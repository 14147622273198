import React from 'react';
import {Link} from '@reach/router'
import MainNavBar from './elements/MainNavBar';
import HugeSearch from './elements/HugeSearch';
import Signature from './maincomponents/Signature';
import FbSkeleton from './maincomponents/FbSkeleton';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { SingleDatePicker, DayPickerRangeController } from 'react-dates';
import moment from 'moment';
function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
const NeoListingCard=(props)=>{

    return(
        <>
                        <Link to={'/listings/'+props.listing.id}>
                <div className="vehicleCardy alignCenter" >
                <div className="upperVehicleCardy" style={{backgroundImage:"url("+props.listing.mainPicture.url+")"}}>
                    <div className="labelCar">HOT</div>
                   
                <div className="bottomVehicleCardy">
                    <div className="wide long alignCenter"> <span className="priceTag white" style={{paddingTop:"0px"}}> {props.selectedSymbol}{formatNumber(Math.ceil(props.listing.dailyPrice*props.rate))}/día</span></div>
               
                </div>
                </div>
                
                </div>
                </Link>
        </>
    )
}

export default function(props){
    const switchrent=(e)=>{ 
        console.log("true")
        props.userPack.switchRent("",true)
        props.userPack.methods.neoSelected("search")
    }
    const switchsale=(e)=>{ 
        props.userPack.switchRent("",false)
        props.userPack.methods.neoSelected("search")
    }
    const publish=(e)=>{ 
        props.userPack.dialog.handleOpen("list")
    }

    const scrollRight=()=>{
        let scroller=document.querySelector("#scrolRight")
        let slider=document.getElementsByClassName("categorySlider")[0]
        // slider.style.position = slider.style.position+200
        slider.scrollBy({left:520,behavior:"smooth"});

    }
    const setStartDate=(start)=>{
        let neoBooking = {...props.userPack.book.myBooking}
        neoBooking.startDate=start
        props.userPack.book.updateMyBooking(neoBooking)
    }
    const setEndDate=(end)=>{
        let neoBooking = {...props.userPack.book.myBooking}
        neoBooking.endDate=end
        props.userPack.book.updateMyBooking(neoBooking)
    }
    const scrollLeft=()=>{
        let scroller=document.querySelector("#scrollLeft")
        let slider=document.getElementsByClassName("categorySlider")[0]
        slider.scrollBy({left:-520,behavior:"smooth"});
    }
    const [focusedInput,setFocusedInput]=React.useState(false)
    const [focusedInput2,setFocusedInput2]=React.useState(false)
    const selectedSymbol = props.userPack.elements.currency.symbols.filter(c=>c.value==props.userPack.elements.currency.selected)[0].label;
    const rate = parseFloat(props.userPack.elements.currency.rates[props.userPack.elements.currency.selected]).toFixed(2)
    return(
        <>
        <div className="wide">
        <MainNavBar userPack={props.userPack}/>
         <div className="background">
    
      <div className="innerBg">
          <div className="blackScreen"></div>

          
          <div style={{zIndex:"5",marginBottom:"-30px"}}><p className="mainTitle" >Revoluciona tu forma de alquilar un carro</p></div>
          <div style={{zIndex:"5",marginBottom:"0px",marginTop:props.userPack.dimensions.width>759?"30px":"0px",marginBottom:props.userPack.dimensions.width>759?"30px":"0px"}}><p className="secondMainTitle" >Reserva el mejor carro para tus próximas vacaciones ya!</p></div>
          <div className="wide t3 alignCenter" style={{zIndex:11}}>
          <HugeSearch focusedInput={focusedInput} setFocusedInput={setFocusedInput} userPack={props.userPack}
  startDate={props.userPack.book.myBooking.startDate} endDate={props.userPack.book.myBooking.endDate}/>
          </div>
          
          
  </div>
  </div>
  
  <div className="mobileBackground">
  <div className="" style={{paddingBottom:'2rem',width:"100%"}}>
     <div className="blackScreen"></div>
     <div className="wide">
            <div className="wide">
            <p className="mainTitleMobile alignCenter justify" >Revoluciona tu forma de alquilar un carro</p></div>
         
            <div className="wide t3 b3">
        <p className="secondMainTitleMobile" >Reserva el mejor carro para tus próximas vacaciones ya!</p>
            </div>
            <div className="alignCenter wide t3">
          <div className="hugeBoxMobile" style={{bottom:props.userPack.dimensions.scrollValue>600?"180px":(props.userPack.dimensions.scrollValue*0.12)+"px"}} >
            <div className="wide relative t2  " >
            <div class="material-textfield" >
        <input type="email" placeholder=" " autoFocus={true} style={{width:"80% !important"}}/>
        <label>Lugar, marca ó modelo</label>
    </div>
            </div>
            <div className="half t2">
                <div className="wide l1">
                    <div className="body2 bold">Desde</div>
                </div>
                <div className="wide l1">
                <div className="body2 bold">Hasta</div>
                </div>
            </div>
            <div className="half relative t2" >
                <div className="flexStart relative">
            <SingleDatePicker
            block={true}
            numberOfMonths={1}
            anchorDirection="ANCHOR_RIGHT"
  date={props.userPack.book.myBooking.startDate} // momentPropTypes.momentObj or null
  onDateChange={date => setStartDate(date)} // PropTypes.func.isRequired
  focused={focusedInput} // PropTypes.bool
  onFocusChange={({ focused }) => setFocusedInput(focused)} // PropTypes.func.isRequired
  id="your_unique_id" // PropTypes.string.isRequired,
/></div>
<div className="flexStart relative">
<SingleDatePicker
block={true}
            numberOfMonths={1}
  date={props.userPack.book.myBooking.endDate} // momentPropTypes.momentObj or null
  onDateChange={date => setEndDate(date)} // PropTypes.func.isRequired
  focused={focusedInput2} // PropTypes.bool
  onFocusChange={({ focused }) => setFocusedInput2(focused)} // PropTypes.func.isRequired
  id="your_unique_id" // PropTypes.string.isRequired,
/></div>

            </div>
            <div className="wide t2 b2">
<Link className="wide alignCenter" to="/listings">
<div className={"button buttonBlack"+ " alignCenter"} > 
<div disabled={false} id={props.buttonId?props.buttonId:""} 
className={"caption2"+" bold textAlign "+"white"}>{props.loading?<div className="spinner"/>:"Mostrar resultados"}
</div>
</div>
</Link>
            </div>
              {/* <div className="hugeSearchMobile">
                  <div className="box1">
                      <input placeholder="Ingrese una dirección, sector o ciudad" className="mainInput" type="text" name="hugeInput2" id="hugeInput2"/>
                  </div>
                  <div className="box2">
                      <Link to="listings" style={{color:"inherit"}}>
                      <svg style={{position:"relative",right:"25px"}} width="20" height="20" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M23.364 20.636C22.7395 20.0378 22.1317 19.4225 21.5413 18.7907C21.0453 18.2867 20.7467 17.92 20.7467 17.92L17.0133 16.1373C18.508 14.4422 19.3329 12.26 19.3333 9.99999C19.3333 4.85466 15.1467 0.666656 10 0.666656C4.85333 0.666656 0.666664 4.85466 0.666664 9.99999C0.666664 15.1453 4.85333 19.3333 10 19.3333C12.3507 19.3333 14.4933 18.4533 16.1373 17.0147L17.92 20.748C17.92 20.748 18.2867 21.0467 18.7907 21.5427C19.3067 22.0267 19.9853 22.6813 20.636 23.3653L22.4467 25.2213L23.252 26.0827L26.08 23.2547L25.2187 22.4493C24.7133 21.9533 24.0387 21.2947 23.364 20.636ZM10 16.6667C6.324 16.6667 3.33333 13.676 3.33333 9.99999C3.33333 6.32399 6.324 3.33332 10 3.33332C13.676 3.33332 16.6667 6.32399 16.6667 9.99999C16.6667 13.676 13.676 16.6667 10 16.6667Z" fill="black"/>
                          </svg>
                      </Link>
                  </div>
              </div> */}

                 
  
          </div>
          </div>
          </div>

   

  </div>
  </div>
  {/* bottom:props.userPack.dimensions.scrollValue>600?"180px":(props.userPack.dimensions.scrollValue*0.3)+"px" */}
 {/* <div className="wide" id="mainBody" style={{top:props.userPack.dimensions.scrollValue>600?"1280px":(props.userPack.dimensions.scrollValue*0.9)+"px"}}> */}
 <div className="wide" id="mainBody" style={{top:"100vh"}}>
     <div className="inner relative" style={{background:"white"}}>
  <div className="wide flexStart t3 b4">
<p className="semiTitle">Buscar por categoría</p>
  </div>
  <div className="categoryDrawer">
      <div className="alignCenter justify">
      <svg onClick={scrollLeft} className="buttonClass" id="scrollLeft" width="20" height="30" viewBox="0 0 32 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M31.1998 8L12.1998 27L31.1998 46L27.3998 53.6L0.799805 27L27.3998 0.400002L31.1998 8Z" fill="black"/>
</svg>

      </div>
      <div className="categorySlider">
      <div className="categoryCard">
          <div className="upperCategoryCard" style={{backgroundImage:'url(../media/pictures/sedan.jpeg)'}}></div>
          <div className="bottomCategoryCard alignCenter justify">
              <p className="categoryText black">Sedán</p>
          </div>
          </div>
          <div className="categoryCard">
          <div className="upperCategoryCard" style={{backgroundImage:'url(../media/pictures/camioneta.jpg)'}}></div>
                    <div className="bottomCategoryCard alignCenter justify">
              <p className="categoryText black">Camioneta</p>
          </div>
          </div>
          <div className="categoryCard">
          <div className="upperCategoryCard" style={{backgroundImage:'url(../media/pictures/SUV.jpg)'}}></div>
                    <div className="bottomCategoryCard alignCenter justify">
              <p className="categoryText black">SUV</p>
          </div>
          </div>
          <div className="categoryCard">
          <div className="upperCategoryCard" style={{backgroundImage:'url(../media/pictures/minivan.png)'}}></div>
                    <div className="bottomCategoryCard alignCenter justify">
              <p className="categoryText black">Minivan</p>
          </div>
          </div>
          
          <div className="categoryCard">
          <div className="upperCategoryCard" style={{backgroundImage:'url(../media/pictures/luxury.png)'}}></div>
                    <div className="bottomCategoryCard alignCenter justify">
              <p className="categoryText black">Lujo</p>
          </div>
          </div>
          <div className="categoryCard">
          <div className="upperCategoryCard" style={{backgroundImage:'url(../media/pictures/sportive.jpg)',backgroundSize:"100% 100%",backgroundPosition:"0px 0px"}}></div>
                    <div className="bottomCategoryCard alignCenter justify">
              <p className="categoryText black">Deportivo</p>
          </div>
          </div>
          </div>
          <div className="alignCenter justify">
          <svg onClick={scrollRight} className="buttonClass" id="scrollRight" width="20" height="30" viewBox="0 0 32 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.799948 46L19.8 27L0.799952 7.99999L4.59995 0.399992L31.2 27L4.59995 53.6L0.799948 46Z" fill="black"/>
</svg>

          </div>
  </div>
  <div className="" style={{backgroundColor:"#000000",width:"100%",height:"auto"}}>
      <div style={{position:"relative",paddingTop:"5%"}}>
            <div style={{width:"100%"}} className="alignCenter textAlign t4">
                    <p className="signatureTitle l2 r2" style={{marginBottom: "16px"}}> Descubre la forma más elegante de alquilar tu carro</p>
                    </div>
                <div style={{width:"100%"}} className="alignCenter textAlign t4">
                    <p className="secondMainTitle"> Haz que tu carro trabaje para ti! Gana dinero alquilando tu vehículo  </p>
                    </div>
                    <div style={{width:"100%",marginTop:"35px",paddingBottom:"10%"}} className="alignCenter textAlign">
                    <div className="botonBlanco alignCenter textAlign" onClick={publish}><p className="buttonText white">Publica tu carro</p></div>
                    </div>
        </div>
  </div>
  <div className="wide">
  <div className="wide flexStart t4 b2">
<p className="semiTitle">Vehículos disponibles</p>
  </div>
  <div className="wide t3">
  <div className="vehicleDrawer">
      {props.fetched&&props.results.length>0?
      <>
      {props.results.map((listing,index)=>(
          <>
          <NeoListingCard selectedSymbol={selectedSymbol} rate={rate}  listing={listing} key={index}/>
          </>
      ))}

                          </>
      :
      <>
      <div className="flexWrap alignCenter">
      <div className="vehicleCardy alignCenter">
            <FbSkeleton width={props.userPack.dimensions.width} loading={true}/>
          </div>
          <div className="vehicleCardy alignCenter">
          <FbSkeleton width={props.userPack.dimensions.width} loading={true}/>
        </div>
        </div>
        </>}

        </div>
          
  </div>
  <div style={{position:"relative",top:"30px",margin:"60px"}} className="alignCenter">
      
            <div className="botonNegro alignCenter"  style={{margin:"8px"}}><p className="buttonText black" >Ver más</p> </div><Link to="/listings"><div style={{margin:"8px"}} className="botonVerde alignCenter justify"><p className="buttonText white">Explorar todos</p></div> </Link>
       
    </div>
    </div>
  </div>
        <div className="wide" style={{marginTop:"0px"}}><Signature userPack={props.userPack}/> </div>
       
  </div>
  </div>
        </>
    )
}
import React from 'react';
import {Link} from '@reach/router'
export default function(props){
    const logout=(e)=>{
        e.preventDefault()
        props.methods.logout()
    }
    return(
        <>
        {props.user.profilePicture&&props.user.profilePicture.url?
        <>
        <div className="profilePicture" style={{backgroundImage:"url("+props.user.profilePicture.url+")"}}>
            {/* <svg className="sendRight" width="40" height="40" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
                <ellipse cx="25" cy="27.9252" rx="25" ry="25" fill="url(#pattern0)"/>
                <path d="M40 29C47.7672 29 54 22.4722 54 14.5C54 6.52778 47.7672 0 40 0C32.2328 0 26 6.52778 26 14.5C26 22.4722 32.2328 29 40 29Z" fill="#C71C1C" stroke="white" strokeWidth="2"/>
                <defs>
                    <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlinkHref="#image0" transform="translate(-0.289256) scale(0.00300669 0.00285714)"/>
                    </pattern>
                    <image id="image0" width="525" height="350" xlinkHref={props.user && props.user.profilePicture?props.user.profilePicture.url:"media/prieto.png"}/>
                    </defs>
                    </svg> */}
                    
                    <div className="mainHover">
                        <Link to="/dashboard">
                        <p className="bulletPoint">Dashboard</p>
                        </Link>
                        <Link to="/myProfile">
                        <p className="bulletPoint">Mi perfil</p>
                        </Link>
                        <Link to="/dashboard/userId">
                        <p className="bulletPoint">Mi cuenta</p>
                        </Link>
                        <a href="#" onClick={logout}>
                        <p className="bulletPoint">Logout</p>
                        </a>
                    </div>
                </div>
                </>
        :
        <>
        {/* https://www.clipartmax.com/png/middle/182-1828676_person-icons-person-icon.png */}
        
        <div className="profilePicture" style={{backgroundImage:"url(https://www.munny.dk/wp-content/uploads/2017/11/Male-missing.png)"}}>
            {/* <svg className="sendRight" width="40" height="40" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink">
                <ellipse cx="25" cy="27.9252" rx="25" ry="25" fill="url(#pattern0)"/>
                <path d="M40 29C47.7672 29 54 22.4722 54 14.5C54 6.52778 47.7672 0 40 0C32.2328 0 26 6.52778 26 14.5C26 22.4722 32.2328 29 40 29Z" fill="#C71C1C" stroke="white" strokeWidth="2"/>
                <defs>
                    <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlinkHref="#image0" transform="translate(-0.289256) scale(0.00300669 0.00285714)"/>
                    </pattern>
                    <image id="image0" width="525" height="350" xlinkHref={props.user && props.user.profilePicture?props.user.profilePicture.url:"media/prieto.png"}/>
                    </defs>
                    </svg> */}
                    
                    <div className="mainHover">
                        <Link to="/dashboard">
                        <p className="bulletPoint">Dashboard</p>
                        </Link>
                        <p className="bulletPoint">Mensajes</p>
                        <p className="bulletPoint">Notificaciones</p>
                        <p className="bulletPoint">Guardados</p>
                        <Link to="/account">
                        <p className="bulletPoint">Cuenta</p>
                        </Link>
                        <a href="#" onClick={logout}>
                        <p className="bulletPoint">Salir</p>
                        </a>
                    </div>
                </div>
        </>
        }
         
            
        </>
    )
}
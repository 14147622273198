import React from 'react';
import {Link} from '@reach/router'
import ProfileButton from './ProfileButton';
import Avatar from '@material-ui/core/Avatar';
import CurrencyItem from '../maincomponents/CurrencyItem';
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MyAvatar from '../maincomponents/MyAvatar';


const CurrencySelection = (props)=>{
    return(<CurrencyItem handleCurrency={props.handleCurrency} symbol={props.symbol} label={props.label}/>)
  }
  
export default function(props){
    const handleLogin=()=>{
        props.userPack.dialog.handleOpen("login")
      }
    const openLogin=()=>{
        // let newVal = {...props.userPack.loginModal}
        // newVal.active=true
        // newVal.login=true
        // props.userPack.switchLoginModal(newVal)
        props.userPack.dialog.handleOpen("login")
      } 
      const publish=(e)=>{ 
        props.userPack.dialog.handleOpen("list")
    }
      const openRegister=()=>{
        props.userPack.dialog.handleOpen("register")
        // let newVal = {...props.userPack.loginModal}
        // newVal.active=true
        // newVal.login=false
        // props.userPack.switchLoginModal(newVal)
      }
      const neoSelected=(e)=>{
        props.userPack.methods.neoSelected(e.target.parentElement.type)
      }
      const switchrent=(e)=>{ 
        console.log("true")
        props.userPack.switchRent("",true)
        props.userPack.methods.neoSelected("search")
    }
    const switchsale=(e)=>{ 
        props.userPack.switchRent("",false)
        props.userPack.methods.neoSelected("search")
    }
    const handleClose = () => {
        setAnchorEl2(null);
      };
      const handleClick = event => {
        setAnchorEl2(event.currentTarget);
      };
    
      const handleCurrency=(value)=>{
        props.userPack.setCurrency(value)
        props.userPack.updateMyListing('currency',{oldVal:props.userPack.elements.currency.symbols.filter(c=>c.value==props.userPack.elements.currency.selected)[0].value,value})
        
      
        
              
        setAnchorEl2(null)
      }
    const menuId = 'search-account-menu';
      const selectedSymbol = props.userPack.elements.currency.symbols.filter(c=>c.value==props.userPack.elements.currency.selected)[0].label;
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    let navColor
    const open2 = Boolean(anchorEl2);
    const id = open2 ? 'simple-popover' : undefined;
    if(props.navColor){navColor=props.navColor}else{if(props.userPack.navColor){navColor=props.userPack.navColor}else{navColor="white"} } 
    return(
        <>
         {/* <div className="mobileNav">
                    
                    <div className="alertBg">
                        <p className="normal" style={{marginTop:"10px"}}>Obtén la información más reciente y relevante acerca del COVID-19. <a href="#" className="underLine" style={{color:"inherit"}}>Leer más</a></p></div>
                    <div className="mobileSearchBar">
                        <div className="innerMobileSearch">
                            <Link to="listings" style={{color:"inherit"}} onClick={neoSelected} type="search">
                        <svg style={{marginLeft:"18px"}} width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="8" cy="8" r="7" stroke="black" strokeWidth="2"/>
                            <line x1="12.3422" y1="12.7137" x2="18.659" y2="18.2478" stroke="black" strokeWidth="2"/>
                            </svg>
                        </Link>
                            <input className="mainInput" type="text" name="" id=""/>
                    
                            
                        </div>
                    </div>
                </div> */}
                    <div className="mobileSearchBar">
                        <div className="innerMobileSearch">
                            <Link to="listings" style={{color:"inherit"}} onClick={neoSelected} type="search">
                        <svg style={{marginLeft:"18px"}} width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="8" cy="8" r="7" stroke="black" strokeWidth="2"/>
                            <line x1="12.3422" y1="12.7137" x2="18.659" y2="18.2478" stroke="black" strokeWidth="2"/>
                            </svg>
                        </Link>
                            <input className="mainInput" type="text" name="" id=""/>
                    
                            
                        </div>
                    </div>
     <div className={navColor=="black"?"indexNavBar whiteBg":"indexNavBar transparentNavBar"}>
          <div className="leftWing flexStart">
              {/* <div className="logoMini"></div> */}
            <Link to="/">
            <div className="title1 button" style={{color:navColor,position:"relative",left:"2vw"}}>CARLY
            </div>
            </Link>
          </div>

          
      <div className="rightWing">
          <div style={{position:"relative",top:"1px"}}>
          <IconButton
                             edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                color="inherit"
                onClick={handleClick}
                style={{color:navColor}}
                >
                 
                {selectedSymbol}
               {/* <AttachMoneyIcon/> */}
               
             </IconButton>
             <Popover
              id={id}
              open={open2}
              anchorEl={anchorEl2}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
            {props.userPack.elements.currency.symbols.map((symbol,index)=>(
              <CurrencySelection handleCurrency={handleCurrency} label={symbol.value} key={index} symbol={symbol.label}/>
            ))}
             </Popover>
          </div>
          <a style={{color:"inherit",padding:"14px"}} href="#" onClick={publish}>
          <p className="title2" style={{minWidth:"150px",color:navColor}}>Publica tu carro!</p>
          </a>
          {props.userPack.dimensions.width>759?
          <>
          {props.userPack.logged?<>
          <div className="relative" style={{marginLeft:"-12px"}}>
                    <div style={{position:"relative", width:"43px",height:"43px",margin:"7px"}} className="alignCenter justify" id="notifications">
                
                <p className="bold" style={{position:"absolute",top:'11px',left:"23px",color:"white",fontSize:"8px"}}>11</p>
      
                <svg  width="33" height="28" viewBox="0 0 33 28" fill={navColor?navColor:"white"} xmlns="http://www.w3.org/2000/svg">
                <path d="M26.7283 21.9351C25.8534 21.2443 25.0874 20.4525 24.451 19.5808C23.7563 18.3776 23.3398 17.0636 23.2262 15.716V11.7467C23.2322 9.63002 22.3652 7.58422 20.7881 5.9937C19.211 4.40318 17.0324 3.37743 14.6615 3.10917V2.07267C14.6615 1.78818 14.5339 1.51534 14.3068 1.31418C14.0796 1.11301 13.7716 1 13.4503 1C13.1291 1 12.821 1.11301 12.5939 1.31418C12.3667 1.51534 12.2391 1.78818 12.2391 2.07267V3.12524C9.88951 3.41284 7.7372 4.44479 6.18083 6.02996C4.62445 7.61514 3.76948 9.64612 3.77426 11.7467V15.716C3.66063 17.0636 3.24421 18.3776 2.54944 19.5808C1.92422 20.4505 1.17056 21.2422 0.308473 21.9351C0.211695 22.0103 0.134133 22.103 0.0809446 22.2069C0.0277567 22.3108 0.000163124 22.4236 0 22.5377V23.6304C0 23.8435 0.0955874 24.0479 0.265734 24.1986C0.435881 24.3493 0.66665 24.4339 0.907274 24.4339H26.1295C26.3701 24.4339 26.6009 24.3493 26.771 24.1986C26.9412 24.0479 27.0368 23.8435 27.0368 23.6304V22.5377C27.0366 22.4236 27.009 22.3108 26.9558 22.2069C26.9026 22.103 26.8251 22.0103 26.7283 21.9351ZM1.88713 22.8269C2.73126 22.1048 3.47449 21.2955 4.10088 20.4164C4.97604 18.9633 5.48667 17.36 5.59788 15.716V11.7467C5.5619 10.8051 5.74025 9.86662 6.12232 8.98727C6.50439 8.10792 7.08237 7.30565 7.82183 6.62824C8.56129 5.95083 9.44711 5.41212 10.4266 5.04421C11.406 4.67629 12.459 4.48669 13.5229 4.48669C14.5868 4.48669 15.6398 4.67629 16.6193 5.04421C17.5987 5.41212 18.4845 5.95083 19.224 6.62824C19.9635 7.30565 20.5414 8.10792 20.9235 8.98727C21.3056 9.86662 21.4839 10.8051 21.4479 11.7467V15.716C21.5591 17.36 22.0698 18.9633 22.9449 20.4164C23.5713 21.2955 24.3146 22.1048 25.1587 22.8269H1.88713Z" fill={navColor?navColor:"white"}/>
      <path d="M22.2486 16.7735C26.9036 16.7735 30.8527 13.3388 30.8527 8.89615C30.8527 4.45347 26.9036 1.0188 22.2486 1.0188C17.5936 1.0188 13.6445 4.45347 13.6445 8.89615C13.6445 13.3388 17.5936 16.7735 22.2486 16.7735Z" fill="#A30000" stroke={navColor?navColor:"white"} strokeWidth="1.5"/>
      
      <path d="M14.1345 27.9998C14.8091 27.9868 15.4564 27.7752 15.962 27.4023C16.4676 27.0294 16.7989 26.5193 16.8973 25.9623H11.2646C11.3658 26.5345 11.7126 27.0565 12.2405 27.4313C12.7684 27.8061 13.4415 28.0081 14.1345 27.9998Z" fill={navColor?navColor:"white"}/>
      </svg>
      <div className="mainHover" >
                              <p className={"bulletPoint"}>Notificaciones</p>
                          </div>
      
      
                </div>
                </div>
                <div className="relative" style={{position:"relative", width:"43px",height:"43px",margin:"7px"}}>
                <ProfileButton methods={props.userPack.methods} user={props.userPack.user}/>
                </div>
                   

          </>
            :
            <>
            <div className="flexWrap alignCenter" style={{position:"relative",top:"2px",minWidth:"161px"}}>
                                      <a onClick={openLogin} style={{color:"inherit"}} className="button">
                          <p className={"navBarTitle r4 "+navColor}>Login</p>
                          </a>
                          <a href="#" onClick={openRegister} style={{color:"inherit"}} className="registerButton buttonClass alignCenter justify">
                              <p className="registerText" >Regístrate</p>
                          </a>
                          </div>
            </>
            }

          </>:null}
          

      </div>  
          
      </div>
  </>
    )
}
import React, { Component } from 'react';
import { DateRangePicker } from 'react-dates';
import moment from 'moment';

class HugeSearch extends Component {
    constructor(props){
        super(props)
    }
    handleFocused=(val)=>{
        this.setState({focused:val})
    }
    goToListings=()=>{
        this.props.userPack.refs.routeListingsRef.current.click()
    }
    onFocusInput=()=>{
        const hugeSearchRef = document.querySelector("#hugeBox")
        hugeSearchRef.classList.add("suggestions")
        const edgy = document.querySelector("#greenEdge")
        const suggestSearch = document.querySelector("#suggestSearch")
        suggestSearch.style.display="block"
        edgy.classList.add("edgy")
    }
    switch=(val)=>{
        const hugeSearchRef = document.querySelector("#hugeBox")
        const edgy = document.querySelector("#greenEdge")
        const suggestSearch = document.querySelector("#suggestSearch")
        if(val){
            
            hugeSearchRef.classList.add("suggestions")

            suggestSearch.style.display="block"
            edgy.classList.add("edgy")
        }else{
            hugeSearchRef.classList.remove("suggestions")
            suggestSearch.style.display="none"
            edgy.classList.remove("edgy")
        }
    }
    handleClick=(e)=>{
        this.switch(e.target.id==="hugeInput"||e.target.id==="suggestSearch"||e.target.id==="hugeBox")
    }
    componentDidMount(){
        document.querySelector('html').addEventListener('click',this.handleClick);
       }
       componentWillUnmount(){
        document.querySelector('html').removeEventListener('click',this.handleClick)
      }
    handleDatess=(start,end)=>{
        let neoBooking={...this.props.userPack.book.myBooking}
        neoBooking.startDate=start
        neoBooking.endDate=end
        this.props.userPack.book.updateMyBooking(neoBooking)
    }
    state = {  }
    render() { 
        return (  
            <>
            <div className="hugeBox" id="hugeBox" style={{zIndex:"4"}}>
      
      <div className="hugeSearch" id="hugeSearch">
          <div className="customField" style={{marginLeft:"1rem"}}>
              <p className="searchMainLabel">Dónde</p>
              <input onClick={this.onFocusInput} style={{paddingTop:"11px",zIndex:"8"}} placeholder="Ciudad, sector, codigo postal..." className="robottoRegular" type="text" name="hugeInput" id="hugeInput"/>
          </div>
           <div className="wide" >
               <div className="flexWrap t3 ">
                   <div className=" l2">
                        <div className="searchMainLabel">Desde</div>
                   </div>
                   <div className="" style={{position:"relative",left:"4rem"}}>
                   <div className="searchMainLabel">Hasta</div>
                   </div>
               </div>
               <div className="wide flexStart" style={{position:"relative",bottom:"5px"}}>
               {/*
          <div className="customField" style={{marginLeft:"1rem"}}>
              <p className="searchDateLabel">Desde</p>
              <div className="alignCenter justify"><svg width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M31.395 6.1875H25.5417V2.57812H23.4132V6.1875H10.6424V2.57812H8.51389V6.1875H2.66057C2.23734 6.18798 1.83159 6.3511 1.53232 6.6411C1.23305 6.93109 1.0647 7.32427 1.06421 7.73438V29.3906C1.0647 29.8007 1.23305 30.1939 1.53232 30.4839C1.83159 30.7739 2.23734 30.937 2.66057 30.9375H31.395C31.8183 30.937 32.224 30.7739 32.5233 30.4839C32.8226 30.1939 32.9909 29.8007 32.9914 29.3906V7.73438C32.9909 7.32427 32.8226 6.93109 32.5233 6.6411C32.224 6.3511 31.8183 6.18798 31.395 6.1875ZM30.8629 28.875H3.19269V8.25H8.51389V10.8281H10.6424V8.25H23.4132V10.8281H25.5417V8.25H30.8629V28.875Z" fill="black"/>
    <path d="M7.44971 14.4375H9.57819V16.5H7.44971V14.4375Z" fill="black"/>
    <path d="M13.303 14.4375H15.4315V16.5H13.303V14.4375Z" fill="black"/>
    <path d="M18.6243 14.4375H20.7527V16.5H18.6243V14.4375Z" fill="black"/>
    <path d="M24.4775 14.4375H26.606V16.5H24.4775V14.4375Z" fill="black"/>
    <path d="M7.44971 19.0781H9.57819V21.1406H7.44971V19.0781Z" fill="black"/>
    <path d="M13.303 19.0781H15.4315V21.1406H13.303V19.0781Z" fill="black"/>
    <path d="M18.6243 19.0781H20.7527V21.1406H18.6243V19.0781Z" fill="black"/>
    <path d="M24.4775 19.0781H26.606V21.1406H24.4775V19.0781Z" fill="black"/>
    <path d="M7.44971 23.7188H9.57819V25.7812H7.44971V23.7188Z" fill="black"/>
    <path d="M13.303 23.7188H15.4315V25.7812H13.303V23.7188Z" fill="black"/>
    <path d="M18.6243 23.7188H20.7527V25.7812H18.6243V23.7188Z" fill="black"/>
    <path d="M24.4775 23.7188H26.606V25.7812H24.4775V23.7188Z" fill="black"/>
    </svg>
    
              <input className="dateField robottoRegular" placeholder="10/20/2020" />
              </div>
          </div>
          <div>
          <svg width="30" height="28" viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M31.8098 13.938C31.9536 13.7983 32.1244 13.6875 32.3124 13.6119C32.5005 13.5362 32.7021 13.4973 32.9057 13.4973C33.1093 13.4973 33.311 13.5362 33.499 13.6119C33.6871 13.6875 33.8579 13.7983 34.0017 13.938L43.2896 22.938C43.4338 23.0773 43.5481 23.2429 43.6262 23.4251C43.7042 23.6073 43.7444 23.8027 43.7444 24C43.7444 24.1973 43.7042 24.3927 43.6262 24.5749C43.5481 24.7571 43.4338 24.9227 43.2896 25.062L34.0017 34.062C33.711 34.3437 33.3168 34.5019 32.9057 34.5019C32.4947 34.5019 32.1004 34.3437 31.8098 34.062C31.5191 33.7803 31.3558 33.3983 31.3558 33C31.3558 32.6017 31.5191 32.2197 31.8098 31.938L40.0048 24L31.8098 16.062C31.6656 15.9227 31.5512 15.7571 31.4732 15.5749C31.3952 15.3927 31.355 15.1973 31.355 15C31.355 14.8027 31.3952 14.6073 31.4732 14.4251C31.5512 14.2429 31.6656 14.0773 31.8098 13.938Z" fill="#1D1D1D"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.59009 24C6.59009 23.6022 6.75318 23.2206 7.04348 22.9393C7.33379 22.658 7.72752 22.5 8.13807 22.5H40.6458C41.0563 22.5 41.45 22.658 41.7404 22.9393C42.0307 23.2206 42.1937 23.6022 42.1937 24C42.1937 24.3978 42.0307 24.7794 41.7404 25.0607C41.45 25.342 41.0563 25.5 40.6458 25.5H8.13807C7.72752 25.5 7.33379 25.342 7.04348 25.0607C6.75318 24.7794 6.59009 24.3978 6.59009 24Z" fill="#1D1D1D"/>
    </svg>
    
          </div>
          <div className="customField" style={{marginLeft:"1rem"}}>
          <p className="searchDateLabel">Hasta</p>
              <div className="alignCenter justify"><svg width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M31.395 6.1875H25.5417V2.57812H23.4132V6.1875H10.6424V2.57812H8.51389V6.1875H2.66057C2.23734 6.18798 1.83159 6.3511 1.53232 6.6411C1.23305 6.93109 1.0647 7.32427 1.06421 7.73438V29.3906C1.0647 29.8007 1.23305 30.1939 1.53232 30.4839C1.83159 30.7739 2.23734 30.937 2.66057 30.9375H31.395C31.8183 30.937 32.224 30.7739 32.5233 30.4839C32.8226 30.1939 32.9909 29.8007 32.9914 29.3906V7.73438C32.9909 7.32427 32.8226 6.93109 32.5233 6.6411C32.224 6.3511 31.8183 6.18798 31.395 6.1875ZM30.8629 28.875H3.19269V8.25H8.51389V10.8281H10.6424V8.25H23.4132V10.8281H25.5417V8.25H30.8629V28.875Z" fill="black"/>
    <path d="M7.44971 14.4375H9.57819V16.5H7.44971V14.4375Z" fill="black"/>
    <path d="M13.303 14.4375H15.4315V16.5H13.303V14.4375Z" fill="black"/>
    <path d="M18.6243 14.4375H20.7527V16.5H18.6243V14.4375Z" fill="black"/>
    <path d="M24.4775 14.4375H26.606V16.5H24.4775V14.4375Z" fill="black"/>
    <path d="M7.44971 19.0781H9.57819V21.1406H7.44971V19.0781Z" fill="black"/>
    <path d="M13.303 19.0781H15.4315V21.1406H13.303V19.0781Z" fill="black"/>
    <path d="M18.6243 19.0781H20.7527V21.1406H18.6243V19.0781Z" fill="black"/>
    <path d="M24.4775 19.0781H26.606V21.1406H24.4775V19.0781Z" fill="black"/>
    <path d="M7.44971 23.7188H9.57819V25.7812H7.44971V23.7188Z" fill="black"/>
    <path d="M13.303 23.7188H15.4315V25.7812H13.303V23.7188Z" fill="black"/>
    <path d="M18.6243 23.7188H20.7527V25.7812H18.6243V23.7188Z" fill="black"/>
    <path d="M24.4775 23.7188H26.606V25.7812H24.4775V23.7188Z" fill="black"/>
    </svg>
    
              <input className="dateField robottoRegular" placeholder="10/20/2020" />
              </div>
          </div> */}
          <DateRangePicker
  startDate={this.props.startDate} // momentPropTypes.momentObj or null,
  startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
  endDate={this.props.endDate} // momentPropTypes.momentObj or null,
  endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
  onDatesChange={({ startDate, endDate }) => this.handleDatess(startDate, endDate)} // PropTypes.func.isRequired,
  focusedInput={this.state.focused} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
  onFocusChange={this.handleFocused}// PropTypes.func.isRequired,
  noBorder={true}
/>
</div>
          </div>
          <div onClick={this.goToListings} id="greenEdge" style={{position:"relative",left:this.props.userPack.dimensions.width<1090?"-0.4rem":"-0.4rem"}} className="greenEdge alignCenter justify link">
          <svg style={{color:"white !important"}} width="32" height="32" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M23.364 20.636C22.7395 20.0378 22.1317 19.4225 21.5413 18.7907C21.0453 18.2867 20.7467 17.92 20.7467 17.92L17.0133 16.1373C18.508 14.4422 19.3329 12.26 19.3333 9.99999C19.3333 4.85466 15.1467 0.666656 10 0.666656C4.85333 0.666656 0.666664 4.85466 0.666664 9.99999C0.666664 15.1453 4.85333 19.3333 10 19.3333C12.3507 19.3333 14.4933 18.4533 16.1373 17.0147L17.92 20.748C17.92 20.748 18.2867 21.0467 18.7907 21.5427C19.3067 22.0267 19.9853 22.6813 20.636 23.3653L22.4467 25.2213L23.252 26.0827L26.08 23.2547L25.2187 22.4493C24.7133 21.9533 24.0387 21.2947 23.364 20.636ZM10 16.6667C6.324 16.6667 3.33333 13.676 3.33333 9.99999C3.33333 6.32399 6.324 3.33332 10 3.33332C13.676 3.33332 16.6667 6.32399 16.6667 9.99999C16.6667 13.676 13.676 16.6667 10 16.6667Z" fill="#FFFFFF"/>
                  </svg>
          </div> 
          </div>
    
          
    <div id="suggestSearch" style={{height:"100px",width:"100%"}}>
    
    </div>
    
    </div>
            </>
        );
    }
}
 
export default HugeSearch;
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HelpIcon from '@material-ui/icons/Help';
import Typography from '@material-ui/core/Typography';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import PaymentIcon from '@material-ui/icons/Payment';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import HistoryIcon from '@material-ui/icons/History';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import LockIcon from '@material-ui/icons/Lock';
import Grid from '@material-ui/core/Grid';
import HomeIcon from '@material-ui/icons/Home';
import RoomIcon from '@material-ui/icons/Room';
import BookIcon from '@material-ui/icons/Book';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {/* <Box p={3}>{children}</Box> */}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

const BusinessTabs = (props) => {
  return(
    <>
    <AppBar position="static" color="inherit" elevation={100}>
      <Grid container alignItems="center" alignContent="center"justify="center">
            <Tabs
              value={props.value}
              onChange={props.handleChange}
              variant="scrollable"
              indicatorColor="secondary"
              textColor="secondary"
              scrollButtons="auto"
              aria-label="scrollable force tabs example"
            >
              <Tab label="Home" icon={<HomeIcon />} {...a11yProps(0)} />
              <Tab label="Agenda" icon={<BookIcon />} {...a11yProps(1)} />
              <Tab label="Vehiculos" icon={<CardTravelIcon />} {...a11yProps(2)} />
              <Tab label="Estados Financieros" icon={<MonetizationOnIcon />} {...a11yProps(3)} />
              <Tab label="Historial de Alquileres" icon={<HistoryIcon />} {...a11yProps(4)} />
              <Tab label="Localidades para entregas" icon={<RoomIcon />} {...a11yProps(5)} />
              <Tab label="Consejos" icon={<HelpIcon />} {...a11yProps(6)} />
              
            </Tabs>
    </Grid>
  </AppBar>
  </>
  );
};

const ConsumerTabs = (props) => {

  const classes = useStyles();


  return(
    <>
    <AppBar position="static" color="inherit">
    <Tabs
      value={props.value}
      onChange={props.handleChange}
      variant="scrollable"
      scrollButtons="on"
      indicatorColor="primary"
      textColor="primary"
      aria-label="scrollable force tabs example"
    >
      <Tab label="Mi Perfil" icon={<AccountCircleIcon />} {...a11yProps(0)} />
      <Tab label="Administrar mis Listings" icon={<CardTravelIcon />} {...a11yProps(1)} />
      <Tab label="Estados Financieros" icon={<MonetizationOnIcon />} {...a11yProps(2)} />
      <Tab label="Historial de Alquileres" icon={<HistoryIcon />} {...a11yProps(3)} />
      <Tab label="Payment Methods" icon={<PaymentIcon />} {...a11yProps(4)} />
      <Tab label="Mi Cuenta" icon={<LockIcon />} {...a11yProps(5)} />
      <Tab label="Reportar Abuso" icon={<LiveHelpIcon />} {...a11yProps(6)} />
    </Tabs>
  </AppBar>
  <TabPanel value={props.value} index={0}>
    Item One
  </TabPanel>
  <TabPanel value={props.value} index={1}>
    Item Two
  </TabPanel>
  <TabPanel value={props.value} index={2}>
    Item Three
  </TabPanel>
  <TabPanel value={props.value} index={3}>
    Item Four
  </TabPanel>
  <TabPanel value={props.value} index={4}>
    Item Five
  </TabPanel>
  <TabPanel value={props.value} index={5}>
    Item Six
  </TabPanel>
  <TabPanel value={props.value} index={6}>
    Item Seven
  </TabPanel>
  </>
  );
};

export default function ScrollableTabsButtonForce(props) {
  const classes = useStyles();
  function handleChange(event, newValue) {
    props.selectTab(newValue);
    return
  };
  const selectedTab = props.selectedTab
  const Panel = (
  <BusinessTabs
  handleChange={handleChange}
  value={selectedTab}
  />)
  
  return (
    <>
   <div className={classes.root}>
      {Panel}
    </div>  
    </>
  );
}
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';

const Tarjeta = (props) => {
    const id = props.listing.id
    const notFound = "https://static.thenounproject.com/png/2999524-200.png"
    const url = props.listing.mainPicture?"url('"+ props.listing.mainPicture.url+"')":"url('"+ notFound+"')"

    const handleChange = name => event => {
      let myUser = props.user
      let deactivateIndex
      let newListings = myUser.listings
      newListings.forEach((listing,index) => {
        if(listing.id===id){
          deactivateIndex=index
        }
      });
      newListings[deactivateIndex].active=!newListings[deactivateIndex].active
      newListings[deactivateIndex].owner={id:props.user.id}
      props.listingAPI("high",newListings[deactivateIndex])
    };
    const handleDelete = () =>{
      props.handleOpen("delete",id)
    }
    const handleEditModal =() =>{
      props.userPack.methods.stateHandling('myListing',props.listing)
      props.switchListing(props.listing)
      props.actions.openEditListingModal(props.listing)
    }
    const handlePreview = (e) => {
      window.location.href = '/listings/'+id;
    }
    const title=props.listing.vehicle&&props.listing.vehicle.brand&&props.listing.vehicle.model&&props.listing.vehicle.year?props.listing.vehicle.brand+" "+props.listing.vehicle.model+" "+props.listing.vehicle.year:"Incompleto"
    return(
        <>
          <div style={{backgroundImage:url,
            backgroundSize:"100%",
            backgroundPosition:"center",
            backgroundRepeat:"no-repeat",
            //borderRadius:"10px",
            height: "auto",
            maxWidth: "auto",
            position: 'relative',
            left: '50%',
            transform: 'translateX(-50%)'}}>
              <Box style={{height:"180px",width:"330px"}}>
              </Box>
          </div>
          <Grid container justify="center" alignItems="center" alignContent="center">
            <Grid item xs={"auto"}>
              <Typography variant="h6">
                {title}         
                <IconButton aria-label="delete" onClick={handleDelete}>
                  <DeleteIcon />
                </IconButton>
              </Typography>
            </Grid>
            </Grid>
            <Grid container justify="center" alignItems="center" alignContent="center">
              <Grid item xs={"auto"} style={{position:"relative",top:"-10px",zIndex:"2"}}>
                <p>No trips</p>
              </Grid>
              <Grid item xs={"auto"} style={{position:"relative",top:"-10px",zIndex:"2"}}>
                <Switch
                  checked={props.listing.active}
                  onChange={handleChange('active')}
                  value={props.listing.active}
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
                              
             </Grid>
              <Grid item xs={"auto"} style={{position:"relative",top:"-10px",zIndex:"2"}}>
                {props.listing.active?<p>Publicacion activa</p>:<p>Inactiva</p>}
                </Grid>
              
            </Grid>
          

      <Grid container justify="center">
        <Grid item xs="auto">
            <ButtonGroup
              variant="contained"
              color="default"
              aria-label="full-width contained primary button group"
            > 
            <Button onClick={handleEditModal} key={id} value={id}>Editar</Button>
            <Button onClick={handlePreview} id={id}>Ver publicación</Button>
            </ButtonGroup>
          </Grid>

      </Grid>

       
    </>
    );
  };

export default Tarjeta;

//onClick={props.actions.openEditListingModal}
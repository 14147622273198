import React from 'react';
import Button from '../SvgElements/Button';
import { Link } from '@reach/router';

const states = [
    {value:"FL",tag:"",label:""},
     {value:"AL",tag:"Alabama",label:"AL"},
 {value:"AK",tag:"Alaska",label:"AK"},
 {value:"AZ",tag:"Arizona",label:"AZ"},
 {value:"AR",tag:"Arkansas",label:"AR"},
 {value:"CA",tag:"California",label:"CA"},
 {value:"CO",tag:"Colorado",label:"CO"},
 {value:"CT",tag:"Connecticut",label:"CT"},
 {value:"DE",tag:"Delaware",label:"DE"},
 {value:"FL",tag:"Florida",label:"FL"},
 {value:"GA",tag:"Georgia",label:"GA"},
 {value:"HI",tag:"Hawaii",label:"HI"},
 {value:"ID",tag:"Idaho",label:"ID"},
 {value:"IL",tag:"Illinois",label:"IL"},
 {value:"IN",tag:"Indiana",label:"IN"},
 {value:"IA",tag:"Iowa",label:"IA"},
 {value:"KS",tag:"Kansas",label:"KS"},
 {value:"KY",tag:"Kentucky",label:"KY"},
 {value:"LA",tag:"Louisiana",label:"LA"},
 {value:"ME",tag:"Maine",label:"ME"},
 {value:"MD",tag:"Maryland",label:"MD"},
 {value:"MA",tag:"Massachusetts",label:"MA"},
 {value:"MI",tag:"Michigan",label:"MI"},
 {value:"MN",tag:"Minnesota",label:"MN"},
 {value:"MS",tag:"Mississippi",label:"MS"},
 {value:"MO",tag:"Missouri",label:"MO"},
 {value:"MT",tag:"Montana",label:"MT"},
 {value:"NE",tag:"Nebraska",label:"NE"},
 {value:"NV",tag:"Nevada",label:"NV"},
 {value:"NH",tag:"New Hampshire",label:"NH"},
 {value:"NJ",tag:"New Jersey",label:"NJ"},
 {value:"NM",tag:"New Mexico",label:"NM"},
 {value:"NY",tag:"New York",label:"NY"},
 {value:"NC",tag:"North Carolina",label:"NC"},
 {value:"ND",tag:"North Dakota",label:"ND"},
 {value:"OH",tag:"Ohio",label:"OH"},
 {value:"OK",tag:"Oklahoma",label:"OK"},
 {value:"OR",tag:"Oregon",label:"OR"},
 {value:"PA",tag:"Pennsylvania",label:"PA"},
 {value:"RI",tag:"Rhode Island",label:"RI"},
 {value:"SC",tag:"South Carolina",label:"SC"},
 {value:"SD",tag:"South Dakota",label:"SD"},
 {value:"TN",tag:"Tennessee",label:"TN"},
 {value:"TX",tag:"Texas",label:"TX"},
 {value:"UT",tag:"Utah",label:"UT"},
 {value:"VT",tag:"Vermont",label:"VT"},
 {value:"VA",tag:"Virginia",label:"VA"},
 {value:"WA",tag:"Washington",label:"WA"},
 {value:"WV",tag:"West Virginia",label:"WV"},
 {value:"WI",tag:"Wisconsin",label:"WI"},
 {value:"WY",tag:"Wyoming",label:"WY"}
]

export default function(props){
    return(
        <>
        {props.userPack.logged?
                <div className="checkoutLine b2">
                <div className="body3">Loggin in as: {props.userPack.user.username} </div><span className="body2 button l2 blue1" onClick={props.logout}>Logout</span>
            </div>:<>
        <div className="wide t1">
        <div className="body1">Contact information</div>
    </div>
    <div className="wide t2">
        <div className="body3">Are you already registered? <Link className="button blue2" to="login">Login now!</Link></div>
    </div>
    <div className="wide t3">
    <div class="material-textfield">
        <input type="email" placeholder=" "onChange={props.handleEmail} value={props.userPack.user.first_name} autoFocus={true}/>
        <label>Email or phone number</label>
    </div>
    </div>
    <div className="flexStart ">
    <div class="md-checkbox">
        <input name="keepUpdated" type="checkbox" onChange={props.handleCheckoutData}
       id="i2" defaultChecked={props.checkoutData.keepUpdated}/>
        <label for="i2">Keep me up to date on news and exclusive offers</label>
                   
                    </div>
                    </div>
                    </>
        }

        <div className="wide t1">
            <div className="body1">Shipping address</div>
        </div>
        <div className="half t4">
        <div className="wide r1">
        <div class="material-textfield">
            <input type="email" placeholder=" " onChange={props.handleCheckoutData} value={props.userPack.shippingInfo.firstName} name="firstName"/>
            <label>First name</label>
        </div>
          </div>
          <div className="wide l1">
          <div class="material-textfield">
            <input type="email" placeholder=" " onChange={props.handleCheckoutData} value={props.userPack.shippingInfo.lastName} name="lastName"/>
            <label>Last name</label>
        </div>
          </div>
        </div>

          <div className="wide t2">
          <div class="material-textfield">
            <input type="email" placeholder=" " onChange={props.handleCheckoutData} value={props.userPack.shippingInfo.address1} name="address1"/>
            <label>Address</label>
        </div>
          </div>
          <div className="wide t2">
          <div class="material-textfield">
            <input type="email" name="address2" placeholder=" " onChange={props.handleCheckoutData} value={props.userPack.shippingInfo.address2}/>
            <label>Apartment, suite, etc. (optional)</label>
        </div>
          </div>
          <div className="wide t2">
          <div class="material-textfield">
            <input type="email" placeholder=" " name="city" onChange={props.handleCheckoutData} value={props.userPack.shippingInfo.city}/>
            <label>City</label>
        </div>
          </div>
          <div className="half">
          <div className="wide t2 r1">
              <div className="select">
              <select className="select-text" name="state" required name="state" onChange={props.handleCheckoutData} defaulValue={props.userPack.shippingInfo.state}>
                  {states.map((c,index)=>(
                      index==0?
<option key={index} value="" disabled selected={props.userPack.shippingInfo.state?false:true}>{""}</option>
                      :
                      
                      <option key={index} value={c.value} selected={props.userPack.shippingInfo.state==c.label?true:false}>{c.label}</option>
                  ))}
              </select>
              <label className="select-label">State</label>
              </div>
          {/* <div class="material-textfield">
            <input type="email" placeholder=" "/>
            <label>State</label>
        </div> */}
          </div>
          <div className="wide t2 l1">
          <div class="material-textfield">
            <input type="email" placeholder=" " name="zipcode" onChange={props.handleCheckoutData} value={props.userPack.shippingInfo.zipcode}/>
            <label>Zipcode</label>
        </div>
          </div>
          </div>


        <div className="wide t4 alignCenter"> <Button style="black" navigate={"/checkout/"+props.tabs[1]} text="Continue to shipping method"/></div>
        <div className="wide t4">
        <div className="hrElement"></div>
        </div>
        <div className="wide t4">
            <div className="body3 grey1">All rights reserved LeanTech</div>
        </div>
        </>
    )
}




import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
    topMargin:{
        marginTop:"25px"
    }
}))


export default function Consejos(props){
    const classes = useStyles(props);
    //Do handling for deleting listing
    const margin = props.smallScreen?"10px":"0px"
    return(
      <>
      <div className="mainBody" style={{position:"relative",top:"0px"}}>
      <Grid container alignItems="center" justify="center" style={{marginBottom:"20px"}}>
            <Grid item xs={12} sm={12}>
                    <Grid container spacing={0} alignItems="flex-end" style={{marginBottom:"20px"}}>
                        <Grid item xs={12} style={{marginLeft:margin,marginRight:margin}}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h4" className={classes.topMargin}>
                                        Recomendaciones, tips y consejos para aumentar tus ganacias!
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom className={classes.topMargin}>
                                        Esta seccion esta destinada para promover nuevas tecnologías y mejoras ideadas para crecer tu negocio
                                    </Typography>
                                </Grid>
      
                            
                        </Grid>

                        </Grid>
                    </Grid>

            </Grid>
        </Grid>
        </div>
      </>
    );
}
import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Name from './dialogcomponents/Name';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import IntroVerified  from './dialogcomponents/IntroVerified';
import Identity  from './dialogcomponents/Identity';
import Business  from './dialogcomponents/Business';
import Phone  from './dialogcomponents/Phone';
import Address  from './dialogcomponents/Address';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import TextField from '@material-ui/core/TextField';
import NewAddress from '../maincomponents/dialogcomponents/NewAddress';
import GoogleMapReact from 'google-map-react';
import RoomIcon from '@material-ui/icons/Room';

const AnyReactComponent = ({ text }) => <RoomIcon style={{fontSize:"32px",color:"red",position:"relative",bottom:"28px",right:"15px"}}/>

const Content = (props)=>{
  const [step,setStep]=React.useState(0)

  const [values,setValues]=React.useState({})
  const handleChange = name => event => {
    setValues({...values,
        [name]: event.target.value,})
    //props.userPack.updateMyUser([name],event.target.value)
  };
  const [advance,setAdvance] = React.useState(false)
  const mapWidth = props.userPack.dimensions.width<600?"100vw":"100%"
  const [markerLat,setLat] = React.useState(props.userPack.elements.latitude)
  const [markerLng,setLng] = React.useState(props.userPack.elements.longitude)
  const handleMarker=(e)=>{
    setAdvance(true)
    setLat(e.lat)
    setLng(e.lng)
  }
  const handleNext=()=>{
    if(step===1){
        handleSave()
        handleClose()
        return
    }
      setStep(prev=>prev+1)
  }
  const handleBack=()=>{
      setStep(prev=>prev-1)
  }
  const handleSave=()=>{
    let newUser = {...props.userPack.user}
    let myValues = {...values}
    let lat = markerLat.toString()
    let lng = markerLng.toString()
    myValues.xCoordinate=lat
    myValues.yCoordinate=lng
    myValues.customer=newUser.id
    myValues.country="República Dominicana"
    myValues.id=null
    newUser.deliveryAddressList.push(myValues)
    props.userPack.methods.stateHandling('user',newUser)
    props.handleSave()
  }
  const handleClose=()=>{
    props.userPack.dialog.handleClose()
  }
  switch(props.activeStep){
    case 0:
      return(
        <IntroVerified userPack={props.userPack}/>
      );
      case 1:
        return(
          <Name
          userPack={props.userPack}/>
      );
    case 2:
        return(
          <Phone
          userPack={props.userPack}/>
      );
    // case 3:
    //   return(
    //     <Address
    //     userPack={props.userPack}/>
    // );
    case 3:
      return(
        <>
         {step===0?
                                <div className="relative" style={{ height: '45vh', width: mapWidth,minWidth:"59vw" }}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key:"AIzaSyDeH9xTN_AEFa5cOucYhTlnN-HHDQV_oeY&v=3.exp&libraries=geometry,drawing,places" }}
                                    defaultCenter={{lat:props.userPack.elements.latitude,lng:props.userPack.elements.longitude}}
                                    defaultZoom={13}
                                    onClick={handleMarker}
                                >
                                <AnyReactComponent
                                        lat={markerLat}
                                        lng={markerLng}
                                        text="Mi Lugar"
                                        />
                                 </GoogleMapReact>
                           
                                 <div className="wide alignCenter" style={{position:"absolute",color:"white",bottom:"-40px",zIndex:"3000"}}>
                                 <Button style={{color:"white"}} onClick={handleNext} color="secondary" variant="outlined">
                                 Siguiente
                       </Button>
                                       </div>
                                 
                            </div>
                    :null}
                {step===1?
                <>          <div className="relative" style={{ height: '45vh', width: mapWidth,minWidth:"59vw" }}>
                                <Grid container style={{marginTop:"5px"}}>
                                <Grid item xs={12}>
                                <TextField
                                style={{width:"100%",color:"white"}}
                                    required
                                    autoFocus
                                    id="standard-required"
                                    label="Dirección (línea 1)"
                                    margin="normal"
                                    name="line1"
                                    value={values.line1}
                                    onChange={handleChange('line1')}
                                    variant="outlined"
                                    InputLabelProps={{
                                      shrink: true,
                                  }}
                                    />
                                     </Grid>
                                
                                </Grid>
                                <Grid item xs={12}>
                                <TextField
                                style={{width:"100%",color:"white"}}
                                    id="standard-required"
                                    label="Dirección (línea 2)"
                                    margin="normal"
                                    value={values.line2}
                                    onChange={handleChange('line2')}
                                    variant="outlined"
                                    name="line2"
                                    InputLabelProps={{
                                      shrink: true,
                                  }}
                                    />
                                </Grid>
                                <Grid container>
                                  <Grid item xs={6}>
                                  <TextField
                                  style={{width:"100%",color:"white"}}
                                  required
                                      id="standard-required"
                                      label="Ciudad"
                                      margin="normal"
                                      name="city"
                                      value={values.city}
                                      onChange={handleChange('city')}
                                      variant="outlined"
                                      InputLabelProps={{
                                          shrink: true,
                                      }}
                                      />
                                  </Grid>
                                  <Grid item xs={6}>
                                              <TextField
                                  style={{width:"100%",color:"white"}}
                                      required
                                      id="standard-required"
                                      label="Estado/Provincia"
                                      margin="normal"
                                      value={values.state}
                                      name="state"
                                      onChange={handleChange('state')}
                                      variant="outlined"
                                      InputLabelProps={{
                                          shrink: true,
                                      }}
                                      />
                                  
                                  </Grid>                                
                                  
                                </Grid>
                                <div className="wide alignCenter" style={{position:"absolute",color:"white",bottom:"-40px",zIndex:"3000"}}>
                             
                             <Button style={{color:"white"}} onClick={handleSave} color="secondary" variant="outlined">
                         Guardar
                       </Button>
                             </div>
                                </div>
                                </>
                    :null}
        </>
      )
          
    // case 4:
    //   return(
    //     <Identity
    //     userPack={props.userPack}/>
    // );
    // 'Verifique su identidad'

    /*
    case 5:
      return(
        <Business
        userPack={props.userPack}/>
    );  
    */
  }
  return null
  }

class NewListing extends Component {
  state = { 
    addressOpen:true,
    loading:false,
    activeStep:0,
    completed:[false,false,false,false],
    steps:['','¿Cuál es tu nombre?', 'Verifique su # de celular', 'Verifique su dirección','Dirección de entrega'],
    listing:this.props.userPack.myListing
  }
  handleNewAddressOpen=()=>{
    this.state({addressOpen:!this.state.addressOpen})
  }
  handleKeyPress = (e)=>{
    if(e.key==="Enter"){
      if(this.state.activeStep===2){
        this.handleClose()
      }
      this.handleNext()
    }
    if(e.key==="Escape"){
      this.handleClose()
    }
  }
  handleClickAway=()=>{
    this.handleClose()
  }
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
  }
  // change code above this line
  handleEnter() {
    this.setState({
      message: this.state.message + 'You pressed the enter key! '
    });
  }
  handleClose = ()=>{
    this.props.handleClose("noUlterior")
    
  }
  handleSave = ()=>{
    let myNewUser = this.props.userPack.user
    myNewUser.verified = true
    this.props.userPack.userAPI(myNewUser)
    this.props.handleClose()
    
  }

  handleStep =(e)=>{
    this.setState({activeStep:e})
  };

  handleBack = () => {
    this.setState({activeStep:this.state.activeStep-1})
  };

  handleNext = () => {
    this.setState({activeStep:this.state.activeStep+1})
  };

  handleComplete = (index) => {
    let completed = [...this.state.completed]
    completed[index]=true
    this.setState({completed:completed})
  };

  handleClickAway=()=>{
    this.handleClose()
  }

  render() { 
    const openString = this.props.open?"modal is-active":"modal";
    const that=this;
    const userPack=this.props.userPack
    const offset=userPack.dimensions.width<500?"-13px":"20px"
    const portadaUrl = userPack.dimensions.width<600?"url('../formbackgroundmobile.jpg')":"url('../formbackground.jpg')"
    return ( 
      <>
      
      <div className={openString}>
        <div className="modal-background"></div>
        <ClickAwayListener onClickAway={this.handleClickAway}>
        <div className="modal-card relative" style={{backgroundImage:portadaUrl,backgroundSize:"cover",height:"94vh",borderRadius:"13px",marginLeft:"5vw",boxSizing:"content-box",marginRight:"5vw",marginTop:"3vh",marginBottom:"3vh"}}>
        <div style={{position:"absolute",right:"5px"}} onClick={this.handleClose} >
              <IconButton aria-label="close"  style={{marginRight:"15px"}}>
                <CloseIcon style={{color:"white"}} />
              </IconButton>
              </div>
          <Grid container justify="center" id="getVerified">
              <Stepper margin="none" alternativeLabel nonLinear activeStep={this.state.activeStep} style={{backgroundColor:"inherit",color:"white !important"}}>
                {this.state.steps.map((label, index) => {
                    const stepProps = {};
                    const buttonProps = {};
                    const handleStep = ()=>{
                      that.handleStep(index)
                    }
                    return (
                      <Step index={index} key={label} {...stepProps} style={{color:"white !important"}} onClick={handleStep}>
                        <StepButton
                          completed={this.state.completed[this.state.activeStep]}
                          step={index}
                          {...buttonProps}
                        >
                          <div style={{color:"white"}}></div>
                        </StepButton>
                      </Step>
                    );
  
                })}
              </Stepper>
   
          </Grid>
          <div className="mainBody">
          <div style={{color:"white",marginLeft:"25px",position:"relative",top:"3vh",marginBottom:"10vh"}}>
            <h1>{this.state.steps[this.state.activeStep]}</h1>
          </div>
          
          <div style={{marginTop:"0px"}}>
          <Content addressOpen={this.state.addressOpen} handleNewAddressOpen={this.handleNewAddressOpen}  handleSave={this.handleSave} listing={this.props.listing} activeStep={this.state.activeStep}
          userPack={userPack} handleClose={this.handleClose}/>
                 <div className="wide  b3" style={{position:"relative",bottom:"-10vh"}}>
                    <div className="wide relative">
                        <div className="half">
                          <div>
                          {this.state.activeStep!==0?
                      <Button style={{color:"white"}} onClick={this.handleBack} color="default" variant="outlined">
                        Atras
                      </Button>
                        :
                        <Button style={{color:"white"}} onClick={this.handleClose} color="default" variant="outlined">
                          Cancelar
                        </Button>
                        }
                          </div>
                          <div >
                          <div className=" flexEnd r5">
                          <div  className="r3">
                      {this.state.activeStep+1===this.state.steps.length?
                         <Button style={{color:"white"}} onClick={this.handleSave} color="secondary" variant="outlined" disabled>
                         Guardar
                       </Button>
                       
                         :
                         <Button style={{color:"white"}} onClick={this.handleNext} color="secondary" variant="outlined">
                           Continuar
                         </Button>
                         }
                      </div>
                        </div>
                        </div>
                    
                    </div>

                    </div>
                   
              </div>
          </div>
   
              </div>
                </div>
                </ClickAwayListener>
              </div>
             
              </>
     );
  }
}
 
export default NewListing;

import React from 'react';

export default function(){
    return(
        <>
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="13" cy="13" r="12.25" fill="white" fill-opacity="0.63" stroke="black" stroke-width="1.5"/>
<path d="M6.5 13.5L10.5 18.5L20 8" stroke="black" stroke-width="1.5"/>
</svg>
        </>
    )
}




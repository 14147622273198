import React from 'react';
import Signature from './maincomponents/Signature';
import MainNavBar from './elements/MainNavBar';
export default function(props){
    return(
        <>
        <MainNavBar navColor="black" userPack={props.userPack}/>
        <div className="wide relative">
        <div className="mainBody">
            <div className="wide alignCenter t4 b5">

            <h1>Coming soon</h1>
            </div>
           
            
        </div>
        <div className="wide t5">
        <Signature userPack={props.userPack}/></div>
        </div>
       
        </>
    )
}
import React, { Component } from 'react';
import NavBar from './maincomponents/NavBar';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from './userdashboardcomponents/Tabs';
import AdministrarListings from './userdashboardcomponents/AdministrarListings';
import EstadosFinancieros from './userdashboardcomponents/EstadosFinancieros';
import EditFinal from './userdashboardcomponents/EditFinal';
import Signature from './maincomponents/Signature';
import ConfirmationDialog from './maincomponents/ConfirmationDialog';
import Home from './userdashboardcomponents/Home';
import CloneDialog from './maincomponents/CloneDialog';
import HistorialAlquiler from './HistorialAlquiler';
import Agenda from './Agenda';
import Consejos from './Consejos';
import HideOnScroll from './maincomponents/HideOnScroll';
import Entrega from './userdashboardcomponents/EditListingTabsComponents/Entrega';
import Chat from './maincomponents/Chat';
import MainNavBar from './elements/MainNavBar';
import UserId from './Components/UserId';


const getUrl = window.location;

class UserDashBoard extends Component {

  state = {
  selectedTab:0,
  drawerIsOpen:false,
  confirmationDialog:{open:false,content:"",parameter:""},
  cloneDialog:{open:false,listing:{}}
  };
  constructor(props){
    super(props)
    if(getUrl.href.includes("?code") && !props.userPack.fetchedUser){
      console.log("positivo dotol")
      props.userPack.dialog.handleOpen("register")
      //this.props.userPack.loginElements.handleRegister()
    }
  }
  closeDrawer = () => {
    if(this.state.drawerIsOpen==false){
      return;
    }
    this.setState({ drawerIsOpen: false });
};

  closeEditing = () =>{
    this.closeDrawer()
    return
  }

  openDrawer = (e) => {
    const brand = e.vehicle.brand
    if(brand!=="Marca"){
      this.props.userPack.fetchModels(brand)
    }
    const rate = parseFloat(this.props.userPack.elements.currency.rates[this.props.userPack.elements.currency.selected]).toFixed(2)
    let myListing = e;
    // myListing.dailyPrice=myListing.dailyPrice*rate
    // myListing.weeklyPrice=myListing.weeklyPrice*rate
    this.setState({selectedListing:myListing}, () =>{
      this.setState({ drawerIsOpen: true });
    })
    
    return
  };



  selectTab = (e) =>{
    this.setState({selectedTab:e})
  };
  
  closeConfirmationDialog = ()=>{
    this.setState({confirmationDialog:{open:false,content:""}})
  }
  openConfirmationDialog = (selected,parameter)=>{
    this.setState({confirmationDialog:{open:true,content:selected,parameter:parameter}})
  }
  openEditListingModal = (e) =>{
    this.openDrawer(e)
  }
  closeEditListingModal = () =>{
    this.closeDrawer()
  }
  openNewListingModal = () =>{
    this.openDrawer()
  }
  closeNewListingModal = () =>{
    this.closeDrawer()
  }
  handleMethod = (content,parameter) => {
    switch(content){
      case "delete":
        this.closeConfirmationDialog()
        break
    }
  }
  openCloneDialog = (listing)=>{
    this.setState({confirmationDialog:{open:false,content:""}}, () =>{
      this.setState({cloneDialog:{open:true,listing:listing}});
    })
    
  }
  closeCloneDialog = (listing)=>{
    this.setState({cloneDialog:{open:false,listing:listing}})
  }
  render() {
    const user=this.props.user
    const that = this
    const actions = {
      listings:{
        openEditListingModal:this.openEditListingModal,
        closeEditListingModal:this.closeEditListingModal,
        openNewListingModal:this.openNewListingModal,
        closeNewListingModal:this.closeNewListingModal,
        selectTab:this.selectTab,
        openCloneDialog:this.openCloneDialog,
        closeCloneDialog:this.closeCloneDialog
      }
    }
    const listings=this.props.userPack.listings
    const smallScreen = this.props.userPack.dimensions.width<600
    const handleOpen = ()=>{
      console.log("y q maldita baina del diablo")
      this.props.userPack.dialog.handleOpen()
    }
    const symbol = this.props.userPack.elements.currency.symbols.filter(c=>c.value==this.props.userPack.elements.currency.selected)[0].label;
    const rate = parseFloat(this.props.userPack.elements.currency.rates[this.props.userPack.elements.currency.selected]).toFixed(2)
    
    const businessDisplay = (index,actions) =>{
      switch(index){
        case 0:
          return(<Home user={user} userPack={that.props.userPack}  selectTab={actions.listings.selectTab} smallScreen={smallScreen}
            handleOpen={handleOpen} logout={this.props.userPack.methods.logout} userPack={this.props.userPack}
            openDialog={that.openConfirmationDialog} openClone={actions.listings.openCloneDialog} closeClone={actions.listings.closeCloneDialog}/>);
            case 1:
              return(
                <Agenda user={user} userPack={that.props.userPack} />
              );
         
            case 2:
            return(
            <AdministrarListings
            user={user} userPack={that.props.userPack} actions={actions.listings}  switchListing={that.props.userPack.switchListing}
            smallScreen={smallScreen} handleOpen={this.props.userPack.dialog.handleOpen} listings={listings} listingAPI={that.props.userPack.listingAPI}
             openDialog={that.openConfirmationDialog} width={that.props.userPack.dimensions.width} />
            );
              case 3:
                  return(
                  <EstadosFinancieros rate={rate} symbol={symbol} user={user} smallScreen={smallScreen}/>
                  );
                      case 4:
                        return(<HistorialAlquiler user={user} smallScreen={smallScreen}/>)
                          case 6:
                            return(<Consejos smallScreen={smallScreen}/>)
                            case 5:
                              return(<Entrega userPack={that.props.userPack} smallScreen={smallScreen} updateMyListing={that.props.userPack.updateMyListing}/>)
      }}

    const consumerDisplay = (index, actions) =>{
      switch(index){
        case 0:
          break
          case 1:
              break
              case 2:
                  break
                  case 3:
                      break
                      case 4:
                          break
                          case 5:
                              break
                              case 6:
                                  break
      }}

      return (
        <React.Fragment>
          {this.props.userPack.logged?
          <>
          <MainNavBar userPack={this.props.userPack} navColor="black"/>
          <div className="overflowHidden" style={{position:"relative",top:"65px",backgroundColor:"white",color:"rgba(0, 0, 0, 0.75)"}}>


          <Tabs
          selectedTab={this.state.selectedTab}
          selectTab={this.selectTab}
          isBusiness={true}
          />
          {this.props.user.business
          ?
          businessDisplay(this.state.selectedTab,actions)
          :
          businessDisplay(this.state.selectedTab,actions)
          }
          {this.state.drawerIsOpen
          ?
          <EditFinal listing={this.state.selectedListing} panel={this.state.drawerIsOpen} height={this.props.userPack.dimensions.height-100}
          isMobile={this.props.userPack.dimensions.width<759} open={this.state.drawerIsOpen} handleClose={this.closeEditing} handleSave={this.saveListing}
          userPack={this.props.userPack}/>
          :
          null
          }
          {this.state.confirmationDialog.open
          ?
          <ConfirmationDialog selected={this.state.confirmationDialog.content} parameter={this.state.confirmationDialog.parameter} 
          close={this.closeConfirmationDialog} yes={this.handleMethod} userPack={this.props.userPack}
          clone={this.openCloneDialog} user={this.props.user} currency={this.props.currency} vehicles={this.props.vehicles}/>
          :
          null
          }
          {this.state.cloneDialog.open
          ?
          <CloneDialog open={this.state.cloneDialog.open}  handleClose={this.closeCloneDialog} listing={this.state.cloneDialog.value}/>
          :
          null
          }
          <Signature userPack={this.props.userPack}/>

          </div>
          </>:
          <UserId userPack={this.props.userPack}/>}
          
        </React.Fragment>
  );
}}

export default UserDashBoard;
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import { Link } from "@reach/router";
import { Button } from '@material-ui/core';
import FbSkeleton from '../maincomponents/FbSkeleton';
function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export default function MediaCard(props) {
  let myWidth = 400
  if(props.width>400){
    myWidth=props.width
  }
  const desktopWidth=(myWidth-(myWidth/3)-50)/2
  const mobileWidth=(myWidth-myWidth*0.05)
  const desktopHeight=(desktopWidth/1.6)
  const mobileHeight=(mobileWidth/1.6)
  
  const handleClear =()=>{
    let newFilters = {priceRange:[30,200],priceMaximum:[0,300],sortBy:null,deliveryFee:30,map:true}
    props.userPack.filterMethods.handleFilters(newFilters)
    props.userPack.filterMethods.setFilteredResults(null)
  }
  const Precio = (props)=>{
    const isMobile=props.userPack.dimensions.width<800
    const margin = isMobile?"0px":"0px";
    const selectedSymbol = props.userPack.elements.currency.symbols.filter(c=>c.value==props.userPack.elements.currency.selected)[0].label;
    const rate = parseFloat(props.userPack.elements.currency.rates[props.userPack.elements.currency.selected]).toFixed(2)
    const listing = props.listing
    return(
      <>
      {!isMobile?
        <Grid item xs={12} style={{backgroundColor:"white"}} >
          <Grid container justify="center">
              <Grid item xs="auto">
                <Typography variant="h4" style={{fontWeight:"bold"}}>
                  {selectedSymbol}{formatNumber(Math.ceil(listing.dailyPrice*rate))}/día
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <Typography style={{fontWeight:"bold",position:"relative",top:"9px",marginLeft:"6px"}}>
                  por día
                </Typography>
              </Grid>
          </Grid>
        </Grid>
      :
      <Grid item xs={12} style={{backgroundColor:"white",maxWidth:"300px"}}>
      <Grid container justify="center">
        <Grid item xs="auto">
              <Typography variant="h4" style={{fontWeight:"bold"}}>
              {selectedSymbol}{formatNumber(Math.ceil(listing.dailyPrice*rate))}/día
              </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container justify="center">
                 <Grid item xs="auto">
                 <Typography style={{fontWeight:"bold"}}>
                por día
                </Typography>
                </Grid>

              </Grid>
              </Grid>
    </Grid>
  </Grid>
      }

      </>
    )
  }
  const TarjetaDesktop =(props)=>{
    const amountViajes = props.listing.bookingHistory.length===1?props.listing.bookingHistory.length+" viaje":props.listing.bookingHistory.length+" viajes"
    const selectedSymbol = props.userPack.elements.currency.symbols.filter(c=>c.value==props.userPack.elements.currency.selected)[0].label;
    const rate = parseFloat(props.userPack.elements.currency.rates[props.userPack.elements.currency.selected]).toFixed(2)
    const listing = props.listing
    const handleHoverIn = ()=>{
      props.handleHovered(props.listing.id)
    }
    const handleHoverOut = ()=>{
      props.handleHovered(null)
    }
    return(
      <>
      <Grid item xs="auto" onMouseEnter={handleHoverIn} onMouseLeave={handleHoverOut}>
        <Grid item xs="auto">
          <div style={{position:"relative"}}>
          <Link to={"/listings/"+props.listing.id}>
            <div style={{backgroundImage:"url("+props.listing.mainPicture.url+")",backgroundSize:"cover",backgroundRepeat: "no-repeat", backgroundPosition: "center center",height:desktopHeight+"px",width:desktopWidth+"px"}}>
            
            </div>
          
          </Link>
          <h1 className="priceTaggy" style={{position:"absolute",bottom:"0px"}}>{selectedSymbol}{formatNumber(Math.ceil(listing.dailyPrice*rate))}/día</h1>
          </div>
        </Grid>
        <Grid item xs={12}>
        <Typography variant="h6" style={{fontWeight:"bold"}}>
        {props.listing.vehicle.brand + " "+props.listing.vehicle.model +" "+props.listing.vehicle.year}
        </Typography>
        </Grid>
        <Grid container>
          <Grid item xs="auto">
            <Box component="fieldset" borderColor="transparent">
              <Rating value={props.listing.overAllRating} readOnly/>
            </Box>
          </Grid>
          <Grid item xs="auto">
            <Typography variant="body2" style={{position:"relative",top:"3px"}}>
            {amountViajes} 
          </Typography>
          </Grid>
        </Grid>
        </Grid>
      </>
    );
  }
  const TarjetaMobile =(props)=>{
    const amountViajes = props.listing.bookingHistory.length===1?props.listing.bookingHistory.length+" viaje":props.listing.bookingHistory.length+" viajes"
    const selectedSymbol = props.userPack.elements.currency.symbols.filter(c=>c.value==props.userPack.elements.currency.selected)[0].label;
    const rate = parseFloat(props.userPack.elements.currency.rates[props.userPack.elements.currency.selected]).toFixed(2)
    const listing = props.listing
    const handleHoverIn = ()=>{
      props.handleHovered(props.listing.id)
    }
    const handleHoverOut = ()=>{
      props.handleHovered(null)
    }
    return(<>
    <Grid item xs="auto" onMouseEnter={handleHoverIn} onMouseLeave={handleHoverOut}>
        
    <Grid item xs="auto">
          <div style={{position:"relative"}}>
          <Link to={"/listings/"+props.listing.id}>
            <div style={{backgroundImage:"url("+props.listing.mainPicture.url+")",backgroundSize:"cover",backgroundSize:"cover",backgroundRepeat: "no-repeat", backgroundPosition: "center center",height:mobileHeight+"px",width:mobileWidth+"px"}}>
            
            </div>
          
          </Link>
          <h1 className="priceTaggy" style={{position:"absolute",bottom:"0px"}}>{selectedSymbol}{formatNumber(Math.ceil(listing.dailyPrice*rate))}/día</h1>
          </div>
        </Grid>
          <Grid item xs={12}>
          <Typography variant="h5" style={{fontWeight:"bold"}}>
            {props.listing.vehicle.brand + " "+props.listing.vehicle.model +" "+props.listing.vehicle.year}
          </Typography>
          </Grid>
          <Grid container>
            <Grid item xs="auto">
              <Box component="fieldset" borderColor="transparent">
                <Rating value={props.listing.overAllRating} readOnly/>
              </Box>
            </Grid>
            <Grid item xs="auto">
              <Typography variant="body2" style={{position:"relative",top:"3px"}}>
              {amountViajes} 
              </Typography>
            </Grid>
          </Grid>
          </Grid>
    </>);
  }
  return (
      <Grid container spacing={2} justify="center" style={{marginTop:props.width>960?"40px":"0px"}}>

      {props.fetched?
      <>
            {(props.width>960)?
              
            props.results.map((listing,index) => (
              <TarjetaDesktop handleHovered={props.handleHovered} key={listing.id} listing={listing} userPack={props.userPack}/>
              ))
            : props.results.map((listing,index) => (
              <TarjetaMobile handleHovered={props.handleHovered} key={listing.id} listing={listing} userPack={props.userPack}/>
              ))
            }
            </>
      :
      <>
      <div style={{margin:"10px"}}>
      <FbSkeleton width={props.width} loading={true}/></div>
      <div style={{margin:"10px"}}>
      <FbSkeleton width={props.width} loading={true}/></div>
      </>
      }

      {props.results.length===0 && props.fetched?
        <><Grid container style={{marginTop:"120px",marginBottom:"120px"}}>
            <Grid item xs={12}>
              <Grid container justify="center">
                <Grid item xs="auto">
                  <Typography variant="h4">No hay resultados</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="center">
                  <Grid item xs="auto">
                    <Button onClick={handleClear} variant="outlined">Borrar filtros aplicados</Button>
                  </Grid>
                </Grid>
            </Grid>
          </Grid>
        </>
        :null}

      </Grid>
  );
}
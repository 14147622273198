import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import GoogleMapReact from 'google-map-react';
import Address from './Address';
import TextField from '@material-ui/core/TextField';
import SelectState from './SelectState';
import RoomIcon from '@material-ui/icons/Room';

const getUrl = window.location;
let neoUrl
let baseUrl = "https://ur8z3iogad.execute-api.us-east-2.amazonaws.com/dev/";
if(getUrl.host.includes("249:3000")){
  neoUrl=getUrl.host.substring(0,getUrl.host.length-5)
  baseUrl = getUrl.protocol+ "//" + neoUrl +":8000/";
}else{
  neoUrl = getUrl.host
}


const axios = require('axios');
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = false;

//const AnyReactComponent = ({ text }) => <h1 className="secondTitle" style={{color:"black" }}>{text}</h1>;
const AnyReactComponent = ({ text }) => <RoomIcon style={{fontSize:"32px",color:"red",position:"relative",bottom:"28px",right:"15px"}}/>

const styles = theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  
  const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
            <p>close</p>
        ) : null}
      </MuiDialogTitle>
    );
  });
  const DialogContent = withStyles(theme => ({
    root: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  }))(MuiDialogContent);
  
  const DialogActions = withStyles(theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
  }))(MuiDialogActions);

  export default function(props){

    let landmark = ""
    let line1 = ""
    let line2 = ""
    let city = ""
    let zipcode = ""
    let rawState = null
    let rawCountry = null
    //maraca
    if(props.userPack.user.homeAddress){
        landmark=props.userPack.user.homeAddress.landmark
        line1=props.userPack.user.homeAddress.line1
        line2=props.userPack.user.homeAddress.line2?props.userPack.user.homeAddress.line2:""
        city=props.userPack.user.homeAddress.city
        rawState=props.userPack.user.homeaddress.state
        zipcode=props.userPack.user.homeAddress.zipcode
        rawCountry=props.userPack.user.homeaddress.country
    }
    let [state,setState]=React.useState(rawState)
    let [country,setCountry]=React.useState(rawCountry)
    let [stateList,setStateList]=React.useState(props.userPack.elements.states)
    const selectCountry = (country)=>{
      setCountry(country);
      const newStateList=stateList.filter(c=>c.country.id==country.id)
      setStateList(newStateList)
    }
    const selectState = (state,country)=>{
      setState(state);
      setCountry(country);
    }
  
  const [markerLat,setLat] = React.useState(props.userPack.elements.latitude)
  const [markerLng,setLng] = React.useState(props.userPack.elements.longitude)
  const [advance,setAdvance] = React.useState(false)
  const [step,setStep]=React.useState(0)

  const [values,setValues]=React.useState({landmark:landmark,line1:line1,line2:line2,city:city,state:state,zipcode:zipcode,country:country})
  const handleChange = name => event => {
    setValues({...values,
        [name]: event.target.value,})
    //props.userPack.updateMyUser([name],event.target.value)
  };

  const handleClose=()=>{
    props.userPack.dialog.handleClose()
  }
  const handleMarker=(e)=>{
    setAdvance(true)
    setLat(e.lat)
    setLng(e.lng)
  }
  const handleSave=()=>{
    let newUser = props.userPack.user
    let myValues = {...values}
    let lat = markerLat.toString()
    let lng = markerLng.toString()
    myValues.xCoordinate=lat
    myValues.yCoordinate=lng
    myValues.customer=newUser.id
    myValues.id=null
    myValues.country="República Dominicana"
    newUser.deliveryAddressList.push(myValues)
    props.userPack.userAPI(newUser)
    if(props.getVerified){
      props.handleNewAddressOpen()
      props.handleSave()
    }
  }
  const handleNext=()=>{
    if(step===1){
        handleSave()
        handleClose()
        return
    }
      setStep(prev=>prev+1)
  }
  const handleBack=()=>{
      setStep(prev=>prev-1)
  }

  const mapWidth = props.userPack.dimensions.width<600?"100vw":"100%"

    return(
        <>
        <Dialog
            open={props.open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Agregar localidad para entregas"}</DialogTitle>
            <DialogContent>
                {step===0?
                                <div style={{ height: '65vh', width: mapWidth,minWidth:"59vw" }}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key:"AIzaSyDeH9xTN_AEFa5cOucYhTlnN-HHDQV_oeY&v=3.exp&libraries=geometry,drawing,places" }}
                                    defaultCenter={{lat:props.userPack.elements.latitude,lng:props.userPack.elements.longitude}}
                                    defaultZoom={13}
                                    onClick={handleMarker}
                                >
                                <AnyReactComponent
                                        lat={markerLat}
                                        lng={markerLng}
                                        text="Mi Lugar"
                                        />
                                 </GoogleMapReact>
                            </div>
                    :null}
                {step===1?
                <>          <div style={{padding:"8px"}}>
                                <Grid container style={{marginTop:"5px"}}>
                                <Grid item xs={12}>
                                <TextField
                                style={{width:"100%",color:"black"}}
                                    required
                                    autoFocus
                                    id="black-required"
                                    label="Dirección (línea 1)"
                                    margin="normal"
                                    name="line1"
                                    value={values.line1}
                                    onChange={handleChange('line1')}
                                    variant="outlined"
                                    InputLabelProps={{
                                      shrink: true,
                                  }}
                                    />
                                     </Grid>
                                
                                </Grid>
                                <Grid item xs={12}>
                                <TextField
                                style={{width:"100%",color:"black"}}
                                    id="black-required2"
                                    label="Dirección (línea 2)"
                                    margin="normal"
                                    value={values.line2}
                                    onChange={handleChange('line2')}
                                    variant="outlined"
                                    name="line2"
                                    InputLabelProps={{
                                      shrink: true,
                                  }}
                                    />
                                </Grid>
                                <Grid container>
                                  <Grid item xs={6}>
                                  <TextField
                                  style={{width:"100%",color:"black"}}
                                  required
                                      id="black-required3"
                                      label="Ciudad"
                                      margin="normal"
                                      name="city"
                                      value={values.city}
                                      onChange={handleChange('city')}
                                      variant="outlined"
                                      InputLabelProps={{
                                          shrink: true,
                                      }}
                                      />
                                  </Grid>
                                  <Grid item xs={6}>
                                              <TextField
                                  style={{width:"100%",color:"black"}}
                                      required
                                      id="standard-required"
                                      label="Estado/Provincia"
                                      margin="normal"
                                      value={values.state}
                                      name="state"
                                      onChange={handleChange('state')}
                                      variant="outlined"
                                      InputLabelProps={{
                                          shrink: true,
                                      }}
                                      />
                                  
                                  </Grid>
                                  
                                </Grid>
                                </div>
                                </>
                    :null}
            </DialogContent>
            
            <DialogActions>
                <Grid item container>
                    <Grid item xs={6}>
                        <Grid container justify="flex-start">
                        {advance?
                            <>
                           
                                {step===0?
                                     <Button color="default" onClick={handleClose}>
                                        Cancelar
                                    </Button>
                                    :
                                    <Button color="default" onClick={handleBack}>
                                        Atras
                                    </Button>
                                    }
                            
                            </>
                        :
                        <Button color="default" onClick={handleClose}>
                            Cancelar
                        </Button>
                        }
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container justify="flex-end">
                            {advance?
                            
                                <Button color="secondary" onClick={handleNext}>
                                    {step===0?"Continuar":"Guardar"}
                                </Button>
                            :
                            <Button color="secondary"  disabled>
                                Continuar
                            </Button>
                            }

                        </Grid>
                    </Grid>
                </Grid>


            </DialogActions>
        </Dialog>
        </>
    );
}


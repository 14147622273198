import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import VerifyBook from './dialogcomponents/VerifyBook';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Step from '@material-ui/core/Step';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import StepButton from '@material-ui/core/StepButton';
import IntroBook  from './dialogcomponents/IntroBook';
import Payment  from './dialogcomponents/Payment';
import BotonReservar  from './dialogcomponents/BotonReservar';


const Content = (props)=>{
  switch(props.activeStep){
    case 0:
      return(
        <IntroBook userPack={props.userPack}/>
      );
      case 1:
        return(
          <VerifyBook
          userPack={props.userPack} listing={props.userPack.book.listingForRent}/>
      );
    case 2:
        return(
          <Payment
          userPack={props.userPack}/>
      );
  }}

class NewListing extends Component {
  state = { 
    loading:false,
    activeStep:0,
    completed:[false,false,false,false],
    steps:['','Verifique los datos de su renta', 'Método de pago'],
    listing:this.props.userPack.myListing
  }
  handleKeyPress = (e)=>{
    if(e.key==="Enter"){
      if(this.state.activeStep===2){
        return
      }
      this.handleNext()
    }
    if(e.key==="Escape"){
      this.handleClose()
    }
  }
  handleClickAway=()=>{
    this.handleClose();
  }
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
  }
  // change code above this line
  handleEnter() {
    this.setState({
      message: this.state.message + 'You pressed the enter key! '
    });
  }
  handleClose = ()=>{
    this.props.handleClose("noUlterior")
    
  }
  handleSave = ()=>{
    let myNewUser = this.props.userPack.user
    myNewUser.verified = true
    this.props.userPack.bookAPI(myNewUser).then(resp=>{
      this.props.handleClose()
    })
    
    
  }

  handleStep =(e)=>{
    this.setState({activeStep:e})
  };

  handleBack = () => {
    this.setState({activeStep:this.state.activeStep-1})
  };

  handleNext = () => {
    this.setState({activeStep:this.state.activeStep+1})
  };

  handleComplete = (index) => {
    let completed = [...this.state.completed]
    completed[index]=true
    this.setState({completed:completed})
  };

  handleClickAway=()=>{
    this.handleClose()
  }

  render() {
    const openString = this.props.open?"modal is-active":"modal";
    const that=this;
    const userPack=this.props.userPack
    const offset=userPack.dimensions.width<500?"-13px":"20px"
    const portadaUrl = userPack.dimensions.width<600?"url('../getverifiedmobile.jpg')":"url('../getverifieddesktop.jpg')"
    return ( 
      <>
      
      <div className={openString}>
        <div className="modal-background"></div>
        <ClickAwayListener onClickAway={this.handleClickAway}>
        <div className="modal-card" style={{backgroundImage:portadaUrl,backgroundSize:"cover",borderRadius:"13px"}}>
        <div style={{position:"absolute",right:"5px"}} onClick={this.handleClose} >
              <IconButton aria-label="close"  style={{marginRight:"15px"}}>
                <CloseIcon style={{color:"white"}} />
              </IconButton>
              </div>
          <Grid container justify="center" id="getVerified">
              <Stepper margin="none" alternativeLabel nonLinear activeStep={this.state.activeStep} style={{backgroundColor:"inherit",color:"white !important"}}>
                {this.state.steps.map((label, index) => {
                    const stepProps = {};
                    const buttonProps = {};
                    const handleStep = ()=>{
                      that.handleStep(index)
                    }
                    return (
                      <Step index={index} key={label} {...stepProps} style={{color:"white !important"}} onClick={handleStep}>
                        <StepButton
                          completed={this.state.completed[this.state.activeStep]}
                          step={index}
                          {...buttonProps}
                        >
                          <div style={{color:"white"}}></div>
                        </StepButton>
                      </Step>
                    );
  
                })}
              </Stepper>
   
          </Grid>
          <div style={{color:"white",marginLeft:"25px",marginTop:this.props.userPack.dimensions.width>600?"80px":"20px"}}>
          <h1 >{this.state.steps[this.state.activeStep]}</h1>
          </div>
          
          <div style={{marginTop:this.props.userPack.dimensions.width>600?"40px":"0px"}}>
          <Content listing={this.state.listing} activeStep={this.state.activeStep}
          userPack={userPack} handleClose={this.handleClose}/>
          </div>
          

            <Grid container style={{position:"absolute",bottom:"0px"}}>
                <Grid item xs={6}>
                  <Grid container justify="flex-start" style={{position:"absolute",bottom:"0px"}}>
                    <Grid item xs="auto">
                    {this.state.activeStep!==0?
                      <Button style={{color:"white",position:"relative",bottom:"20px",left:"20px"}} onClick={this.handleBack} color="default" variant="outlined">
                        Atras
                      </Button>
                        :
                        <Button style={{color:"white",position:"relative",bottom:"135px",left:this.props.userPack.dimensions.width<600?"40px":"95px"}} onClick={this.handleClose} color="default" variant="outlined">
                          Cancelar
                        </Button>
                        }
                    
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container justify="flex-end">
                    <Grid item xs="auto">
                      {this.state.activeStep+1===this.state.steps.length?
                         <BotonReservar userPack={this.props.userPack}/>
                         :
                         <>
                         {this.state.activeStep===0?
                         <Button style={{color:"white",position:"relative",bottom:"135px",right:this.props.userPack.dimensions.width<600?"40px":"95px"}} onClick={this.handleNext} color="primary" variant="outlined">
                           Continuar
                         </Button>
                         :
                         <Button style={{color:"white",position:"relative",bottom:"20px",right:"20px"}} onClick={this.handleNext} color="primary" variant="outlined">
                         Continuar
                       </Button>
                         }
                         </>
                         }
                      
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
                </div>
                </ClickAwayListener>
              </div>
              </>
     );
  }
}
 
export default NewListing;
import React from 'react';
import {Link} from '@reach/router';

export default function(props){

    const neoSelected=(e)=>{
      props.userPack.methods.neoSelected(e.target.parentElement.type)
      return
    }
    const openLogin=()=>{
        let newVal = {...props.userPack.loginModal}
        newVal.active=true
        newVal.login=true
        props.userPack.switchLoginModal(newVal)
      }
      const openRegister=()=>{
        let newVal = {...props.userPack.loginModal}
        newVal.active=true
        newVal.login=false
        props.userPack.switchLoginModal(newVal)
      }
      let idHome = "#484848"
      let idSearch = "#484848"
      let idProfile = "#484848"
      let idSaved = "#484848"
      let idChat = "#484848"
      let selected=false
      if(props.userPack.selected){
      switch (props.userPack.selected) {
          case "home":
            idHome = "black"
              break;
              case "search":
                idSearch = "black"
                  break;
                  case "saved":
                    idSaved = "black"
                      break;
                      case "chat":
                        idChat = "black"
                          break;
                          case "profile":
                            idProfile = "black"
                              break;
      
      }}else{
      if(window.location.href.includes('profile')){
        idProfile="black"
        selected=true
      }
      if(window.location.href.includes('listings')){
        idSearch="black"
        selected=true
      }
      if(window.location.href.includes('chat')){
        idChat="black"
        selected=true
      } 
      if(window.location.href.includes('loginMobile')){
        idProfile="black"
        selected=true
      }
      if(!selected){
        idHome="black"
      }
    }
    return(
        <>
        {props.userPack.user?
        <div className="bottomNavbar">
        <div className="bottomIcon">
        <svg  className="verticalCenter" width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.6399 11.3087C25.6394 11.3082 25.6388 11.3076 25.6382 11.307L15.0322 0.701416C14.5802 0.249146 13.9791 0 13.3398 0C12.7005 0 12.0994 0.248947 11.6471 0.701218L1.04675 11.3014C1.04318 11.305 1.03961 11.3087 1.03604 11.3123C0.107693 12.246 0.10928 13.7609 1.0406 14.6922C1.46609 15.1179 2.02806 15.3645 2.6289 15.3903C2.6533 15.3927 2.6779 15.3939 2.70269 15.3939H3.12541V23.1989C3.12541 24.7434 4.38205 26 5.92691 26H10.0763C10.4968 26 10.838 25.659 10.838 25.2383V19.1191C10.838 18.4144 11.4113 17.8411 12.1161 17.8411H14.5635C15.2683 17.8411 15.8415 18.4144 15.8415 19.1191V25.2383C15.8415 25.659 16.1825 26 16.6033 26H20.7527C22.2975 26 23.5541 24.7434 23.5541 23.1989V15.3939H23.9461C24.5852 15.3939 25.1863 15.1449 25.6388 14.6926C26.5711 13.7597 26.5715 12.2422 25.6399 11.3087Z" fill={idHome}/>
        </svg>
        <Link to="/" onClick={neoSelected} type="home" className="bottomIcon">
            <div className="centerX" style={{width:"100px",position:"absolute",height:"48px",zIndex:"15",bottom:"-10px"}}></div>
        </Link>
        </div>
        <div className="bottomIcon">
       <svg style={{marginLeft:"0px"}} width="29" height="29" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="8" cy="8" r="6.5" stroke={idSearch} strokeWidth={idSearch==="black"?"3":"1"}/>
                            <line x1="12.3422" y1="12.7137" x2="18.659" y2="18.2478" stroke={idSearch} strokeWidth={idSearch==="black"?"3":"1"}/>
                            </svg>
                          <Link to="/listings" onClick={neoSelected} type="search" className="middleIcon">
                          <div className="centerX" style={{width:"100px",position:"absolute",height:"48px",zIndex:"15",bottom:"-10px"}}></div>
        </Link>
        </div>
        <div className="bottomIcon">
        <svg width="28" height="28" viewBox="0 0 44 41" fill="none" xmlns="http://www.w3.org/2000/svg" >
<path d="M40.6721 5.50024C49.0182 18.0001 31.8352 32.0002 22.5073 39.0002C13.1794 32.0002 -4.23599 18.2437 4.11002 5.74389C8.43123 -0.727979 20.3799 2.00024 22.5073 6.50024C24.6347 2.00024 36.3509 -0.971631 40.6721 5.50024Z" stroke={idSaved} strokeWidth={idSaved==="black"?"5":"2"}/>
</svg>

        <Link to="/saved" className="bottomIcon" onClick={neoSelected} type="saved" >
            <div className="centerX" style={{width:"100px",position:"absolute",height:"48px",zIndex:"15",bottom:"-10px"}}></div>
        </Link>
        </div>
        <div className="bottomIcon">
        <svg width="28" height="28" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.4929 43.9998L27.4023 35.9998C31.0026 35.8331 39.1848 35.9998 41.6397 34.4997C44.0946 32.9996 43.6035 6.28547 41.6397 3.99979C39.6759 1.71415 27.4023 1.66646 22.4929 1.99979" stroke={idChat} strokeWidth={idChat==="black"?"6":"2"}/>
<path d="M22.6824 44.1502L17.773 36.1502C14.1727 35.9835 5.99052 36.1502 3.53563 34.6501C1.08074 33.15 1.57183 6.43587 3.53563 4.15018C5.4994 1.86454 17.773 1.81685 22.6824 2.15018" stroke={idChat} strokeWidth={idChat==="black"?"6":"2"}/>
</svg>

        <Link to="/chat" className="bottomIcon" onClick={neoSelected} type="chat" >
            <div className="centerX" style={{width:"100px",position:"absolute",height:"48px",zIndex:"15",bottom:"-10px"}}></div>
        </Link>
        </div>
        
        <div className="bottomIcon">
          {props.userPack.user.profilePicture?
            <div className={props.userPack.selected==='profile'||window.location.href.includes('profile')?'profilePicture centerX selectedBorder':'profilePicture centerX'} style={{left:"40%",backgroundImage:"url("+props.userPack.user.profilePicture.url+")",backgroundSize:"cover"}}>

            </div>
          :        <svg className="verticalCenter" width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.3396 0.0975342C8.5533 0.0975342 5.4729 3.17793 5.4729 6.96423C5.4729 10.7505 8.5533 13.8309 12.3396 13.8309C16.1259 13.8309 19.2063 10.7505 19.2063 6.96423C19.2063 3.17793 16.1259 0.0975342 12.3396 0.0975342ZM12.3396 12.305C9.3947 12.305 6.99883 9.90913 6.99883 6.96423C6.99883 4.01933 9.3947 1.62347 12.3396 1.62347C15.2845 1.62347 17.6804 4.01933 17.6804 6.96423C17.6804 9.90913 15.2845 12.305 12.3396 12.305Z" fill={idProfile}/>
        <path d="M20.8831 18.3169C19.0032 16.4081 16.511 15.3569 13.8656 15.3569H10.8137C8.16835 15.3569 5.67609 16.4081 3.79614 18.3169C1.9254 20.2164 0.895142 22.7237 0.895142 25.3772C0.895142 25.7985 1.23675 26.1401 1.65811 26.1401H23.0212C23.4425 26.1401 23.7841 25.7985 23.7841 25.3772C23.7841 22.7237 22.7539 20.2164 20.8831 18.3169ZM2.45464 24.6142C2.83689 20.2863 6.43992 16.8828 10.8137 16.8828H13.8656C18.2393 16.8828 21.8424 20.2863 22.2246 24.6142H2.45464Z" fill={idProfile}/>
        </svg>}

        <Link to="/profile" className="bottomIcon" onClick={neoSelected} type="profile" >
            <div className="centerX" style={{width:"100px",position:"absolute",height:"48px",zIndex:"15",bottom:"-10px"}}></div>
        </Link>
        </div>
        </div>
        :
        <div className="bottomNavbar2">
        <div className="bottomIcon">
            
        <svg  className="verticalCenter" width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M25.6399 11.3087C25.6394 11.3082 25.6388 11.3076 25.6382 11.307L15.0322 0.701416C14.5802 0.249146 13.9791 0 13.3398 0C12.7005 0 12.0994 0.248947 11.6471 0.701218L1.04675 11.3014C1.04318 11.305 1.03961 11.3087 1.03604 11.3123C0.107693 12.246 0.10928 13.7609 1.0406 14.6922C1.46609 15.1179 2.02806 15.3645 2.6289 15.3903C2.6533 15.3927 2.6779 15.3939 2.70269 15.3939H3.12541V23.1989C3.12541 24.7434 4.38205 26 5.92691 26H10.0763C10.4968 26 10.838 25.659 10.838 25.2383V19.1191C10.838 18.4144 11.4113 17.8411 12.1161 17.8411H14.5635C15.2683 17.8411 15.8415 18.4144 15.8415 19.1191V25.2383C15.8415 25.659 16.1825 26 16.6033 26H20.7527C22.2975 26 23.5541 24.7434 23.5541 23.1989V15.3939H23.9461C24.5852 15.3939 25.1863 15.1449 25.6388 14.6926C26.5711 13.7597 26.5715 12.2422 25.6399 11.3087Z" fill={idHome}/>
        </svg>
        <Link to='/' onClick={neoSelected} type="home" >
        <div className="centerX" style={{width:"100px",position:"absolute",height:"48px",zIndex:"15",bottom:"-10px"}}></div>
        </Link>
        </div>
        <div className="bottomIcon">
       
        <svg style={{marginLeft:"0px"}} width="29" height="29" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="8" cy="8" r="6.5" stroke={idSearch} strokeWidth={idSearch==="black"?"3":"1"}/>
                            <line x1="12.3422" y1="12.7137" x2="18.659" y2="18.2478" stroke={idSearch} strokeWidth={idSearch==="black"?"3":"1"}/>
                            </svg>
                          <Link to="/listings" className="middleIcon" onClick={neoSelected} type="search" >
                          <div className="centerX" style={{width:"100px",position:"absolute",height:"48px",zIndex:"15",bottom:"-10px"}}></div>
        </Link>
        </div>
        <div className="bottomIcon">
         
        <svg className="verticalCenter" width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.3396 0.0975342C8.5533 0.0975342 5.4729 3.17793 5.4729 6.96423C5.4729 10.7505 8.5533 13.8309 12.3396 13.8309C16.1259 13.8309 19.2063 10.7505 19.2063 6.96423C19.2063 3.17793 16.1259 0.0975342 12.3396 0.0975342ZM12.3396 12.305C9.3947 12.305 6.99883 9.90913 6.99883 6.96423C6.99883 4.01933 9.3947 1.62347 12.3396 1.62347C15.2845 1.62347 17.6804 4.01933 17.6804 6.96423C17.6804 9.90913 15.2845 12.305 12.3396 12.305Z" fill={idProfile}/>
        <path d="M20.8831 18.3169C19.0032 16.4081 16.511 15.3569 13.8656 15.3569H10.8137C8.16835 15.3569 5.67609 16.4081 3.79614 18.3169C1.9254 20.2164 0.895142 22.7237 0.895142 25.3772C0.895142 25.7985 1.23675 26.1401 1.65811 26.1401H23.0212C23.4425 26.1401 23.7841 25.7985 23.7841 25.3772C23.7841 22.7237 22.7539 20.2164 20.8831 18.3169ZM2.45464 24.6142C2.83689 20.2863 6.43992 16.8828 10.8137 16.8828H13.8656C18.2393 16.8828 21.8424 20.2863 22.2246 24.6142H2.45464Z" fill={idProfile}/>
        </svg>
        
        <Link to="/profile" className="bottomIcon" onClick={neoSelected} type="profile" >
            <div className="centerX" style={{width:"100px",position:"absolute",height:"48px",zIndex:"15",bottom:"-10px"}}></div>
        </Link>
        </div>
        
       
        </div>
        }
        
        </>
    )
}
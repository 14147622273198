import React from 'react';
import NewListing from './NewListing';
import LoginModal from './LoginModal';
import CloneSelection from './CloneSelection'
import GetVerified from './GetVerified'
import CloneDialog from './CloneDialog'
import Review from './Review'
import Book from './Book'
import DeleteConfirmation from './dialogcomponents/DeleteConfirmation';
import NewAddress from './dialogcomponents/NewAddress';

export default function(props){
    const data=props.userPack.dialog.data
    const open=props.userPack.dialog.open
    const handleClose= props.userPack.dialog.handleClose
    const handleOpen=props.userPack.dialog.handleOpen
    const login=props.userPack.login
    const ogSwitch=props.userPack.dialog.handleSwitch
    const systemLogin=props.userPack.systemLogin
    const isMobile=props.userPack.dimensions.width<960
    const handleDelete=props.userPack.deleteListing
    const listings=props.userPack.listings
    const handleSwitch=()=>{
        ogSwitch(data.action)
    }
    const handleFirstList=()=>{
        props.userPack.dialog.handleClose()
        props.userPack.refs.routeMakeMoneyRef.current.click()
        
    }
    switch(data.action){
        case "newListing":
            return(
                <NewListing open={open} handleClose={handleClose} 
                currency={{preferred: "DOP", rate: 52.704663,rates:{DOP: 52.704663, EUR: 0.907128}}} vehicles={[{ label: 'Ford' },
            { label: 'Toyota' },
            { label: 'Honda' },
            { label: 'Maserati' },
            { label: 'Range Rover' },
            { label: 'Jeep' },
            { label: 'Volvo' },
            { label: 'Kia' },
            { label: 'Hyundai' },
            { label: 'Nissan' },
            { label: 'BMW' },
            { label: 'Mercedes' },]}
            userPack={props.userPack}/>
            );
            case "finishListing":
                    return(
                        <NewListing open={open} handleClose={handleClose} 
                        currency={{preferred: "DOP", rate: 52.704663,rates:{DOP: 52.704663, EUR: 0.907128}}} vehicles={[{ label: 'Ford' },
                    { label: 'Toyota' },
                    { label: 'Honda' },
                    { label: 'Maserati' },
                    { label: 'Range Rover' },
                    { label: 'Jeep' },
                    { label: 'Volvo' },
                    { label: 'Kia' },
                    { label: 'Hyundai' },
                    { label: 'Nissan' },
                    { label: 'BMW' },
                    { label: 'Mercedes' },]}
                    userPack={props.userPack}/>
                    );
            case "review":
                    return(
                        <Review open={props.open} handleClose={props.handleClose}/>
                    );
                    case "login":
                        return(
                            <LoginModal
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                                open={open}
                                onClose={handleClose}
                                register={false}
                                switch={handleSwitch}
                                login={login}
                                systemLogin={systemLogin}
                                userPack={props.userPack}
                                />
                        );
                        case "register":
                            return(
                                <LoginModal
                                aria-labelledby="simple-modal-title"
                                aria-describedby="simple-modal-description"
                                open={open}
                                onClose={handleClose}
                                register={true}
                                switch={handleSwitch}
                                login={login}
                                systemLogin={systemLogin}
                                userPack={props.userPack}
                            />
                            );
                            case "needPowers":
                                if(props.userPack.logged && !props.userPack.user.verified){
                                
                                    //props.userPack.dialog.handleOpen("newListing",props.userPack.myListing,props.userPack.listings)
                                    return(<GetVerified open={open} handleClose={handleClose} 
                                    currency={{preferred: "DOP", rate: 52.704663,rates:{DOP: 52.704663, EUR: 0.907128}}} vehicles={[{ label: 'Ford' },
                                { label: 'Toyota' },
                                { label: 'Honda' },
                                { label: 'Maserati' },
                                { label: 'Range Rover' },
                                { label: 'Jeep' },
                                { label: 'Volvo' },
                                { label: 'Kia' },
                                { label: 'Hyundai' },
                                { label: 'Nissan' },
                                { label: 'BMW' },
                                { label: 'Mercedes' },]}
                                userPack={props.userPack}/>);
                                } else if(!props.userPack.logged){
                                    props.userPack.dialog.handleOpen("login")
                                    return null
                                } else{
                                    return null
                                }
                            case "list":
                                if(props.userPack.listings.length===0 ||!props.userPack.logged){
                                    handleFirstList()
                                    
                                    
                                    return null
                                }
                                else if(props.userPack.fetchedUser){ 
                                    return(
                                        //<CloneSelection open={open} listings={props.userPack.listings} handleOpen={handleOpen} handleClose={handleClose}/>
                                        <CloneDialog userPack={props.userPack} open={open} handleClose={handleClose} handleOpen={handleOpen} />
                                    );
                                }
                                else{
                                    props.userPack.dialog.handleOpen("newListing",props.userPack.myListing,props.userPack.listings)
                                    return
                                    /*
                                    return(
                                        <NewListing open={open} handleClose={handleClose} 
                                        currency={{preferred: "DOP", rate: 52.704663,rates:{DOP: 52.704663, EUR: 0.907128}}} vehicles={[{ label: 'Ford' },
                                    { label: 'Toyota' },
                                    { label: 'Honda' },
                                    { label: 'Maserati' },
                                    { label: 'Range Rover' },
                                    { label: 'Jeep' },
                                    { label: 'Volvo' },
                                    { label: 'Kia' },
                                    { label: 'Hyundai' },
                                    { label: 'Nissan' },
                                    { label: 'BMW' },
                                    { label: 'Mercedes' },]}
                                    userPack={props.userPack}/>
                                    );*/
                                }
                                case "cloneSelection":
                                    return(<CloneSelection open={open} listings={props.userPack.user.listings} handleOpen={handleOpen} handleClose={handleClose}/>)
                                case "cloneListing":
                                        return(
                                            <NewListing open={open} handleClose={handleClose} 
                                        currency={{preferred: "DOP", rate: 52.704663,rates:{DOP: 52.704663, EUR: 0.907128}}} vehicles={[{ label: 'Ford' },
                                    { label: 'Toyota' },
                                    { label: 'Honda' },
                                    { label: 'Maserati' },
                                    { label: 'Range Rover' },
                                    { label: 'Jeep' },
                                    { label: 'Volvo' },
                                    { label: 'Kia' },
                                    { label: 'Hyundai' },
                                    { label: 'Nissan' },
                                    { label: 'BMW' },
                                    { label: 'Mercedes' },]}
                                    userPack={props.userPack}/>
                                        );
                                        case "getVerified":
                                            return(
                                                <GetVerified open={open} handleClose={handleClose} 
                                        currency={{preferred: "DOP", rate: 52.704663,rates:{DOP: 52.704663, EUR: 0.907128}}} vehicles={[{ label: 'Ford' },
                                    { label: 'Toyota' },
                                    { label: 'Honda' },
                                    { label: 'Maserati' },
                                    { label: 'Range Rover' },
                                    { label: 'Jeep' },
                                    { label: 'Volvo' },
                                    { label: 'Kia' },
                                    { label: 'Hyundai' },
                                    { label: 'Nissan' },
                                    { label: 'BMW' },
                                    { label: 'Mercedes' },]}
                                    userPack={props.userPack}/>
                                            );
                                            case "review":
                                                return(
                                                    <Review open={open} handleClose={handleClose} 
                                            currency={{preferred: "DOP", rate: 52.704663,rates:{DOP: 52.704663, EUR: 0.907128}}} vehicles={[{ label: 'Ford' },
                                        { label: 'Toyota' },
                                        { label: 'Honda' },
                                        { label: 'Maserati' },
                                        { label: 'Range Rover' },
                                        { label: 'Jeep' },
                                        { label: 'Volvo' },
                                        { label: 'Kia' },
                                        { label: 'Hyundai' },
                                        { label: 'Nissan' },
                                        { label: 'BMW' },
                                        { label: 'Mercedes' },]}/>
                                                );
                                                case "delete":
                                                    return(
                                                        <DeleteConfirmation open={open} handleClose={handleClose} handleDelete={handleDelete} id={data.value}/>                    
                                                    );
                                                    case "newAddress":
                                                        return(
                                                            <NewAddress userPack={props.userPack} open={open}/>                    
                                                        );
                                                    case "book":
                                                        return(
                                                            <Book open={open} handleClose={handleClose} handleDelete={handleDelete} userPack={props.userPack}/>                    
                                                        );
    }
    return(null);
}
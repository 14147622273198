import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
    topMargin:{
        marginTop:"25px"
    },
    unDoneListing:{
        backgroundColor: "hsla(0, 0%, 15%, 0.10)",
        '&:hover':{
            backgroundColor: "hsla(61, 88%, 91%, 0.57)"
        }
    }
}))

const data = {
    ingresos:305000,
    egresos:98500
}
const UnDoneListing = (props) =>{
    console.log(props.listing)
    const classes = useStyles()
    const title = (props.brand && props.model && props.year)?props.brand +" "+ props.model +" "+ props.year:"Undefined"

    return(
        <>
        <Grid item xs={4} md={2} sm={3} key={props.listing.id}>
            <Tooltip title="Click para continuar" aria-label="Editar" placement="bottom-start">
            <div>
            <a href="#" onClick={props.addListing} id={props.listing.id}>
                <img src="https://static.thenounproject.com/png/2999524-200.png" className={classes.unDoneListing}/>
            </a>
            </div>
            </Tooltip>
        </Grid>
        </>
    );
}

function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

function EstadosFinancieros(props){
    const openDialog = props.openDialog
    const classes = useStyles(props);
    //Do handling for deleting listing
    const handleAddListing = () => {
        openDialog("new")
    }
    const handleConsejos= () => {
        props.selectTab(4)
    }
    const margin = props.smallScreen?"10px":"0px"
    
    let monthlyIncome = 0//parseFloat(8750*props.rate).toFixed(2)
    console.log("payment almost hr", props.user)
    props.user.paymentsReceived.forEach(payment => {
        console.log("payment here")
        monthlyIncome+=payment.amount
    });
    let dailyIncome = monthlyIncome/30
    monthlyIncome = formatNumber(parseFloat(monthlyIncome*props.rate).toFixed(2))
    dailyIncome = formatNumber(parseFloat(dailyIncome*props.rate).toFixed(2))
    return(
      <>
      <div className="mainBody" style={{position:"relative",top:"0px"}}>
      <Grid container alignItems="center" justify="center" style={{marginBottom:"20px"}}>
            <Grid item xs={12} sm={12}>
                    <Grid container spacing={0} alignItems="flex-end" style={{marginBottom:"20px"}}>
                        <Grid item xs={12}  style={{marginLeft:margin,marginRight:margin}}>
                            <Grid container style={{marginTop:"25px"}} spacing={2}>
                                {/* Ingreso MENSUAL */}
                                <Grid item xs={6}>
                                    <Grid container className="incomeBox">
                                        <Grid item xs={4}>
                                            <p className="bigSymbol">
                                                {props.symbol}
                                            </p>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                <p className="bigNumber">
                                                    {monthlyIncome}
                                                </p>
                                                <p className="smallText" style={{position:"relative",bottom:"10px"}}>
                                                    Ingreso mensual promedio
                                                </p>
                                                </Grid>
                                                <Grid item xs={12}>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>    
                                <Grid item xs={6}>
                                     {/* Segunda Columna */}
                                    {/* Ingreso DIARIO */}

                                    <Grid container className="incomeBox" style={{marginTop:"0px"}}>
                                        <Grid item xs={4}>
                                            <p className="bigSymbol">
                                                {props.symbol}
                                            </p>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                <p className="bigNumber">
                                                {dailyIncome}
                                                </p>
                                                <p className="smallText" style={{position:"relative",bottom:"10px"}}>
                                                    Ingreso diario promedio
                                                </p>
                                                </Grid>
                                                <Grid item xs={12}>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>   
                                <Grid item xs={12}>
                                    {/* GRAPH */}
                                </Grid>                           
                            </Grid>
      
                            
                        </Grid>

                    </Grid>

            </Grid>
        </Grid>
        </div>
      </>
    );
}

export default EstadosFinancieros;
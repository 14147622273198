import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import {makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
    topMargin:{
        marginTop:"25px"
    },
    unDoneListing:{
        backgroundColor: "hsla(0, 0%, 15%, 0.10)",
        '&:hover':{
            backgroundColor: "hsla(61, 88%, 91%, 0.57)"
        }
    }
}))

const UnDoneListing = (props) =>{
    const classes = useStyles()
    const title = (props.listing.vehicle.brand && props.listing.vehicle.model && props.listing.vehicle.year)?props.listing.vehicle.brand +" "+ props.listing.vehicle.model +" "+ props.listing.vehicle.year:"Undefined"
    console.log("UnDone",title)
    const handleDialog= () => {
        props.openClone(props.listing)
    }
    
    return(
        <>
        <Grid item xs={4} md={2} sm={3} key={props.listing.id} style={{marginBottom:"15px"}}>
            <Tooltip title="Click para continuar" aria-label="Editar" placement="bottom-start">
            <div>
            <a href="#" onClick={handleDialog} id={props.listing.id}>
                <img src="https://static.thenounproject.com/png/2999524-200.png" style={{width:"135px",height:"110px"}} className={classes.unDoneListing}/>
            </a>
            <p>{title}</p>
            </div>
            </Tooltip>
        </Grid>
        </>
    );
}   

function Agenda(props){
    const openDialog = props.openDialog
    const classes = useStyles(props);
    //Do handling for deleting listing
    const handleAddListing = () => {
        openDialog("new")
    }
    const handleConsejos= () => {
        props.selectTab(4)
    }
    const margin = props.smallScreen?"10px":"0px"

    let dias = []
    for (let index = 0; index < 32; index++) {
        dias.push(index+1)
    }
    return(
      <>
       <div className="mainBody" style={{position:"relative",top:"0px"}}>
           <div className="flexStart alignCenter t4">
           <svg className="link" width="9" height="17" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 16L1 8.5L8 1" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


                <div className="title1 cambayBold l2 r2 t1" style={{cursor:"default",textAlign:"center"}}>
                    Abril
                </div>

                <svg className="link" width="9" height="17" viewBox="0 0 9 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 0.999999L8 8.5L0.999999 16" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

           </div>
            <div className="agendaBox t2">
                <div className="body1 cambayBold">Dom</div>
                <div className="body1 cambayBold">Lun</div>
                <div className="body1 cambayBold">Mar</div>
                <div className="body1 cambayBold">Mie</div>
                <div className="body1 cambayBold">Jue</div>
                <div className="body1 cambayBold">Vie</div>
                <div className="body1 cambayBold">Sab</div>
                <div className="baseBox selectedBox">1</div>
                <div className="baseBox greyBox">2</div>
                <div className="baseBox">3</div>
                {dias.map(dia=>(
                    <>  <div className="baseBox">{dia}</div>
                    </>
                ))}
            </div>
            <div className="flexStart">
                <div className="flexStart">
                <svg width="19" height="7" viewBox="0 0 19 7" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse cx="2.65263" cy="3.79781" rx="2.65263" ry="2.89253" fill="#5644F2"/>
<ellipse cx="15.9158" cy="3.79781" rx="2.65263" ry="2.89253" fill="#5644F2"/>
<rect x="4.64258" y="2.35156" width="9.28421" height="2.89253" fill="#5644F2"/>
</svg>
<div className="body1 l2">Reservado</div>
                </div>
                <div className="flexStart l3">
                <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="7.29474" height="8.6776" fill="#E3E3E3"/>
</svg>

<div className="body1 l2">No disponible</div>
                </div>
                <div className="flexStart l3">
                <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse cx="2.65263" cy="9.89253" rx="2.65263" ry="2.89253" fill="#181818"/>
<path d="M12.5576 12.8208C12.5576 12.367 12.4303 12.0073 12.1758 11.7417C11.9268 11.4705 11.5007 11.2215 10.8975 10.9946C10.2943 10.7677 9.77409 10.5436 9.33691 10.3223C8.89974 10.0954 8.52344 9.83805 8.20801 9.55029C7.89811 9.257 7.65462 8.9139 7.47754 8.521C7.30599 8.12809 7.22021 7.66048 7.22021 7.11816C7.22021 6.18294 7.51904 5.4165 8.1167 4.81885C8.71436 4.22119 9.50846 3.87256 10.499 3.77295V1.99658H11.8271V3.79785C12.8066 3.9362 13.5731 4.3457 14.1265 5.02637C14.6799 5.7015 14.9565 6.57861 14.9565 7.65771H12.5576C12.5576 6.99365 12.4193 6.49837 12.1426 6.17188C11.8714 5.83984 11.5062 5.67383 11.0469 5.67383C10.5931 5.67383 10.2417 5.80387 9.99268 6.06396C9.74365 6.31852 9.61914 6.67269 9.61914 7.12646C9.61914 7.54704 9.74089 7.8846 9.98438 8.13916C10.2279 8.39372 10.6789 8.65381 11.3374 8.91943C12.0015 9.18506 12.5465 9.43685 12.9727 9.6748C13.3988 9.90723 13.7585 10.1729 14.0518 10.4717C14.3451 10.765 14.5692 11.1025 14.7241 11.4844C14.8791 11.8607 14.9565 12.3006 14.9565 12.8042C14.9565 13.745 14.6632 14.5086 14.0767 15.0952C13.4901 15.6818 12.6821 16.0277 11.6528 16.1328V17.7847H10.333V16.1411C9.19857 16.0194 8.31868 15.6182 7.69336 14.9375C7.07357 14.2513 6.76367 13.341 6.76367 12.2065H9.1626C9.1626 12.8651 9.31755 13.3714 9.62744 13.7256C9.94287 14.0742 10.3939 14.2485 10.9805 14.2485C11.4674 14.2485 11.8521 14.1213 12.1343 13.8667C12.4165 13.6066 12.5576 13.258 12.5576 12.8208Z" fill="black"/>
</svg>

<div className="body1 l2">Precio modificado</div>
                </div>
            </div>

            <div className="flexStart t4">
                <div className="title2">Viajes</div>
            </div>

            <div className="flexStart t1">
                <div className="body1">No tienes viajes goy</div>
            </div>
            <div className="flexStart t4" style={{height:"20px"}}>
            </div>

        </div>
        <div className="alignCenter wide " style={{zIndex:"1000",bottom:props.userPack.dimensions.width<760?"90px":"20px",display:props.userPack.dimensions.width<760?"block":"none",marginBottom:"0px",position:"fixed"}}>
                        <div className="shadowBox alignCenter link">
                            <div style={{color:"#6853DA",textDecorationLine:"underline"}} className="body1 ">
                            Agregar reservación externa (no disponible)
                            </div>

                        </div>
            </div>
      </>
);
}

export default Agenda;
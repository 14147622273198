import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

const AddressLine = (props)=>{
  
    const handleSelect=()=>{
      props.handleLocation(props.address)
    }
    const handleMap=()=>{
      props.handleLocationVirtual(props.address)
      props.handleMap()
    }
    const selected = props.address.line1===props.booking.location.line1
    return(
      <>
        <Grid container>
        
          <Grid item xs={12} sm={6} md={12} style={{position:"relative",top:selected&&props.width>600&&props.width<960?"9px":"0px"}}>
            <Grid container style={{position:"relative",bottom:"5px"}}>
              <Grid item xs={12}>
                {props.address.line1}
              </Grid>
              <Grid item xs={12}>
                {props.address.city+", "+props.address.state+", "+props.address.country}
                </Grid>
            </Grid>
             </Grid>
          <Grid item xs={12} sm={6} md={12}>
            <Button variant="outlined" onClick={handleMap} color="secondary">Ver en el mapa</Button>
            {selected?
            null
              :
              <Button variant="outlined" onClick={handleSelect} style={{marginLeft:"5px "}} color="primary">Seleccionar</Button>
              }
            
          </Grid>
        </Grid>
        
      </>
    )
  }

export default function(props){
    return(
        <Grid item xs={12}>
        <FormControl style={{width:"100%"}}>
            <Select
              value={props.booking.location?props.booking.location.id:null}
              style={{width:"100%"}}
            >
              {props.listing.owner.deliveryAddressList.map((address,index)=>(
                <MenuItem value={address.id} key={index}>
                  <AddressLine handleMap={props.handleMap} handleLocationVirtual={props.handleLocationVirtual}
                  handleLocation={props.handleLocation} address={address} key={index} index={index} booking={props.booking}
                  width={props.width}/>
                </MenuItem>
              ))}
              
            </Select>
          </FormControl>
    </Grid>
    )
}
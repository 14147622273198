import { Link } from '@reach/router';
import React from 'react';
import MobileNavBarPlain from '../generalComponents/MobileNavBarPlain';
import CheckMark from '../SvgElements/CheckMark';
import TrackPackageIcon from '../SvgElements/TrackPackageIcon';
import moment from 'moment'

export default function(props){
    const user=props.userPack.user
    const logout=()=>{
        props.userPack.methods.logout()
        props.userPack.refs.routeHomeRef.current.click()
        return
    }
    return(
        <>
        <MobileNavBarPlain title="My account"/>

        <div className="alignCenter">
        <div className="mainBody">
        
            {/* <div className="row t2">
            <div className="body1 grey1 r1">Sort by</div><div className="body1">Date</div>
       </div> */}
       {props.userPack.logged?
              <>
              <div className="wide">
        <div className="half">
            <div className="wide">
            <div className="wide">
            
       <div className="t4 title1">{user.first_name?"Hola "+user.first_name:"Bienvenido"}!</div></div>
       <div className="wide"><div className="t2 caption2 grey1">Miembro desde el {moment(props.userPack.user.date_joined).format('YYYY')}</div></div>
       <div className="t2 bold caption2 green2 button">Editar mi cuenta</div>
            </div>
            <div className="alignCenter">
            <div className="">
                <div className="row alignCenter">
                {props.userPack.user.profilePicture?
                                <div className="" style={{borderRadius:"50%",width:"60px",height:"60px",backgroundImage:"url("+props.userPack.user.profilePicture.url+")",backgroundSize:"cover"}}>
                                </div>:
                                <div className="profilePicture" >
                                </div>
                }
                </div>
                <div className="row">
                <div className="t2 bold caption2 green2 button">Cambiar foto</div>
                </div>
                </div>
                </div>
        </div>
       {(user.confirmedPhone||user.confirmedEmail)?
       <>
       <div className="wide t4 alignCenter">
           <div className="row alignCenter">
               {user.confirmedPhone?
                              <div className="">
                              <div className="container alignCenter">
                              <CheckMark/>
                          <div className="caption1 l2">Número de teléfono</div>
                          </div></div>
               :null}
               {user.confirmedEmail?
               <div className="">
               <div className="container alignCenter l4">
               <CheckMark className=""/>
               <div className="caption1 l2">Correo electrónico</div>
               </div>
              </div>
               :null}
                </div>
               </div>
       </>
       :null}
       
       
       <div className="wide alignCenter t1">
       </div> 
       <div className="wide t4">
       <div className="title1"><Link to="../paymentOptions">Métodos de pago</Link></div>
       </div>
       <div className="wide t4">
       <div className="title1"><Link to="../orders">Historial de rentas</Link></div>
       </div>
       <div className="wide t4">
       <div className="title1">0 Críticas</div>
       </div>
       <div className="wide t4">
       <div className="title2 blue1 link" onClick={props.userPack.methods.logout}>Logout</div>
       </div>
       <div className="wide t5 " >
       
       </div>
       </div>

              </>
              :
       <>
       <div className="wide">
           <div className="title1">Please <span className="blue1"><Link to='../login'>login to your account</Link></span> or <span className="blue1"><Link to='../register' >sign up today!</Link></span></div>
       </div>
       </>
       }
               </div>
        </div>
        </>
    )
}




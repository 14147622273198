import '@businesscomponents29/prietoelements/src/media/css/neoTypography.css';
import '@businesscomponents29/prietoelements/src/media/css/neoElements.css';
import '@businesscomponents29/prietoelements/src/media/css/effects.css';
import '@businesscomponents29/prietoelements/src/media/css/effects.scss';
import './index.scss';
import './moarCss.scss';
import './borrowed.scss';
import 'react-dates/lib/css/_datepicker.css';
import UserId from './Components/UserId';
// import '@businesscomponents29/prietoelements/src/media/css/bootstrap-grid.css';
import React, { Component } from 'react';
import './App.css';
import { Router, Link } from "@reach/router";
import MainView from "./MainView"
import Listings from "./Listings"
import SingleListing from "./SingleListing"
import LoginMobile from "./LoginMobile"
import LoginModal from "./elements/LoginModal"
import moment from 'moment';
import Profile from './Profile';
import Chat from './elements/Chat';
// import ModalHandler from '@businesscomponents29/prietoelements/src/Components/ModalHandler';
import User from './User';
import Perfil from './Perfil';
import Cuenta from './Cuenta';
import Saved from './Saved';
import Chaty from './Chat';
import PublicTerms from './PublicTerms';
import PublicPrivacy from './PublicPrivacy';
import BottomNavBar from './BottomNavBar';
import {loadStripe} from '@stripe/stripe-js';
import {ElementsConsumer} from '@stripe/react-stripe-js';
import {
  Elements
} from "@stripe/react-stripe-js";
import UserDashBoard from './DashBoard2';
import MainDialogs from './maincomponents/MainDialogs';
import MakeMoney from './MakeMoney';
import Checkout from './CommerceOG/Checkout';

const axios = require('axios');

const stripePromise = loadStripe(
    "pk_test_51HWiqaEs4YXxlTlMm9eqb2HE2yQ42HiojQXfFfgv6KmztJJr7hIg4upzHVxKp9bZrVrGH4K8HlyszlYiXiTPBDdr00mfEFmgVI"
  );

const getUrl = window.location;
let neoUrl
let baseUrl = "https://ur8z3iogad.execute-api.us-east-2.amazonaws.com/dev/";
let baseWss = "18.216.39.52";
if(getUrl.host.includes("249:3000")){
  neoUrl=getUrl.host.substring(0,getUrl.host.length-5)
  baseUrl = getUrl.protocol+ "//" + neoUrl +":8000/";
}else{
  neoUrl = getUrl.host
}

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = false;

let host
const w = window;
const d = document;
const documentElement = d.documentElement;
const body = d.getElementsByTagName('body')[0];

// //      const width = props.userPack.dimensions.width
// // const height = w.innerHeight || documentElement.clientHeight || body.clientHeight;
// const getUrl = window.location;

// if(getUrl.host.includes(":")){
//   host = getUrl.host.substring(0, getUrl.host.length - 5);
// }else{host = getUrl.host;}
// let baseUrl = getUrl.protocol+ "//" + host +":8000/";

var W3CWebSocket = require('websocket').w3cwebsocket;

  
  const currencies = [
    {
      value: 'USD',
      label: 'US$',
    },
    {
      value: 'DOP',
      label: 'RD$',
    },
    {
      value: 'EUR',
      label: '€',
    },
  
  ];
  const emptyBooking={
    "id": null,
    "closed": false,
    "startDate": "2019-12-10T00:15:17.759822Z",
    "days": null,
    "endDate": "2019-12-15T00:15:17.759822Z",
    "comment": "",
    "fidelityRating": null,
    "cleanRating": null,
    "drivingExperienceRating": null,
    "valueForMoneyRating": null,
    "overallRating": null,
    "closed": false,
    "listing": null,
    "customer": null,
    "deliveryAddress": null
  }
  const emptyListing={
    "id": null,
    "bookingHistory": [],
    "listingPictures": [
  ],
  "bankAccounts":[],
  "mainPicture": null,
    "features": [
    ],
    "vehicle": {
      "id": 666666,
      "brand": "Marca",
      "model": "Modelo",
      "year": 2019,
      "doors": 4,
      "seats": 4,
  },
    "owner": {
        "id": null
    },
    "active": true,
    "insured": true,
    "forSale": false,
    "milage": null,
    "matricula": "A",
    "fidelityRating": null,
    "cleanRating": null,
    "drivingExperienceRating": null,
    "valueForMoneyRating": null,
    "overallRating": null,
    "dailyPrice": "1800.00",
    "weeklyPrice": "10000.00",
    "performance": "30.00",
    "unit": "Km/gal",
    "additionalDetails": "",
    "category": {
      "id": 1,
      "name": "Carro"
  },
}
  const currencySymbols = [
    {
      value: 'USD',
      label: 'US$',
    },
    {
      value: 'DOP',
      label: 'RD$',
    },
    {
      value: 'EUR',
      label: '€',
    },
  
  ];
  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  function validateEmail(email) {
    var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  }
  
  class App extends Component {
     stateHandling=(tag,latter)=>{
       this.setState({[tag]:latter})
       console.log(tag,latter)
     }
     neoSelected=(element)=>{
       this.setState({selected:element})
     }
     processKey=(e)=>{
       if(e.code==="Escape" && this.state.modal.open){
          let newVal = {...this.state.modal}
          newVal.open=false
          this.setState({modal:newVal})
       }
       if((e.code==="Enter" || e.code==="NumpadEnter")&& ((this.state.modal.open && this.state.modal.type==="userId") || (window.location.href.includes("login")|| window.location.href.includes("register")|| window.location.href.includes("userId")))){
         if(window.location.href.includes("login")||window.location.href.includes("userId")){
          this.login(this.state.loginModal.username,this.state.loginModal.password)
         }else(
          this.handleRegister()
         )
        
     }
     }
     handleScroll=()=>{
      let newDimensions={...this.state.dimensions}
        newDimensions.scrollValue=window.scrollY
        const val = window.scrollY
        const that = this
        this.setState({dimensions:newDimensions})
        try {
          // let navBar=document.querySelector('#root > div.mobileNav')
    let mobileSearchBar=document.querySelector('#root > div:nth-child(1) > div.mobileSearchBar')
    let bottomNavBar=document.querySelector('#root > div.bottomNavbar2')
    let indexNavBar=document.querySelector('#root > div:nth-child(1) > div.indexNavBar')
    let indexNavBarMob=document.querySelector('#root > div:nth-child(1) > div.indexNavBar')
    
          // if(window.location.href.includes('loginMobile')){
          //   navBar.style.display='none'
          // }else{
          //   navBar.style.display='flex'
          // }
    if(window.location.pathname.includes(['isting','dash','prof','account','user'])){
      return
    }
          if(val<that.state.dimensions.height*0.85&&that.state.dimensions.width<759){
            that.setState({navColor:"white"})
            mobileSearchBar.style.display="none !important"
            mobileSearchBar.style.position="absolute"
            mobileSearchBar.style.top="-81px"
            bottomNavBar.style.display="none !important"
            bottomNavBar.style.position="fixed"
            bottomNavBar.style.bottom="-81px"
            indexNavBar.classList.remove("blackNavBar") 
            indexNavBar.classList.add("transparentNavBar")
            indexNavBarMob.classList.remove("blackNavBar") 
            indexNavBarMob.classList.add("transparentNavBar")
            
            
            
            return
          }
          if(val>that.state.dimensions.height*0.85&&that.state.dimensions.width<759){
            mobileSearchBar.style.display="flex"
            bottomNavBar.style.display="grid"

            mobileSearchBar.style.position="fixed"
            mobileSearchBar.style.top="0px"
            return
          }
          if(val>that.state.dimensions.height*0.53){
            that.setState({navColor:"black"})
            indexNavBar.classList.remove("transparentNavBar")
            indexNavBar.classList.add("blackNavBar")
    
          } else{
            that.setState({navColor:"white"})
            indexNavBar.classList.remove("blackNavBar") 
            indexNavBar.classList.add("transparentNavBar")
            
          }
          // if(val>30){
          //   const alertEl=document.querySelector('#root > div > div.mobileNav > div.alertBg')
          //   alertEl.style.top=-70+'px'
          //   const mobileSearchBar=document.querySelector('#root > div.mobileNav > div.mobileSearchBar')
          //   mobileSearchBar.style.top=0+'px'
          // }
        } catch (error) {
        }

        
      }
  
    
    updateDimensions=()=>{
      const w = window;
      const d = document;
      const documentElement = d.documentElement;
      const body = d.getElementsByTagName('body')[0];
      const width = w.innerWidth || documentElement.clientWidth || body.clientWidth;
      const height = w.innerHeight || documentElement.clientHeight || body.clientHeight;
      if (width < 769){
        const newDimensions={width:documentElement.clientWidth, height:height, isMobile:true}
        this.setState({dimensions:newDimensions})
        return
      }
      const newDimensions={width:documentElement.clientWidth, height:height-100, isMobile:false}
      this.setState({dimensions:newDimensions})
    }
    updateVals=(prevProps)=>{
      
      return
    }
    constructor(props){
      super(props)
      this.routeUserIdRef=React.createRef();
      this.routeProfileRef= React.createRef();
      this.routeChatRef= React.createRef();
      this.routeHomeRef= React.createRef();
      this.routeListingsRef= React.createRef();
      this.routeSavedRef= React.createRef();
      this.routeMakeMoneyRef= React.createRef();
      this.routeMyProfileRef= React.createRef();
      this.routeAccountRef= React.createRef();
      this.position()
        this.alertRef = React.createRef();
        window.addEventListener("resize", this.updateDimensions);
        let startDateRaw = localStorage.getItem('startDate')
        let endDateRaw = localStorage.getItem('endDate')
        let location = localStorage.getItem('location')
        let startDate
        let endDate
        if(startDateRaw===null || moment(startDateRaw).isAfter(moment())){startDate=moment()}
        else{startDate=moment(startDateRaw)}
        if(endDateRaw===null){endDate=moment().add(7, 'd')}
        else{endDate=moment(endDateRaw)}
        let newBooking = emptyBooking
        newBooking.startDate=startDate
        newBooking.endDate=endDate
        let carCategory = window.localStorage.getItem('carCategory')
        carCategory?carCategory=parseInt(carCategory):carCategory=null
        const token = localStorage.getItem('token')
        const userId = localStorage.getItem('userId')
        let notificationClient = null
        if(userId){
          notificationClient = this.openNotifications(userId)
        }
        
        this.state={shippingInfo:{useAccountName:true,useSameShipping:true,keepUpdated:true},
          modal:{open:false,type:""},
          user:{isBusiness:true,listings:[],paymentsReceived:[],deliveryAddressList:[]},fetchedUser:false,
          loginModal:{active:false,firstName:'',social:false,lastName:'',phoneNumber:'',login:null,
    username:'',password:'',innerLoading:false,innerError:null,type:""},
    axiosPack:{axios,baseUrl,baseWss},
    selected:null,currency:{symbols:currencySymbols,navColor:"white",
    rates:{"USD":1,"AED":3.672058,"ARS":60.073152,"AUD":1.462619,"BGN":1.772324,"BRL":4.175311,"BSD":1,"CAD":1.313949,"CHF":0.970542,"CLP":775.032232,"CNY":6.937035,"COP":3356.26087,"CZK":22.774105,"DKK":6.769282,"DOP":53.200551,"EGP":15.731404,"EUR":0.906153,"FJD":2.173377,"GBP":0.763843,"GTQ":7.686349,"HKD":7.772394,"HRK":6.743727,"HUF":304.680441,"IDR":13481.299912,"ILS":3.454776,"INR":71.322065,"ISK":124.593825,"JPY":109.43859,"KRW":1168.893381,"KZT":378.401961,"MXN":18.789109,"MYR":4.06712,"NOK":9.020184,"NZD":1.513791,"PAB":1,"PEN":3.322153,"PHP":50.850544,"PKR":154.697395,"PLN":3.850458,"PYG":6432.833333,"RON":4.330082,"RUB":61.891661,"SAR":3.750937,"SEK":9.552551,"SGD":1.351475,"THB":30.561937,"TRY":5.940419,"TWD":30.040088,"UAH":24.306582,"UYU":37.255792,"ZAR":14.392269},selected:"DOP"},
    dimensions:{isMobile:null,width:null,height:null,scrollValue:0},
          filteredResults:null,
          supportChats:[],username:'',password:'',data:{},socialLogin:false,
          redirectUri:window.location.host,
          //searchFiltters:{priceMin:0,priceMax:1000,},
          notificationClient:notificationClient,
          chatDialog:{active:false,chatId:null},
          scrollValue:0,
          latitude:18.4637770,longitude:-69.9336969,
          filters:{priceRange:[30,200],priceMaximum:[0,300],
            sortBy:null,deliveryFee:30,map:true},
          popOver:{open:false,anchorElement:null,content:null},
          elements:{states:[]},
            fetchedUser:false,
            fetched:false,
            rawResults:[],listingHeredado:null,
            currency:{selected: "DOP", symbols:currencies,rates:{"USD":1,"AED":3.672058,"ARS":60.073152,"AUD":1.462619,"BGN":1.772324,"BRL":4.175311,"BSD":1,"CAD":1.313949,"CHF":0.970542,"CLP":775.032232,"CNY":6.937035,"COP":3356.26087,"CZK":22.774105,"DKK":6.769282,"DOP":53.200551,"EGP":15.731404,"EUR":0.906153,"FJD":2.173377,"GBP":0.763843,"GTQ":7.686349,"HKD":7.772394,"HRK":6.743727,"HUF":304.680441,"IDR":13481.299912,"ILS":3.454776,"INR":71.322065,"ISK":124.593825,"JPY":109.43859,"KRW":1168.893381,"KZT":378.401961,"MXN":18.789109,"MYR":4.06712,"NOK":9.020184,"NZD":1.513791,"PAB":1,"PEN":3.322153,"PHP":50.850544,"PKR":154.697395,"PLN":3.850458,"PYG":6432.833333,"RON":4.330082,"RUB":61.891661,"SAR":3.750937,"SEK":9.552551,"SGD":1.351475,"THB":30.561937,"TRY":5.940419,"TWD":30.040088,"UAH":24.306582,"UYU":37.255792,"ZAR":14.392269}},
            vehicles:[{ label: 'Ford' },
                        { label: 'Toyota' },
                        { label: 'Honda' },
                        { label: 'Maserati' },
                        { label: 'Range Rover' },
                        { label: 'Jeep' },
                        { label: 'Volvo' },
                        { label: 'Kia' },
                        { label: 'Hyundai' },
                        { label: 'Nissan' },
                        { label: 'BMW' },
                        { label: 'Mercedes' },
                    ],
            logged:false,
            dialogData:{action:"",value:null},
            dialogFetching:false,
            open:false,
            button:false,
            location:location,
            carCategory:carCategory,
            steps:{selectedStep:false,options:{
              years:["2019","2018","2017","2016","2015","2014","2013","2012","2011","2010","2009","2008"]
              ,models:[],carTypes:[],units:[],currencies:[],features:[
                {
                    "id": 1,
                    "name": "Eléctrico/Híbrido"
                },
                {
                    "id": 2,
                    "name": "Rack para bicicletas"
                },
                {
                    "id": 3,
                    "name": "Asiento para niños"
                },
                {
                    "id": 4,
                    "name": "Tracción 4x4"
                },
                {
                    "id": 5,
                    "name": "GPS"
                },
                {
                    "id": 7,
                    "name": "Bluetooth"
                },
                {
                    "id": 9,
                    "name": "Entrada USB"
                },
                {
                    "id": 10,
                    "name": "Calefacción en los asientos"
                },
                {
                    "id": 11,
                    "name": "Sunroof"
                },
                {
                    "id": 12,
                    "name": "Convertible"
                },
                {
                    "id": 13,
                    "name": "Pase para peajes"
                },
                {
                    "id": 14,
                    "name": "Amigable para perros"
                },
                {
                    "id": 15,
                    "name": "Entrada de Audio"
                }
            ]},
              selectStep:this.selectStep
            },
            payment:null,
            myListing:{...emptyListing},
            myBooking:newBooking
        }
        this.fetchElements()
        if(token!==null&&this.state.fetchedUser===false){
          this.getUser()
        }
        
    }
    componentDidUpdate(prevProps){
      this.updateVals(prevProps);
    }
    position = async () => {
      const that = this
      await navigator.geolocation.getCurrentPosition(
        position => {
          // console.log(position,"position")
          that.setState({ 
          latitude: position.coords.latitude, 
          longitude: position.coords.longitude
        })}, 
        err => {
          that.setState({ 
            latitude: 18.4637770,
            longitude: -69.9336969
          })
          console.log("error ubicando cliente",err)}
      );
      
    }
    handleRegister = (data=false)=>{
      console.log("called")
      let neoModal = {...this.state.loginModal}
      neoModal.innerLoading=true
      this.setState({loginModal:neoModal})
      const that = this
      async function register (){
        let sendingData
        if(data){
          sendingData=data
        }else{
          sendingData=data={}
        }
        let myUsername = that.state.loginModal.username
        let myPassword = that.state.loginModal.password
        sendingData.username=myUsername
        sendingData.password=myPassword
  
        sendingData.phoneNumber=that.state.loginModal.phoneNumber
        let social = that.state.loginModal.social
  
        const isEmail = await validateEmail(myUsername)
        if(!isEmail){
          neoModal.innerLoading=false
          that.setState({loginModal:neoModal})
          return
        }
        if(myPassword.length<8){
          neoModal.innerLoading=false
          that.setState({loginModal:neoModal})
          return
        }
        if(social){
          axios.post(baseUrl+"custom-auth/",sendingData)
          .then(res=>{
            console.log(res.data, "logging you in 1 ...")
            that.systemLogin(res.data.user,res.data.token)
            neoModal.innerLoading=false
            neoModal.social=false
            neoModal.active=false
            that.setState({loginModal:neoModal})
            try{that.routeProfileRef.current.click()}catch (err){console.log(err)}}
            ).catch(err=>{
              neoModal.innerLoading=false
              neoModal.innerError="error post info fetching"
              neoModal.social=false
              that.setState({loginModal:neoModal})
              // console.log("hay bosbo")
              return
                })
        }else{
          sendingData.first_name=capitalize(that.state.loginModal.firstName)
          sendingData.last_name=capitalize(that.state.loginModal.lastName)
          sendingData.name=sendingData.first_name+" "+sendingData.last_name
          await axios.post(baseUrl+"api/existing/",sendingData)
          .then(res=>{
            if(res.data.message==="existe"){
              that.login(sendingData.username,sendingData.password)
              return
                    }
                    else{
                      that.login(sendingData.username,sendingData.password)
                      return
                    }
            },err=>{
              
            neoModal.innerLoading=false
            neoModal.innerError="Este correo ya existe, intente iniciar sesión"
            that.setState({loginModal:neoModal})
          })
          
        }
  
  
        //window.location.href = ("https://carly.do")
      }
      register()
    }
  
    login = (username,password) =>{
      
      let neoModal = {...this.state.loginModal}
      neoModal.innerLoading=true
      neoModal.innerError=null
      this.setState({loginModal:neoModal})
      const that = this
      axios.post(baseUrl+"token-auth/",{username,password})
      
      .then(res=>{
        // console.log("success")
          that.systemLogin(res.data.user,res.data.token)
          let neoModal = {...that.state.loginModal}
          neoModal.innerLoading=false
          neoModal.active=false
          that.setState({loginModal:neoModal})
          try{that.routeProfileRef.current.click()}catch (err){console.log(err)}
          return
      },
      error=>{
        
        // console.log("hay bobooo")
        let neoModal = {...this.state.loginModal}
        neoModal.innerLoading=false
        neoModal.innerError="Usuario y/o contraseña incorrecta"
        this.setState({loginModal:neoModal})
          return false
  })}
    systemLogin=(user,token)=>{
      window.localStorage.setItem('token',token);
      window.localStorage.setItem('userId',user.id);
      let notifications = [].concat(user.activeLeasing).concat(user.activeRenting)
      //console.log("estas iban a ser las notfs", notifications)
      const notificationClient = this.openNotifications(user.id)
      let neoListings=[]
      const rate = this.state.currency.rates[this.state.currency.selected]
      user.listings.forEach(listing => {
        listing.dailyPrice = parseFloat(listing.dailyPrice*rate).toFixed(2)
        listing.weeklyPrice = parseFloat(listing.weeklyPrice*rate).toFixed(2)
        neoListings.push(listing)
      }); 
      user.listings = neoListings
      this.setState({notificationClient,notifications,user:user,fetchedUser:true,logged:true});
  }

  deleteListing=(id)=>{
    this.virtualDeleteListing(id)
  
    this.state.myListing.listingPictures.forEach( picture => {
      axios.delete(picture.url)
    });
    const token = window.localStorage.getItem('token')
    axios.defaults.headers.delete['Authorization']="JWT "+token
    axios.delete(baseUrl + `api/listings/`+id).then(resp=>{
      //this.reFetchUser()
    })
    
  }
  
 getUser=(latitude,longitude)=>{
  const token = window.localStorage.getItem('token')
  // console.log("hola chicos, no hagan drogas",firstName,zipzip,lastName,address1,state,city)
  let userRequest=async function(){return null}
  let neoAxios ={...axios}
  if(token){
    axios.defaults.headers.get['Authorization']="JWT "+token
    userRequest = axios.get(baseUrl + `current_user/`)
    let neoModal = {...this.state.loginModal}
    neoModal.innerLoading=true
    neoModal.innerError=null
    this.setState({loginModal:neoModal})
  }
  axios.defaults.headers.get['Content-Type']='application/x-www-form-urlencoded'
  // const featuresRequest = axios.get(baseUrl +"getFeatures/")
  // const statesRequest = axios.get(baseUrl +"getStates/")
  // const countriesRequest = axios.get(baseUrl +"getCountries/")
  // const results = axios.post(baseUrl+"getResults/",{"locationCode":null})
  
  // const featuresRequest = axios.get(baseUrl +"getFeatures/")
  // neoAxios.defaults.proxy = {host:"https://cors-anywhere.herokuapp.com"}
  neoAxios.defaults.proxy = null
  delete neoAxios.defaults.headers.get['Authorization'];
  const currenciesReq = neoAxios.get("https://api.exchangerate-api.com/v4/latest/USD")
  Promise.all([userRequest,currenciesReq,]).then(res => {
    const data = res[0].data;
    // const rawResults=res[2].data;
    const currenciesData=res[1].data;
    let newSteps = {...this.state.steps}
    const rate = this.state.currency.rates[this.state.currency.selected]
    if(data){
      let newListings = []
      data.listings.forEach(listing => {
        
        listing.dailyPrice = parseFloat(listing.dailyPrice*rate).toFixed(2)
        listing.weeklyPrice = parseFloat(listing.weeklyPrice*rate).toFixed(2)
        newListings.push(listing)
      }); 
      data.listings = newListings
    }

    // if(data.is_superuser){this.fetchSupportChats()}
    let switcher;
    let neoCurrency = {...this.state.currency}
    neoCurrency.rates=currenciesData.rates
    data?switcher=true:switcher=false
    let stripeCustomer=null
    let resss = null
    this.setState({user:data,
      fetchedUser:switcher,shippingAddress:true,
      logged:switcher,currency:neoCurrency,
      dialogFetching:false,steps:newSteps})
  }).catch(error=>{console.log("error getting user",error)})
 }
 fetchElements=()=>{
  delete axios.defaults.headers.get['Authorization'];
  axios.defaults.headers.get['Content-Type']='application/x-www-form-urlencoded'
  const featuresRequest = axios.get(baseUrl +"getFeatures/")
  const statesRequest = axios.get(baseUrl +"getStates/")
  const countriesRequest = axios.get(baseUrl +"getCountries/")
  const myBooking =this.state.myBooking
  let endDate
  let startDate
  if(myBooking.endDate===null || myBooking.length<9){
    endDate=moment().add(7,'d')
  }else{
    endDate=moment(myBooking.endDate)
  }
  if(myBooking.startDate===null || myBooking.length<9){
    startDate=moment()
  }else{
    startDate=moment(myBooking.startDate)
  }
  let counter=0
  const results = axios.post(baseUrl+"getResults/",{"startDate":startDate,"endDate":endDate,"location":""})
  Promise.all([statesRequest,countriesRequest,results,featuresRequest]).then(res => {
    const features = res[3].data;
    const states = res[0].data;
    const countries = res[1].data;
    const results = res[2].data;
    let prices = []
    results.forEach(item => {
      prices.push(item.dailyPrice)
    });
    const max = Math.max.apply(Math, prices);
    const min = Math.min.apply(Math, prices);
    //const currency = res[3].data;
    let newFilters = {...this.state.filters}
    newFilters.priceMaximum = [min,max+80]
    //console.log("fetched data", states, countries,"resuts",results)
    this.setState({filters:newFilters,elements:{features:features,states:states,countries:countries}})
    this.setState({rawResults:results})
    counter=counter+1
    if(counter>1){
      counter=-1
      this.setState({fetched:true})
    }
  })
  axios.defaults.proxy = {host:"https://cors-anywhere.herokuapp.com"}
  axios.get("https://api.exchangerate-api.com/v4/latest/USD").then(
    resp=>{
      let currency={selected:"DOP",rates:resp.data.rates,symbols:currencies}
      //console.log("respuesta covnersion monedas",currency)
      this.setState({currency:currency})
      counter=counter+1
      if(counter>1){
        counter=-1
        this.setState({fetched:true})
      }
      axios.defaults.proxy = null
    }
  ).catch(err=>{
    // console.log("error con api monedas",err)
    // console.log("using mock up data")
    let currency={selected:"DOP",symbols:currencies,rates:{"USD":1,"AED":3.672058,"ARS":60.073152,"AUD":1.462619,"BGN":1.772324,"BRL":4.175311,"BSD":1,"CAD":1.313949,"CHF":0.970542,"CLP":775.032232,"CNY":6.937035,"COP":3356.26087,"CZK":22.774105,"DKK":6.769282,"DOP":53.200551,"EGP":15.731404,"EUR":0.906153,"FJD":2.173377,"GBP":0.763843,"GTQ":7.686349,"HKD":7.772394,"HRK":6.743727,"HUF":304.680441,"IDR":13481.299912,"ILS":3.454776,"INR":71.322065,"ISK":124.593825,"JPY":109.43859,"KRW":1168.893381,"KZT":378.401961,"MXN":18.789109,"MYR":4.06712,"NOK":9.020184,"NZD":1.513791,"PAB":1,"PEN":3.322153,"PHP":50.850544,"PKR":154.697395,"PLN":3.850458,"PYG":6432.833333,"RON":4.330082,"RUB":61.891661,"SAR":3.750937,"SEK":9.552551,"SGD":1.351475,"THB":30.561937,"TRY":5.940419,"TWD":30.040088,"UAH":24.306582,"UYU":37.255792,"ZAR":14.392269}}
    this.setState({currency:currency})
    counter=counter+1
    if(counter>1){
      counter=-1
      this.setState({fetched:true})
    }
    axios.defaults.proxy = null
  })
}
handlePayment=(method,data)=>{
  this.setState({payment:{method:method,data:data}})
}
fetchModels=(brand)=>{
  const token = window.localStorage.getItem('token')
  axios.defaults.headers.post['Authorization']="JWT "+token
  axios.post(baseUrl + `getModels/`,{"brand":brand}).then(resp=>{
    const newModels = resp.data
    let newSteps = {...this.state.steps}
    newSteps.options.models = newModels
    this.setState({steps:newSteps})
  })
}
handleSwitch = (action)=>{
    action==="login"?
    this.setState({dialogData:{action:"register",data:{}}})
    :
    this.setState({dialogData:{action:"login",data:{}}})
    this.setState({open:true})
}
setUlteriorMotive=(value)=>{
  window.localStorage.setItem('ulteriorMotive',value)
  this.setState({ulteriorMotive:value})
}
removeUlteriorMotive=()=>{
  window.localStorage.removeItem('ulteriorMotive')
  this.setState({ulteriorMotive:null})
}
handleClose = (ulterior=null)=>{
  if(ulterior==="noUlterior"){
    this.removeUlteriorMotive()
    this.setState({open:false,dialogData:{action:null,value:null}})
    return
  }
  if (this.state.ulteriorMotive){
    switch(this.state.ulteriorMotive){
      case "list":
        if(this.state.logged && this.state.user.verified){
          this.removeUlteriorMotive()
          this.handleOpen("newListing",{...this.state.myListing},this.state.user.listings)
          return
        }
        this.handleOpen("needPowers")
        return
      case "book":
          if(this.state.logged && this.state.user.verified){
            this.removeUlteriorMotive()
            this.handleOpen("book",this.state.listingForRent)
            return
          }
          this.handleOpen("needPowers")
          return
    }
  }
  this.setState({open:false,dialogData:{action:null,value:null}})
}
setListingForRent = (listing)=>{
  this.setState({listingForRent:listing})
}
createListing = (type,action,data=null,listings)=>{
  const ownerId = parseInt(window.localStorage.getItem('userId'))
  let myListings = listings
  
  let newListing={...emptyListing}
  data.owner.id=ownerId
  newListing.owner.id=ownerId
  if(type==="new"){
  }
  else if(type==="clone"){
    
    newListing = data
    newListing.id=null
    newListing.listingPictures=[]
    newListing.mainPicture=null
  }else{
    newListing = data
    this.setState({dialogData:{action:action,value:data}})
    return
  }
  
  //this.setState({open:true,myListing:emptyListing,dialogData:{action:action,value:data}})
  this.setState({open:true,dialogFetching:true,dialogData:{action:action,value:newListing}},()=>{

  })
  
  const myUser = {...this.state.user}
  myListings.filter(c=>c.id!==1)
  // console.log("CREATE MY LISTINGS",myListings,myUser)
  const rate = this.state.currency.rates[this.state.currency.selected]
  newListing.dailyPrice=parseFloat(newListing.dailyPrice/rate).toFixed(2);
  newListing.weeklyPrice=parseFloat(newListing.weeklyPrice/rate).toFixed(2)
  axios.post(baseUrl +"api/listings/",newListing).then(resp=>{
    //this.listingAPI();
    let myNewListings =myListings

    // console.log("premanipuladaaa",resp.data)
    let neoListing={...resp.data}
    neoListing.dailyPrice = parseFloat(neoListing.dailyPrice*rate).toFixed(2);
    neoListing.weeklyPrice=parseFloat(neoListing.weeklyPrice*rate).toFixed(2);
    myNewListings.push(neoListing)
    myUser.listings=myNewListings
    this.setState({ulteriorMotive:null,dialogFetching:false,myListing:neoListing,user:myUser,open:true,dialogData:{action:action,value:newListing}})
    
  })
  
}
setFilteredResults =(filteredResults)=>{
  this.setState({filteredResults})
}


handleReFetchUser = () =>{
  this.getUser()
  return
}

switchListing=(listing=null,oldC)=>{
  if(listing===null){
    let neoList = {...emptyListing}
    this.setState({myListing:neoList})
  }
  // this.setState({myListing:listing},()=>{
  //   let newCurrency = {...this.state.currency}
  //   const oldVal=newCurrency.selected
  //   newCurrency.selected=value
  //   this.updateMyListing(["currency"],{oldVal,value})
  // })
}
handleEmptyAddress=()=>{
  let newUser = this.state.user
  if(newUser.homeAddress){
    return
  }else{
    newUser.homeAddress={id:666666,landmark:"",line1:"",line2:"",city:"",state:"",zipcode:"",country:""}
  }
  this.setState({user:newUser})
}
updateMyBooking=(newBookingg)=>{
  // console.log("updated my BOOKING ???????")

  this.setState({myBooking:newBookingg})
}
updateMyUser=(attribute,newValue)=>{
  let newUser = this.state.user
  if(attribute[0]==="first_name"){
    newUser.first_name = newValue
    this.setState({user:newUser})
}
  else if(attribute[0]==="last_name"){
      newUser.last_name = newValue
      this.setState({user:newUser})
    }
    else if(attribute==="bankAccounts"){
      // console.log("Bank accounts",newValue)
      newUser.bankAccounts = newValue
      this.setState({user:newUser})
      this.userAPI(newUser)
    }
    else if(attribute[0]==="countryCode"){
      newUser.countryCode = newValue
      this.setState({user:newUser})
    }
    else if(attribute[0]==="phoneNumber"){
      newValue = newValue.replace(/\D/g,'');
      newUser.phoneNumber=newValue
      this.setState({user:newUser})
    }
    else if(attribute[0]==="business"){
      newUser.business = !newUser.business
      this.setState({user:newUser})
    }
    else if(attribute[0]==="homeLandMark"){
      this.handleEmptyAddress()
      let newHomeAddress = newUser.homeAddress
      newHomeAddress.landmark = newValue
      newUser.homeAddress = newHomeAddress
      this.setState({user:newUser})
    }
    else if(attribute[0]==="homeLine1"){
      this.handleEmptyAddress()
      let newHomeAddress = newUser.homeAddress
      newHomeAddress.line1 = newValue
      newUser.homeAddress = newHomeAddress
      this.setState({user:newUser})
    }
    else if(attribute[0]==="homeLine2"){
      this.handleEmptyAddress()
      let newHomeAddress = newUser.homeAddress
      newHomeAddress.line2 = newValue
      newUser.homeAddress = newHomeAddress
      this.setState({user:newUser})
    }
    else if(attribute[0]==="homeCity"){
      this.handleEmptyAddress()
      let newHomeAddress = newUser.homeAddress
      newHomeAddress.city = newValue
      newUser.homeAddress = newHomeAddress
      this.setState({user:newUser})
    }
    else if(attribute[0]==="homeState"){
      this.handleEmptyAddress()
      let newHomeAddress = newUser.homeAddress
      newHomeAddress.state = newValue
      newUser.homeAddress = newHomeAddress
      this.setState({user:newUser})
    }
    else if(attribute[0]==="homeZipcode"){
      this.handleEmptyAddress()
      let newHomeAddress = newUser.homeAddress
      newHomeAddress.zipcode = newValue
      newUser.homeAddress = newHomeAddress
      this.setState({user:newUser})
    }
    else if(attribute[0]==="homeCountry"){
      this.handleEmptyAddress()
      let newHomeAddress = newUser.homeAddress
      newHomeAddress.country = newValue
      newUser.homeAddress = newHomeAddress
      this.setState({user:newUser})
    }
}
userAPI=(newUser)=>{

  const token = localStorage.getItem('token')
  axios.defaults.headers.common['Authorization']="JWT "+token
  axios.put(baseUrl +"api/customer/"+newUser.id+"/",newUser).then(resp=>{
    // console.log("respuesta del servidor recibida",resp)
    let neoListings=[]
    let neouser ={...resp.data}
    // console.log("premanipulado",neouser.listings)
    const rate = this.state.currency.rates[this.state.currency.selected]
    neouser.listings.forEach(listing => {
      listing.dailyPrice = parseFloat(listing.dailyPrice*rate).toFixed(2)
      listing.weeklyPrice = parseFloat(listing.weeklyPrice*rate).toFixed(2)
      neoListings.push(listing)
      
    }); 
    neouser.listings = neoListings
    // console.log("manipulado",neoListings)
    this.setState({user:neouser})
    return
  })
}
virtualUpdateListing=(myListing)=>{
  
  let newUser={...this.state.user}
  this.setState({myListing})
  newUser.listings.forEach((listing,index) => {
    if(listing.id===myListing.id){
      newUser.listings[index]=myListing
      this.setState({user:newUser})
      return
    }
  });
}
virtualDeleteListing=(id)=>{
  
  let newUser={...this.state.user}

  let listings=newUser.listings.filter(c=>c.id!==id)
  newUser.listings=listings
  this.setState({user:newUser})
}
listingAPI = (priority="low",newListing=null)=>{
  const rate = this.state.currency.rates[this.state.currency.selected]
  // console.log("listing api bobo",rate)
  let myListing
  if(newListing){
    myListing={...newListing}
    this.virtualUpdateListing(newListing)
  }else{myListing={...this.state.myListing}}

  myListing.dailyPrice=parseFloat(myListing.dailyPrice/rate).toFixed(2);
  myListing.weeklyPrice=parseFloat(myListing.weeklyPrice/rate).toFixed(2)
  myListing.owner={id:this.state.user.id}
  // console.log("listing api bobito",{...newListing},myListing,this.state.myListing)
  axios.post(baseUrl +"api/listings/",myListing).then(resp=>{
    //// console.log("respuesta del servidor recibida")
    //console.log("updated price",resp.data)
    let neoListing = {...resp.data}
    neoListing.dailyPrice=parseFloat(neoListing.dailyPrice*rate).toFixed(2);
    neoListing.weeklyPrice=parseFloat(neoListing.weeklyPrice*rate).toFixed(2)
    // neoListing.dailyPrice = parseFloat(neoListing.dailyPrice*rate).toFixed(2);
    // neoListing.weeklyPrice = parseFloat(neoListing.weeklyPrice*rate).toFixed(2);
    this.virtualUpdateListing(neoListing)
    return
  }).catch(err=>{
    // console.log("maldito error",err)
  })

}
updateMyListing = (name,newValue)=>{
  
  const newListing = {...this.state.myListing}

  if(name==="brand"){
    //console.log("newListing",newListing)
    newListing.vehicle.brand = newValue
    this.setState({myListing:newListing})

  }else if(name==="model"){
    //console.log("value",newValue)
    newListing.vehicle.model = newValue.label
    this.setState({myListing:newListing})
    return
  }
else if(name==="bankAccounts"){
  //console.log("Bank Accounts",newValue)
  newListing.bankAccounts = newValue
  this.setState({myListing:newListing})
  return
}
  else if(name==="fotos"){
    newListing.mainPicture = newValue.mainPicture
    newListing.listingPictures = newValue.listingPictures
    const token = window.localStorage.getItem('token')
    axios.defaults.headers.post['Authorization']="JWT "+token
    // console.log(newListing.mainPicture.id,"el maldito id",this.state.myListing.id,newValue.listingPictures)
    axios.post(baseUrl+'updatePictures/',{action:null,listing:this.state.myListing.id,id:this.state.user.id,mainPicture:newListing.mainPicture,pictures:newListing.listingPictures}).then(resp=>{
   
      const currentListing=this.state.myListing.id
      let user = {...resp.data}
      // console.log("recibido BEFORE",user.listings)
      let neoListingss=[]
      let neoListing = user.listings.filter(c=>c.id==currentListing)[0]
    const rate = this.state.currency.rates[this.state.currency.selected]

    newListing.dailyPrice = parseFloat(newListing.dailyPrice*rate).toFixed(2)
    newListing.weeklyPrice = parseFloat(newListing.weeklyPrice*rate).toFixed(2)

    user.listings.forEach(neoListing => {
      neoListing.dailyPrice = parseFloat(neoListing.dailyPrice*rate).toFixed(2)
    neoListing.weeklyPrice = parseFloat(neoListing.weeklyPrice*rate).toFixed(2)
    neoListingss.push(neoListing)
    });

      this.setState({user,myListing:neoListing})
    }).catch(err=>{console.log("error getting help",err)})

    this.setState({myListing:newListing})
    
  return
}
else if(name==="features"){
  const newFeatures = []
  //console.log("features",newValue)
  if(newValue){
    newValue.forEach(feature => {
      newFeatures.push({id:feature.value,name:feature.name,label:feature.label})
    });
  }
  
  newListing.features = newFeatures
  this.setState({myListing:newListing})
  return
}

  switch(name[0]){
        case "year":
          
          newListing.vehicle.year = newValue
          this.setState({myListing:newListing})
          return
          case "dailyPrice":
            newListing.dailyPrice = newValue
            //newListing.dailyPrice=newListing.dailyPrice*rate/oldRate
            this.setState({myListing:newListing})
            return
            case "weeklyPrice":
              //// console.log("here yet again")
              newListing.weeklyPrice = newValue
              this.setState({myListing:newListing})
              return
              case "carType":
                newListing.category = newValue
                this.setState({myListing:newListing})
                return
                case "currency":
                  
                  let newCurrency = {...this.state.currency}
                  
                  const oldRate = newCurrency.rates[newValue.oldVal]
                  
                  const rate = newCurrency.rates[newValue.value]
                  newCurrency.selected=newValue.value
                  
                  // console.log("oval",newValue.oldVal,newValue.value,oldRate,rate)
                  //console.log(oldRate,rate,"tarifasss")
                  //console.log("currency selected",this.state.currency)
                  let user = {...this.state.user}
                    let neoListingss=[]


                  user.listings.forEach(neoListing => {
                    neoListing.dailyPrice = parseFloat(neoListing.dailyPrice*rate/oldRate).toFixed(2)
                  neoListing.weeklyPrice = parseFloat(neoListing.weeklyPrice*rate/oldRate).toFixed(2)
                  neoListingss.push(neoListing)
                  // // console.log("trabajando",neoListing,oldRate,rate)
                  console.log(this.state.currency.selected)
                  });
                  user.listings=neoListingss
                  newListing.weeklyPrice=parseFloat(newListing.weeklyPrice*rate/oldRate).toFixed(2)
                  newListing.dailyPrice=parseFloat(newListing.dailyPrice*rate/oldRate).toFixed(2)
                  //console.log("newListing.dailyPrice",newListing.dailyPrice)
                  this.setState({myListing:newListing,currency:newCurrency,user})
                  return
                  case "units":
                    newListing.units = newValue
                    this.setState({myListing:newListing})
                    return
                    case "matricula":
                      newListing.matricula = newValue
                      this.setState({myListing:newListing})
                      return
                      case "listingPictures":
                        newListing.listingPictures = newValue
                        this.setState({myListing:newListing})
                        return
                        case "additionalDetails":
                          newListing.additionalDetails = newValue
                          this.setState({myListing:newListing})
                          return
                          case "features":
                            newListing.features = newValue
                            this.setState({myListing:newListing})
                            return
                            case "performance":
                              newListing.performance = newValue
                              //console.log(newValue)
                              this.setState({myListing:newListing})
                              return
                              
  }
}
logout = () =>{
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('userId');

this.setState({fetchedUser:false,logged:false,user:null,selected:'home'})
try{this.routeHomeRef.current.click()}catch (err){console.log(err)}
}
setLocation=(location)=>{
  this.setState({location:location})
}
getHelp =()=>{
  if(!this.state.user.is_superuser){
  const token = window.localStorage.getItem('token')
  axios.defaults.headers.get['Authorization']="JWT "+token
  axios.get(baseUrl+'getHelp/').then(resp=>{
    console.log("got help",resp.data)
    this.setState({user:resp.data,chatDialog:{active:true,dialogId:resp.data.supportChats[0].id,type:"support"}})
  }).catch(err=>{console.log("error getting help",err)})
}
}
bookingAPI = (id,message,support=false)=>{
  const that = this
  let chatss = this.state.chats
  //console.log("prime chats",chatss,support)
  function waitForSocketConnection(socket, callback){
    //console.log("socket",socket)
    socket = socket.client?socket.client:socket
    setTimeout(
        function () {
            if (socket.readyState === 1) {
                //console.log("Connection is active")
                if (callback != null){
                    callback();
                }
            } else {
                //console.log("wait for connection...")
                waitForSocketConnection(socket, callback);
            }

        }, 5); // wait 5 milisecond for the connection...
}
  //console.log(this.state.chats, "chatttts")
  
  async function Run(chatss){
    console.log("just set cliente 1",chatss)
    await that.checkConnections()
    let client
    let rawClient = null
    if(support){
      if(that.state.user.is_superuser){
        //console.log("looking for clients",id,that.state.supportChats, that.state.supportChats.filter(c=>c.supportChat.id===id))
        rawClient = await that.state.supportChats.filter(c=>c.supportChat.id===id)[0]
        console.log("just set clients 2",rawClient)
        const callback = function(chatss){
          rawClient.client?rawClient.client.send(JSON.stringify(message)):rawClient.send(JSON.stringify(message))
          //rawClient.client.send(JSON.stringify(message))
          //client.send(JSON.stringify(message))
        }
        waitForSocketConnection(rawClient,callback)
        return
      }else{
        client = that.state.notificationClient
      }
    }else{
      client = chatss.filter(c=>c.id===id)[0].client
    }
    const callback = function(chatss){
      console.log("finally client",client)
      client.client?client.client.send(JSON.stringify(message)):client.send(JSON.stringify(message))
      //client.send(JSON.stringify(message))
    }
    waitForSocketConnection(client,callback)
    
  }
  Run(chatss)

}
openNotifications=(id)=>{
  let protocol
  let port
  if(getUrl.protocol==="http:"){
    protocol="ws://"
    port=":8000"
  } else{
    protocol="wss://"
    port=":8000"
  }
  const that = this
  if(id!=null){
    var client = new W3CWebSocket(protocol+"dialerprieto.xyz:8000/ws/notifications/"+id+"/");

    client.onerror = (e) => {

        console.log('Connection Error',e);
    };
    
    client.onopen = (e) =>{
      console.log('WebSocket Client Connected TO MAIN NOTIFICATIONS '+id);
  
    };
    
    client.onclose = (e) => {
        console.log('echo-protocol Client Closed');
    };
    
    client.onmessage = (e) =>  {
      let data = JSON.parse(e.data)
      let newChats = [...that.state.supportChats]

        if (typeof e.data === 'string') {
          
          if(data.action){
            console.log("Received FROM NOTIFICATIONS:",data.message);
            if(data.message.id===parseInt(window.localStorage.getItem("userId"))){
              async function process(){
                let newSupportChat = data.message
                if(data.action==="message"){
                  newChats = newChats.filter(c=>c.supportChat.id!=newSupportChat.id)
                  newChats.push({supportChat:newSupportChat,client:client})//that.state.supportChats.filter(c=>c.supportChat.id===newSupportChat.id)[0]})
                  that.setState({supportChats:newChats})
                  return
                }else if(data.action==="newSupport"){
                  console.log("new support chat")
                  const newClient = await that.openNotifications(data.message.customer.id)
                  newChats.push({supportChat:data.message,client:newClient})
                  return
                }else{
                  let notifications = data.message.activeLeasing.concat(data.message.activeRenting)
                  if(that.state.currency){
                  let neoListings = [];
                  const rate = that.state.currency.rates[that.state.currency.selected]
                  data.message.listings.forEach(listing => {
                    // listing.dailyPrice=listing.dailyPrice*rate
                    // listing.weeklyPrice=listing.weeklyPrice*rate
                    neoListings.push(listing)
                  });
                  data.message.listings = neoListings
                }
                  that.setState({user:data.message,notifications})
                  return
                }
              }
              process()


            }
            else{
              if(that.state.user.is_superuser){
                console.log("new support chat failure",data.action)
                  if(data.action==="newSupport"){
                    
                    const newClient = that.openNotifications(data.message.customer.id)
                    newChats = newChats.filter(c=>c.supportChat.id!=data.message.id)
                    newChats.push({supportChat:data.message,client:newClient})
                    console.log("pusssssshing")
                    that.setState({supportChats:newChats})
                    return
                  }else if(data.action==="initData"){
                    return
                  }
                  else if(data.action==="message"){
                    let newSupportChat = data.message
                    const oldChat = newChats.filter(c=>c.supportChat.id==newSupportChat.id)[0]
                    if(oldChat&&newSupportChat.closed!=oldChat.closed){
                      
                      newChats = newChats.filter(c=>c.supportChat.id!=newSupportChat.id)
                      newChats.push({supportChat:newSupportChat,client:client})//that.state.supportChats.filter(c=>c.supportChat.id===newSupportChat.id)[0]})
                      that.setState({supportChats:newChats})
                    }
                  return
                }
                  
                  else{
                    let newSupportChat = data.message.supportChats?data.message.supportChats[0]:data.message
                    if(!newSupportChat.closed){
                      newChats = newChats.filter(c=>c.supportChat.id!=newSupportChat.id)
                      console.log(newSupportChat,data.message.id,that.state.supportChats,that.state.supportChats.filter(c=>c.supportChat.customer.id==data.message.id)[0])
                      newChats.push({supportChat:newSupportChat,client:that.state.supportChats.filter(c=>(c.supportChat.customer.id==data.message.id)||(c.supportChat.id==data.message.id))[0].client})
                      that.setState({supportChats:newChats})
                    }

                  }
              }else{
                let newSupportChat = data.message.supportChats?data.message.supportChats[0]:data.message
                let newUser = {...that.state.user}
                // if(newSupportChat.closed){
                //   newUser.supportChats= []
                // }else{newUser.supportChats[0]=newSupportChat}
                newUser.supportChats[0]=newSupportChat

                that.setState({user:newUser})
              }

            }

          }
            
        }
    }
return client
  }
  
}
openChat=(id)=>{
  let protocol
  let port
  if(getUrl.protocol==="http:"){
    protocol="ws://"
    port=":8000"
  } else{
    protocol="wss://"
    port=":8000"
  }
  const that = this
  var client = new W3CWebSocket(protocol+"18.216.39.52/ws/chat/"+id+"/");

      client.onerror = (e) => {

          console.log('Connection Error',e);
      };
      
      client.onopen = (e) =>{
        console.log('WebSocket Client Connected ' + id);
    
      };
      
      client.onclose = (e) => {
          console.log('echo-protocol Client Closed');
      };
      
      client.onmessage = (e) =>  {
        let data = JSON.parse(e.data)

          if (typeof e.data === 'string') {
            
            
            if(data.action){

              //console.log("Received:",data.message);
              if(data.message.messages){
                if(data.message.messages[data.message.messages.length-1]&&data.message.messages[data.message.messages.length-1].sender!=this.state.user.id){
                  const audioPromise = this.alertRef.current.play()
                  if (audioPromise) {
                    audioPromise.catch((e) => {
                        if (e.name === 'NotAllowedError' ||
                            e.name === 'NotSupportedError') {
                            //console.log(e.name);
                        }
                    });
                }
                  
                }
              }

              
              let oldBookings = that.state.notifications
              let index
              oldBookings.map((c,y)=>{
                if(c.id===data.message.id){
                  index=y
                }}
                )
                let current = oldBookings[index]
              current=data.message
              if(current.customer.id===current.listing.owner.id){
                current.self=true
              }
              oldBookings[index] = current
              that.setState({notifications:oldBookings})
            }else{
              
            }
              
          }
      };
  return client
}
checkConnections=()=>{
  const that = this
  async function Run(){
  let changed = false
  let newNotifications=false
  let notificationClient
  const userId = window.localStorage.getItem('userId')
  if(that.state.notificationClient!=null && that.state.notificationClient.readyState===WebSocket.CLOSED){
    notificationClient = that.openNotifications(userId)
    newNotifications=true
  }
  let chats = []
  if(that.state.chats){
    chats= that.state.chats
  }
  
  if(that.state.notifications){
    const myNotifications = that.state.notifications
    
    myNotifications.forEach(booking => {
      let existingBooks = chats.filter(c=>c.id==booking.id)
      if(existingBooks.length===0){
        //console.log(chats.filter(c=>c.id==booking.id).length,"chatssss")
        let client = that.openChat(booking.id)
        chats.push({id:booking.id,client})
        changed=true
        //console.log("updating chats",chats.length,client)
    }else{
      if(existingBooks[0].client.readyState === WebSocket.CLOSED){
        chats = chats.filter(c=>c.id!=booking.id)
        let client = that.openChat(booking.id)
        chats.push({id:booking.id,client})
        changed=true
      }
    }
    });
    if(changed){
      await that.setState({chats})
    }
    if(newNotifications){
      await that.setState({notificationClient:notificationClient})
    }
    }
  
  }
  Run()
}
     componentDidMount(){
    //   const stripePromise = loadStripe(
    //     "pk_test_6pRNASCoBOKtIshFeQd4XMUh"
    // );
    // const stripe = useStripe();
    // const stripeEls = useElements();
    //     this.setState({stripeElements:{stripePromise,stripe,stripeEls}})
       const that = this
       let latitude
       let longitude
       async function test(){
        const firstName = window.localStorage.getItem('firstName')
        const lastName = window.localStorage.getItem('lastName')
        const address1 = window.localStorage.getItem('address1')
        const address2 = window.localStorage.getItem('address2')
        const city = window.localStorage.getItem('city')
        const state = window.localStorage.getItem('state')
        const zipzip = window.localStorage.getItem('zipcode')
        let neoState = {...that.state.shippingInfo}
        neoState.firstName=firstName
        neoState.lastName=lastName
        neoState.address1=address1
        neoState.address2=address2
        neoState.city=city
        neoState.state=state
        neoState.zipcode=zipzip
        that.setState({shippingInfo:neoState,//filters:{},results:{},
          carlyOrder:{
            startDate:moment(),endDate:moment().add(7,'d'),
            selectedCard:{id:null},
            bank:{id:null},
            deliveryLocation:{id:null}
          }
        })
        await that.updateDimensions()
        await navigator.geolocation.getCurrentPosition(
          position => {
            latitude= position.coords.latitude
            longitude= position.coords.longitude
          }, 
          err => {
              latitude= 18.4637770
              longitude= -69.9336969
            console.log("error ubicando cliente",err)}
        );
        that.updateDimensions()
        that.setState(latitude,longitude)
        that.getUser()
       }
       test()
      
      //this.updateDimensions()
      document.querySelector('html').addEventListener('keydown',this.processKey)
      window.addEventListener('scroll',this.handleScroll)
      window.addEventListener("resize", this.updateDimensions);
     }
     componentWillUnmount(){
      document.querySelector('html').removeEventListener('keydown',this.processKey)
      window.removeEventListener('scroll',this.handleScroll)
      window.removeEventListener('resize',this.updateDimensions)
    }
     switchLoginModal=(newModal)=>{
       try {
        let navElement=document.querySelector('#root > div > div.navBar');
        if(newModal.active){
          
          navElement.style.position="relative"
        }else{
         navElement.style.position="fixed"
        }
       } catch (error) {
         //pass
       }
      
       this.setState({loginModal:newModal})
     }
     switchRent=(e,f=null)=>{
      let prevVal
      if(f){
        prevVal= true
      }else{
        if(!f){
          prevVal= false
        }else{
          prevVal= !this.state.rent
        }
        
      }
      this.setState({rent:prevVal})
     }
    closeAll=()=>{
      this.setState({modal:{open:false,type:''}})
    }
    checkoutFunction=(e)=>{
      try {
        e.stopPropagation()
        e.preventDefault()
      } catch (error) {
        
      }

      this.setState({ulteriorMotive:{active:true,route:"checkout"}})

      if(this.state.shippingAddress && (this.state.shippingInfo.address1&&this.state.shippingInfo.zipcode&&this.state.shippingInfo.state)){
        
        if(this.state.user && this.state.user.cards.length>0){
          this.routeCheckoutRef.current.click()
        }else{
          this.routeCheckoutRef.current.click()
        }
      }else{
        this.routeCheckoutRef.current.click()
      }
        
      
      this.closeAll()
      
    }
    popUpSwitch=(e)=>{
      try {
        e.preventDefault()
        e.stopPropagation()
      } catch (error) {
        //
      }

      let neoModal={...this.state.modal}
      neoModal.open=!neoModal.open
      this.stateHandling('modal',neoModal)
  }
  valueHandling=(e)=>{

  }
  setCurrency=(value)=>{
    let newCurrency = {...this.state.currency}
    const oldVal=newCurrency.selected
    newCurrency.selected=value
    this.setState({currency:newCurrency},()=>{this.updateMyListing(["currency"],{oldVal,value})})
    
  }
  handleFilters =(filters)=>{
    this.setState({filters})
  }
  handleOpen = (action,data=null,next=null)=>{
    if(data!==null){
    }
    
    if(next){
    }
    const listings = next

    if(action==="cloneListing"){
      this.removeUlteriorMotive()
      //this.createListing("clone",action,data,next)
      
      let myUser={...this.state.user}
      let newListing=data
      newListing.id=null
      newListing.mainPicture=null
      newListing.listingPictures=[]
      const rate =this.state.currency.rates[this.state.currency.selected]
      newListing.dailyPrice=parseFloat(newListing.dailyPrice/rate).toFixed(2);
      newListing.weeklyPrice=parseFloat(newListing.weeklyPrice/rate).toFixed(2)
      this.setState({dialogFetching:true,dialogData:{action:action,value:newListing}})
      axios.post(baseUrl +"api/listings/",newListing).then(resp=>{
        
        let newListings = listings
        let neoListingt = {...resp.data}
        neoListingt.dailyPrice = parseFloat(neoListingt.dailyPrice*rate).toFixed(2);
        neoListingt.weeklyPrice=parseFloat(neoListingt.weeklyPrice*rate).toFixed(2);
        newListings.push(neoListingt)
        myUser.listings=newListings
        //this.virtualUpdateListing(neoListingt)
        this.setState({myListing:neoListingt,user:myUser,open:true,dialogFetching:false,dialogData:{action:action,value:neoListingt}})
        this.handleReFetchUser()
      })
      
      return  
    }else if(action==="newListing"){
      this.removeUlteriorMotive()
      let idRaw= localStorage.getItem("userId")
      data.owner={id:parseInt(idRaw)}
      this.createListing("new",action,data,next)
      
      return
    } else if(action=="finishListing"){
      this.createListing("finish",action,data,listings)
    }  else if(action=="book"){
      console.log("set up ulterior book",data)
      this.setState({listingForRent:data})
    }
    this.setState({dialogData:{action:action,value:data}},()=>{this.setState({open:true})})
    return
  }
  closeSupport=(id)=>{
    const token = window.localStorage.getItem('token')
    axios.defaults.headers.common['Authorization']="JWT "+token
    axios.post(baseUrl + `closeSupport/`,{customerId:id}).then(resp=>{
      if("SUCCESS"===resp.data){
        console.log(resp.data,"respuesta close sup")
        let newUser = {...this.state.user}
        newUser.supportChats=[]
       
        if(newUser.is_superuser){
          let allChats=[...this.state.supportChats]
          allChats=allChats.filter(c=>c.supportChat.id!=id)
          console.log("reduccionxx",allChats)
          this.setState({supportChats:allChats,chatDialog:{active:false,dialogId:null}})
        }else{
          this.setState({user:newUser,chatDialog:{active:false,dialogId:null}})
          
        }
        
      }
      
    }).catch(err=>{
      console.log(err)
      this.setState({chatDialog:{active:false,dialogId:null}})
    })
  }
  setPopOver=(popOver)=>{
    this.setState({popOver})
  }
  setUsername=(username)=>{
    this.setState({username})
  }
  setRedirectUri=(redirectUri)=>{
    this.setState({redirectUri})
  }
  setPassword=(password)=>{
    this.setState({password})
  }
  setData=(data)=>{
    this.setState({data})
  }
  setSocialLogin=(socialLogin)=>{
    this.setState({socialLogin})
  }
    render() {
      const filteredResults = this.state.filteredResults
      let results= [...this.state.rawResults]
      if(filteredResults){
        results=[...this.state.filteredResults]
      }
      const shopData={paypal:this.state.paypal,card:this.state.card,bitcoin:this.state.bitcoin}
      const methods={getUser:this.getUser,stateHandling:this.stateHandling,checkoutFunction:this.checkoutFunction,
        popUpSwitch:this.popUpSwitch,capitalize,axios,baseUrl,
        login:this.login,logout:this.logout,formatNumber,neoSelected:this.neoSelected,
        handleRegister:this.handleRegister}
      const refs={
        routeUserIdRef:this.routeUserIdRef,
        routeProfileRef:this.routeProfileRef,
        routeChatRef:this.routeChatRef,
        routeHomeRef:this.routeHomeRef,
        routeListingsRef:this.routeListingsRef,
        routeSavedRef:this.routeSavedRef,
        routeMakeMoneyRef:this.routeMakeMoneyRef,
        routeMyProfileRef:this.routeMyProfileRef,
        routeAccountRef:this.routeAccountRef
      }
      // const elements={
      //   ulteriorMotive:this.state.ulteriorMotive,order:this.state.order,shippingAddress:this.state.shippingAddress,
      //   billingAddress:this.state.billingAddress
      // }
      const userPack={
        refs,stripeElements:this.state.stripeElements,order:this.state.order,
        stripeObj:this.state.stripeObj,stripeEls:this.state.stripeEls,
        stripeCustomer:this.state.stripeCustomer,
        dialog:{
          fetched:this.state.dialogFetching,data:this.state.dialogData,
        open:this.state.open,button:this.state.button, 
        handleClose:this.handleClose,
          handleOpen:this.handleOpen,handleSwitch:this.handleSwitch
        },
          filters:this.state.filters,
          filteredResults:this.state.filteredResults,
          filterMethods:{setFilteredResults:this.setFilteredResults,setCarCategory:this.setCarCategory,
            setLocation:this.setLocation,handleFilters:this.handleFilters
          },
          rawResults:this.state.rawResults,
          elements:{...this.state.elements, latitude:this.state.latitude,longitude:this.state.longitude,currency:this.state.currency},
          setCurrency:this.setCurrency,
          bookingAPI:this.bookingAPI,popOver:this.state.popOver,
          setPopOver:this.setPopOver,
          loginElements:{handleRegister:this.handleRegister,socialRegister:this.socialRegister,redirectUri:this.state.redirectUri,
            data:this.state.data,setSocialLogin:this.setSocialLogin,setRedirectUri:this.setRedirectUri,
            setData:this.setData,username:this.state.username,password:this.state.password,socialLogin:this.state.socialLogin,
            setUsername:this.setUsername,setPassword:this.setPassword},
          supportChats:this.state.supportChats,
          notifications:this.state.notifications,
          results:results,
          login:this.login,
          switchChat:this.switchChat,
        systemLogin:this.systemLogin, height:this.state.height,
        verified:this.state.user?this.state.user.verified:null,
        userId:this.state.user?this.state.user.id:null,
        listings:this.state.user?this.state.user.listings:[""],
        allListings:this.state.user?this.state.user.listings:[""],
        width:this.state.width,
        units:{all:[{
            value: 'Km/gal',
            label: 'Km/gal',
          },
          {
            value: 'Mi/gal',
            label: 'Mi/gal',
          }]},
        mainScrollDown:this.mainScrollDown,
        reFetchUser:this.handleReFetchUser,
        fetchModels:this.fetchModels,
        isMobile:this.state.width<960,
        steps:this.state.steps,
        profilePicture:this.state.user?this.state.user.profilePicture:null,
        myListing:this.state.myListing,
        updateMyListing:this.updateMyListing,
        updateMyUser:this.updateMyUser,
        userAPI:this.userAPI,
        FBLogin:this.FBLogin,
        listingAPI:this.listingAPI,
        deleteListing:this.deleteListing,
        switchListing:this.switchListing,
        chatDialog:this.state.chatDialog,
        scrollValue:this.state.scrollValue,
        ulteriorMethods:{removeUlteriorMotive:this.removeUlteriorMotive,setUlteriorMotive:this.setUlteriorMotive},
        book:{myBooking:this.state.myBooking,emptyBooking,
          updateMyBooking:this.updateMyBooking,
          location:this.state.location,payment:this.state.payment,handlePayment:this.handlePayment,
          bookingAPI:this.bookingAPI,listingForRent:this.state.listingForRent,setLocation:this.setLocation,
          latitude:this.state.latitude,longitude:this.state.longitude,setListingForRent:this.setListingForRent
        },
        closeSupport:this.closeSupport,
        getHelp:this.getHelp,
        logout:this.logout,
        fetchedUser:this.state.fetchedUser,
        user:this.state.user,
        axiosPack:this.state.axiosPack,
        latitude:this.state.latitude,
       shippingInfo:this.state.shippingInfo,
       shopData:shopData,
       modal:this.state.modal,
       w,documentElement,body,
      longitude:this.state.longitude, 
      dimensions:this.state.dimensions,
      navColor:this.state.navColor,listingHeredado:this.state.listingHeredado,
        loginModal:this.state.loginModal,
        methods:methods,
        selected:this.state.selected,logged:this.state.logged,
        rent:this.state.rent,switchRent:this.switchRent,currency:this.state.currency,rawResults:this.state.rawResults,
        switchLoginModal:this.switchLoginModal}
      return ( 
      <>
              {/* {this.state.modal.open?
        <>
        
        <ModalHandler userPack={userPack}/>
        </>:null} */}
        <Elements stripe={stripePromise}>
    <ElementsConsumer>
    {({stripe, elements}) => (
      <>
             {this.state.loginModal.active?<LoginModal userPack={userPack}/>:null}

        {/* {userPack.chatDialog.active?
        <Chat userPack={userPack}/>
        :null} */}
        <MainDialogs userPack={userPack}/>

<Router>
  
   <MainView path="/" userPack={userPack} fetched={this.state.fetched} results={results}/>
   <MakeMoney userPack={userPack} path="/makemoney"/>
   <SingleListing userPack={userPack} path="/listings/:listingId" />
   <Listings userPack={userPack} path="/listings" fetched={this.state.fetched} results={results} />
   <LoginMobile path="/loginMobile" userPack={userPack} />
   <Saved userPack={userPack} path="/saved" />
   <Chaty userPack={userPack} path="/chat" />

   <PublicTerms userPack={userPack} path="/public-terms-of-service" />
   <PublicPrivacy userPack={userPack} path="/public-privacy-policy" />
   
   
   <Perfil userPack={userPack} path="/myProfile" />
            <User userPack={userPack} path="/user/:userId" />
            <Cuenta userPack={userPack} path="/account" />
   {["/dashboard",'/profile','/dashboard/home','/dashboard/consejos','/dashboard/agenda'
   ,'/dashboard/vehicles','/dashboard/contabilidad','/dashboard/trips','/dashboard/locations'].map((pathy)=>(  
      <UserDashBoard path={pathy} userPack={userPack} user={this.state.user} vehicles={this.state.vehicles}/>))}
          {["/checkout", "/checkout/Information","/checkout/Shipping","/checkout/Payment"].map((page) => (
        <Checkout tabs={['Información','Lugar de encuentro','Pago']} stripe={stripe} elements={elements} path={page} userPack={userPack}/>
      ))}
   {/* <NewListing path="/newlisting" userPack={userPack}/>
   <NewBooking  path="/newbooking" userPack={userPack}/> 
   <SingleListing  path="/singlelisting" userPack={userPack}/> */}
   {["/userId", "/login","/register","/dashboard/userId","/dashboard/login","/dashboard/register",
                "checkout/login","checkout/register","checkout/userId",
                "checkout/info/login","checkout/info/register","checkout/info/userId",
                "checkout/shipping/login","checkout/shipping/register","checkout/shipping/userId",
                "checkout/payment/login","checkout/payment/register","checkout/payment/userId"].map((page) => (
        <UserId path={page} userPack={userPack}/>
      ))}
  </Router>
  </>

          )}
          </ElementsConsumer>
            </Elements>
            <audio ref={this.alertRef}>
          <source src="media/mp3/alert.mp3" type="audio/mpeg"/>
        </audio>
        
        <Link to="/dashboard/userId" className="buttonClass" ref={this.routeUserIdRef}/>
        <Link to="/dashboard" className="buttonClass" ref={this.routeProfileRef}/>
        <Link to="/myProfile" className="buttonClass" ref={this.routeMyProfileRef}/>
        <Link to="/account" className="buttonClass" ref={this.routeAccountRef}/>
      <Link to="/saved" className="buttonClass" ref={this.routeSavedRef}/>
      <Link to="/" className="buttonClass" ref={this.routeHomeRef}/>
      <Link to="/listings" className="buttonClass" ref={this.routeListingsRef}/>
      <Link to="/makemoney" className="buttonClass" ref={this.routeMakeMoneyRef}/>
      <BottomNavBar userPack={userPack}/>
      </>
     );
  }
}


export default App;
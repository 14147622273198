import React from 'react';
import BackArrow from '../SvgElements/BackArrow';
import CartIcon from '../SvgElements/CartIcon';

export default function(props){
    return(
        <>
        <div className="navPlain">
            <BackArrow/>
            <div className="title2 alignCenter">{props.title}</div>
        </div>
        
        
        
        </>
    )
}




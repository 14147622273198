import React, { Component } from 'react';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';
import { ButtonBase, Button } from '@material-ui/core';

const ChatLine=(props)=>{
  let tag
  const time= new Date(props.message.date)
  let pic
  
  if(props.type==="support"){

    pic="https://sdtultrasound.com/images/support-icon.png"
    if(props.is_superuser){
      pic=props.booking.customer.profilePicture?props.booking.customer.profilePicture.url:"https://www.munny.dk/wp-content/uploads/2017/11/Male-missing.png"
    }
    if(props.message.sender===props.userId){
      tag="message message-personal new"
    }else{
      tag="message new"
    }
  }else{
    try{
    if(!props.message.sender===props.userId){
      tag="message message-personal new"
      if(props.isOwner){
        if(props.booking.listing.owner.profilePicture){pic=props.booking.listing.owner.profilePicture.url}}
        else{
          if(props.booking.customer.profilePicture){pic=props.booking.customer.profilePicture.url}}
    }else{
      tag="message new"
      console.log("es un puto owner",props.isOwner)
      if(!props.isOwner){
        if(props.booking.listing.owner.profilePicture)
          {pic=props.booking.listing.owner.profilePicture.url}}
      else{
        if(props.booking.customer.profilePicture){
          pic=props.booking.customer.profilePicture.url}
        else{pic="https://www.munny.dk/wp-content/uploads/2017/11/Male-missing.png"}
      }}
    }catch(exception){console.log("exception",exception)}
  }

  let newDay = false
  let dayName = "Hoy"
  if(props.previousMessage){
    const prevDate = new Date(props.previousMessage.date)
    const today = new Date(moment().format())
    const diff = prevDate.getUTCDate()-time.getUTCDate()
    if(diff!==0){
      newDay=true
      if(today.getUTCDate()-time.getUTCDate()!==0){
        dayName = today
      }
    }
  }
  
function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}
const myTime = formatAMPM(time);
  //console.log("problem time?",time,props.message.text,pic)

  return(
    <>
    {newDay?
    null
    //  <div className="day">hoy</div>
    :
    null
    }
      <div className={tag}>
         {props.message.text}
        {tag==="message new"?
        <>
        <figure className="avatar"><img alt="Remy Sharpy" src={pic?pic:"https://www.munny.dk/wp-content/uploads/2017/11/Male-missing.png"} /></figure>
        
        </>
        :
        null
        }
        <div className="timestamp">{myTime}</div>
        </div>
    </>
  )
}

class Chat extends Component {

  constructor(props){
    super(props)
    let support
    if(this.props.userPack.chatDialog.type==="support"){
      support=true
    }else{support=false}
    const myHtml=document.getElementsByTagName('html')[0]
    this.state = {myId:this.props.userPack.chatDialog.dialogId,newText:"",
    focused:false,html:myHtml,support,
    is_superuser:this.props.userPack.user.is_superuser}
  }
  componentDidMount(){
    if(this.props.userPack.dimensions.width<600){
      this.state.html.classList.add("stop-scrolling");
    }
    this.scrollMessages()
  }
  componentDidUpdate(){
    if(this.props.userPack.dimensions.width<600){
      this.state.html.classList.add("stop-scrolling");
      //let html = document.getElementsByTagName('html')[0]
      //this.state.html.classList.add("stop-scrolling");
    }
    this.scrollMessages()
  }
  componentWillUnmount(){
    // let html = document.getElementsByTagName('html')[0]
    this.state.html.classList.remove("stop-scrolling");
  }
  scrollMessages=()=>{
    let chatElement = document.getElementsByClassName('messages-content')[0]
    chatElement.scrollTop = chatElement.scrollHeight;
  }
  focusChat =(e)=>{
    const that =this
    e.persist()
    async function run(){

      if(that.props.userPack.dimensions.width<600){
        await window.scrollTo(0,0)
        //let html = document.getElementsByTagName('html')[0]
        
        let newFocus=!that.state.focused
        
        if(!newFocus){
          console.log(e.target,"new Foxus x")
          that.handleSend(e)
        }
        that.setState({focused:newFocus})
        await that.state.html.classList.add("stop-scrolling");
      }
    }

    run()
  }
  
  
  handleText=(e)=>{
    
    this.setState({newText:e.target.value})
  }
  handleEnter=(e)=>{
    if(e.key==="Enter"){
      this.handleSend(e)
      e.preventDefault()
      //props.userPack.sendMessage({action:"message",sender:props.userPack.userId,text:newText})
    }
  }
  finalizeSupport=()=>{
    this.state.html.classList.remove("stop-scrolling");
    let finalizeId
    if(this.state.is_superuser){
      finalizeId=this.state.myId
    }else{finalizeId=this.props.userPack.user.id}
    this.props.userPack.closeSupport(finalizeId)
    // props.userPack.bookingAPI(booking.id,{"final":"yes","action":"message","chatId":booking.id,"message":{text:newText,sender:props.userPack.user.id}},support)
    // setText("")
  }
  handleSend=(e)=>{
    const myId = parseInt(e.target.getAttribute('id'))
    console.log("bout to send",e.target)
    //console.log(myId,this.props.userPack.user.id,this.state.newText,this.state.support)
    if(this.state.newText!==""&&!e.target.getAttribute('disabled')){
      this.props.userPack.bookingAPI(myId,{"action":"message","chatId":myId,"message":{text:this.state.newText,sender:this.props.userPack.user.id}},this.state.support)
      this.setState({newText:""})
    }

  }
  closeChat=()=>{
    this.state.html.classList.remove("stop-scrolling");
    this.props.userPack.switchChat({active:false,dialogId:null})
  }
  getHelp=()=>{
    this.props.userPack.getHelp()
  }

  render() {
    let booking
    let isOwner
    let name

    let pic
    let support
    const type=this.props.userPack.chatDialog.type
    console.log("my real id",this.props.userPack.chatDialog.dialogId)
    if(type && type==="support"){
      if(this.props.userPack.user.is_superuser){
        console.log("mis reales supports chats",[...this.props.userPack.supportChats])
        booking = [...this.props.userPack.supportChats].filter(c=>c.supportChat.id===this.props.userPack.chatDialog.dialogId)[0].supportChat
      }else{
        booking = this.props.userPack.supportChats.concat(this.props.userPack.user.supportChats).filter(c=>c.id===this.props.userPack.chatDialog.dialogId)[0]
      }
      
    } else{
      
      booking = this.props.userPack.notifications.filter(c=>c.id===this.props.userPack.chatDialog.dialogId)[0]
      isOwner = this.props.userPack.user.id===booking.listing.owner.id
      console.log("no soporte",isOwner)
      try{
      if(isOwner){
        name=booking.customer.first_name+" "+booking.customer.last_name
        if(booking.customer.profilePicture){pic=booking.customer.profilePicture.url}
        else{pic="https://www.munny.dk/wp-content/uploads/2017/11/Male-missing.png"}
      } else{
        name=booking.listing.owner.first_name+" "+booking.listing.owner.last_name
        if(booking.listing.owner.profilePicture){pic=booking.listing.owner.profilePicture.url}
        else{pic="https://www.munny.dk/wp-content/uploads/2017/11/Male-missing.png"}
      }
      console.log("reusltado pic",pic)
      }catch(err){
        if(!isOwner){
          name=booking.customer.first_name+" "+booking.customer.last_name
        }else{
          name=booking.listing.owner.first_name+" "+booking.listing.owner.last_name
        }
        console.log("error de url?",err)
      }
    }
    
    if(booking.listing){
      support=false
    }else{
      support=true
      
      if(this.props.userPack.user.is_superuser){
        //console.log(booking.customer,"booking cux")
        pic=booking.customer.profilePicture?booking.customer.profilePicture.url:"https://www.munny.dk/wp-content/uploads/2017/11/Male-missing.png"
        name = (booking.customer.first_name===""&&booking.customer.last_name==="")?"No definido":booking.customer.first_name+" "+booking.customer.last_name
        name = "Soporte para: "+name
      }else{
        name="CARLY SUPPORT"
        pic="https://sdtultrasound.com/images/support-icon.png"
      }
      
    }
    const isMobile=this.props.userPack.dimensions.width<600
    const positionTop=this.props.userPack.scrollValue+"px"
    
    let location
    let html = document.getElementsByTagName('html')[0]
    let top=html.scrollTop
    let chatBoxStyle={zIndex:"10",width:isMobile?"100%":this.props.userPack.dimensions.width/2+"px",position:"fixed",top:isMobile?(this.state.focused?"0px":-this.props.userPack.dimensions.height*0+"px"):this.props.userPack.dimensions.height-500+"px",right:"0px"}
    if(this.state.focused){
      console.log("valor de top",top)
      {if(top===0){
        chatBoxStyle.top="175px"
      }else{
        chatBoxStyle.bottom="-305px"
      }
    }
    }else{chatBoxStyle.bottom="0px"}
  
    
    
    
  
    return (  <>
      <div className="chatBox" style={chatBoxStyle}>

      <div className="chat" style={{height:this.state.focused?"48vh":"80vh",width:isMobile?this.props.userPack.dimensions.width-20+"px":"350px"}}>
      {this.state.support?<ButtonBase onClick={this.finalizeSupport} style={{backgroundColor:"#f50057",color:"white",padding:"5px",fontWeight:"bold",fontSize:"14px"}}>Finalizar Chat</ButtonBase>
        :null
        }
        <div className="chat-title">
          <Grid container>
            <Grid item xs={10}>
            <figure className="avatar" >
              <img style={{backgroundSize:"cover"}} alt="Remy" src={pic} /></figure>
            <h1 style={{color:"black",fontSize:"14px",fontWeight:"bold"}}>{name}</h1> 
            </Grid>
            <Grid item xs={2}>
              <Grid container justify="flex-end">
                <Grid item xs="auto">
                  <CancelIcon onClick={this.closeChat}/>
                </Grid>
              </Grid>
              </Grid>
          </Grid>
          
          {/* <h2>Supah</h2> */}

        </div>
        <div className="messages">
          <div className="messages-content">
            {booking.closed?
            <>
                                    {booking.messages.sort((a, b) => a.id- b.id ).map((message,index)=>(
        <ChatLine is_superuser={this.state.is_superuser} type={type} previousMessage={index===0?null:booking.messages[index-1]} key={message.id} message={message} userId={this.props.userPack.user.id} isOwner={isOwner} booking={booking} />
      ))}
            <Grid container justify="center" style={{marginTop:"30px"}}>
              <Grid item xs="auto" >
                <p style={{fontWeight:"bold",fontSize:"18px",color:"black"}}>Este chat ha sido finalizado</p>
              </Grid>
            </Grid>
            <Grid container justify="center" style={{marginTop:"25px"}}>
              <Grid item xs="auto" >
                <Button variant="outlined" onClick={this.getHelp}>Abrir un nuevo chat</Button>
              </Grid>
            </Grid>
            
            </>
              :
              <>
                        {booking.messages.sort((a, b) => a.id- b.id ).map((message,index)=>(
        <ChatLine is_superuser={this.state.is_superuser} type={type} previousMessage={index===0?null:booking.messages[index-1]} key={message.id} message={message} userId={this.props.userPack.user.id} isOwner={isOwner} booking={booking} />
      ))}
              </>
              }


          </div>
        </div>
        <div className="message-box">
          <textarea type="text" id={booking.id} disabled={booking.closed} onSubmit={this.handleSend} onKeyPress={this.handleEnter} onFocusCapture={this.focusChat} onBlurCapture={this.focusChat} className="message-input" placeholder="Escribir mensaje..." value={this.state.newText}
          onChange={this.handleText}></textarea>
          <button type="submit" id={booking.id} disabled={booking.closed} className="message-submit" onClick={this.handleSend}>Send</button>
        </div>

      </div>
<div className="bg"></div>




      </div>
      </> );
  }
}
 
export default Chat;
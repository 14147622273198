import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import DetallesDelCarro from './dialogcomponents/DetallesDelCarro';
import Fotos  from './dialogcomponents/Fotos';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DetallesAdicionales from './dialogcomponents/DetallesAdicionales';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Content = (props)=>{
  switch(props.activeStep){
    case 0:
        return(
          <DetallesDelCarro
          userPack={props.userPack}/>
      );
    case 1:
      return(
        <Fotos userPack={props.userPack} handleClose={props.handleClose}/>
    );
    case 2:
      return(
        <DetallesAdicionales userPack={props.userPack}/>
    );  
  }
  return null
  }

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
function LoginModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <Modal
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
    open={props.open}
    onClose={props.onClose}
  >
    <div style={modalStyle} className={classes.paper}>
    </div>
  </Modal>
  );
}


class NewListing extends Component {
  state = { 
    loading:false,
    activeStep:0,
    completed:[false,false,false,false],
    steps:['Detalles básicos', 'Fotos', 'Detalles adicionales'],
    listing:this.props.userPack.myListing
  }
  handleClose = ()=>{
    this.props.userPack.ulteriorMethods.removeUlteriorMotive();
    this.props.userPack.dialog.handleClose()
    this.props.userPack.listingAPI()
    return
    
  }

  handleStep =(e)=>{
    console.log("handle",e)
    /*
    const listingAPI = this.props.userPack.listingAPI
    const that=this
    async function myFunction(e){
      await listingAPI()
      that.setState({activeStep:e})
    }
    myFunction(e)
    */
   this.setState({activeStep:e})
  };

  handleBack = () => {
    this.props.userPack.listingAPI()
    this.setState({activeStep:this.state.activeStep-1})
  };

  handleNext = (e) => {
    console.log("step",e.target)
    this.props.userPack.listingAPI()
    this.setState({activeStep:this.state.activeStep+1})
  };

  handleComplete = (index) => {
    let completed = [...this.state.completed]
    completed[index]=true
    this.setState({completed:completed})
  };

  handleClickAway=()=>{
    this.handleClose()
  }
  selectAction =(content,action)=>{
    let listing = {...this.state.listing}
    switch(action){
      case"brand":
        listing.brand=content
        break
      case"year":
        listing.year=content
        break
      case"model":
        listing.model=content
        break
    }
    this.setState({listing:listing})
  }

  render() { 
    const openString = this.props.open?"modal is-active":"modal";
    const that=this;
    const userPack=this.props.userPack
    const offset=userPack.dimensions.width<600?"-10px":"20px"
    return ( 
      <>
      
      <div className={openString} style={{zIndex:"3000"}}>
        <div className="modal-background"></div>
        <Grid id="newListing" container justify="center" style={{position:"relative",bottom:"15px"}}>
              <Stepper margin="none" alternativeLabel nonLinear activeStep={this.state.activeStep} style={{backgroundColor:"inherit",color:"white !important"}}>
                {this.state.steps.map((label, index) => {
                    const stepProps = {};
                    const buttonProps = {};
                    const handleStep = ()=>{
                      that.handleStep(index)
                    }
                    return (
                      <Step index={index} key={label} {...stepProps} style={{color:"white !important"}} onClick={handleStep}>
                        <StepButton
                          completed={this.state.completed[this.state.activeStep]}
                          step={index}
                          {...buttonProps}
                        >
                          <div style={{color:"white"}}>{label}</div>
                        </StepButton>
                      </Step>
                    );
  
                })}
              </Stepper>
          </Grid>
        
        
        <div className="modal-card" style={{position:"relative",top:offset,boxSizing:"content-box"}}>
          <header className="modal-card-head">
              <p className="modal-card-title" style={{position:"relative",left:"32px"}}>{this.state.steps[this.state.activeStep]}</p>
              <IconButton aria-label="close"  style={{marginRight:"15px"}} onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>
          </header>
          {!this.props.userPack.dialog.fetched?
          
          <Content onClickAway={this.handleClickAway} listing={this.props.listing} activeStep={this.state.activeStep}
          userPack={userPack} handleClose={this.handleClose}/>:
          <>
                <Grid style={{backgroundColor:"white"}} container spacing={3} justify="center" alignItems="center" alignContent="center">
                    <Grid item xs={12} style={{marginTop:"80px"}}>
                      <Grid container justify="center">
                        <Grid item xs="auto" style={{padding:userPack.dimensions.width<759?"15px":"0px"}}>
                            <h1>Creando su nueva publicación</h1>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={"auto"} style={{marginBottom:"140px"}}>
                      <CircularProgress color="secondary" />

                    </Grid> 

                    </Grid>
          </>
          }
          <footer className="modal-card-foot">
            <Grid container>
                <Grid item xs={6}>
                  <Grid container justify="flex-start">
                    <Grid item xs="auto">
                    <Button onClick={this.handleClose} variant="contained" color="default">
                      Cancelar
                    </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container justify="flex-end">
                    <Grid item xs="auto">
                      {this.state.activeStep===2?
                                          <Button onClick={this.handleClose} variant="contained" color="secondary">
                                          Guardar
                                        </Button>
                        :
                        <Button onClick={this.handleNext} variant="contained" color="secondary">
                        Continuar
                      </Button>
                        }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
                  </footer>
                </div>
              </div>
              </>
     );
  }
}
 
export default NewListing;
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  fabProgress: {
    color: green[500],
    position: 'absolute',
    top: -23,
    left: 5,
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const getUrl = window.location;
let neoUrl
let baseUrl = "https://ur8z3iogad.execute-api.us-east-2.amazonaws.com/dev/";
if(getUrl.host.includes("249:3000")){
  neoUrl=getUrl.host.substring(0,getUrl.host.length-5)
  baseUrl = getUrl.protocol+ "//" + neoUrl +":8000/";
}else{
  neoUrl = getUrl.host
}


const axios = require('axios');
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = false;

export default function CircularIntegration(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef();

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleButtonClick = () => {
    if (!loading) {
      let payment
      if(props.userPack.book.payment){
        payment=props.userPack.book.payment
      }else{
        if(props.userPack.book.listingForRent.bankAccounts.length>0){
          payment = {method:'CASH',data:props.userPack.book.listingForRent.bankAccounts[0].id}
        }
        else return
      }
      //props.userPack.book.handlePayment('CASH',selected.id)
      setSuccess(false);
      setLoading(true);
      const token = window.localStorage.getItem('token')
      axios.defaults.headers.post['Authorization']="JWT "+token
      let newBooking = props.userPack.book.myBooking
      // newBooking.customer=props.userPack.user
      // newBooking.listing=props.userPack.book.listingForRent
      const oldStart = newBooking.startDate
      const oldEnd = newBooking.endDate
      const listing = props.userPack.book.listingForRent
      const duracion = props.userPack.book.myBooking.endDate.diff(props.userPack.book.myBooking.startDate, 'days')+1
      const rate = parseFloat(props.userPack.elements.currency.rates[props.userPack.elements.currency.selected]).toFixed(2)
      const total = Math.ceil(listing.dailyPrice)*duracion
      newBooking.startDate = oldStart.format()
      newBooking.endDate = oldEnd.format()
      newBooking.totalCost=total
      newBooking.deliveryAddress = newBooking.deliveryAddress.id

      console.log("printing userPack user",props.userPack.user)
      newBooking.customer=props.userPack.user.id
      //axios.post(baseUrl + `api/booking/  `,newBooking).then(resp=>{
      console.log(props.userPack.book.myBooking, "my booking prior")
      axios.post(baseUrl + `newBooking/`,{booking:newBooking,payment}).then(resp=>{
        setLoading(false);
        setSuccess(true);
        console.log(props.userPack.book.myBooking, "my booking after")
        let startDateRaw = localStorage.getItem('startDate')
        let endDateRaw = localStorage.getItem('endDate')
        let location = localStorage.getItem('location')
        let startDate
        let endDate
        if(startDateRaw===null || moment(startDateRaw).isAfter(moment())){startDate=moment()}
        else{startDate=moment(startDateRaw)}
        if(endDateRaw===null){endDate=moment().add(7, 'd')}
        else{endDate=moment(endDateRaw)}
        let newBooking = props.userPack.book.emptyBooking
        newBooking.startDate=startDate
        newBooking.endDate=endDate
        props.userPack.book.updateMyBooking(newBooking)
      }).catch(error=>{
        setSuccess(false)

      })
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Fab
          aria-label="save"
          color="primary"
          className={buttonClassname}
          onClick={handleButtonClick}
          size="large"
          variant="extended"
        >
          {success ? <CheckIcon /> : <p>Reservar</p>}
        </Fab>
        {loading && <CircularProgress size={96} className={classes.fabProgress} />}
      </div>
    </div>
  );
}
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { IconButton, Button } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';


const getUrl = window.location;
let neoUrl
let baseUrl = "https://ur8z3iogad.execute-api.us-east-2.amazonaws.com/dev/";
if(getUrl.host.includes("249:3000")){
  neoUrl=getUrl.host.substring(0,getUrl.host.length-5)
  baseUrl = getUrl.protocol+ "//" + neoUrl +":8000/";
}else{
  neoUrl = getUrl.host
}

const axios = require('axios');
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = false;


//const AnyReactComponent = ({ text }) => <div style={{color:"black" }}>{text}</div>;
const AnyReactComponentOG = (props) => {
  return( 
    <>
        <RoomIcon style={{fontSize:"35px",color:"red",position:"relative",bottom:"25px",right:"10px"}}/>

 </>
  )
}
const FloatingObj = (props) => {
  const handleSwitchMap=()=>{
    let newFilters = props.userPack.filters
    newFilters.map=!newFilters.map
    props.userPack.filterMethods.handleFilters(newFilters)
  }
  return( 
    <>
    {props.userPack.filters.map?
    <>
    <Grid item xs="auto" style={{backgroundColor:"transparent",position:"fixed",zIndex:"1500",bottom:(props.height/2)-40+"px",right:(props.width/3)-30+"px" }}>
        <IconButton onClick={handleSwitchMap}>
        <DoubleArrowIcon style={{fontSize:"35px",color:"black"}}/>
        </IconButton>
        </Grid>
        </>
        :
        <>
            <Grid item xs="auto" style={{backgroundColor:"transparent",position:"fixed",zIndex:"1500",bottom:(props.height/2)-40+"px",right:"0px" }}>
        <IconButton onClick={handleSwitchMap}>
        <ArrowBackIosIcon style={{fontSize:"35px",color:"black"}}/>
        </IconButton>
        </Grid>
        </>
        }
 </>
  )
}
const AnyReactComponent = (props) => {
  const hovered = props.hovered && props.element.id===props.hovered
  return(
    <>
    <Grid item xs="auto" style={{position:"relative",bottom:hovered?"29px":"0px"}}>
      <Grid container>
        <Grid item xs={12}>
          { hovered?
          <p className="hoveredElement" style={{padding:"5px",fontSize:"16px"}}>CLICK ME
          </p>
          
          :null}
        <GpsFixedIcon className="hoveredItem" id={props.element.id} onMouseLeave={props.handleHoverOut} onMouseEnter={props.handleHoverIn} onClick={props.handleHoverIn} style={{fontSize:"32px",color:"blue",position:"relative",bottom:"28px",right:"15px"}}/>
   
        </Grid>

      </Grid>
    </Grid>
 </>
  )
}
const containerStyle = {
  width: '100%',
  height: '100%'
};

const center = {
  lat: -3.745,
  lng: -38.523
};

function NeoMaps(props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDeH9xTN_AEFa5cOucYhTlnN-HHDQV_oeY&v=3.exp&libraries=geometry,drawing,places"
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
  const [hovered,setHovered]=React.useState(null)
  const handleHovered = (id)=>{
    setHovered(id)
  }
  const handleMap=(e)=>{
    e.preventDefault()
    props.methods.switchFloating()
  }
  const handleHoverIn=(e)=>{
    e.preventDefault()
    setHovered(parseInt(e.target.id))
  }
  const handleHoverOut=(e)=>{
    setHovered(null)
    e.preventDefault()
  }
  console.log("veamos qloq",props.elements.latitude,props.elements.longitude);
  return isLoaded ? (
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={{lat:18.4859895,lng:-69.8625041}}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        { /* Child components, such as markers, info windows, etc. */ }
        <>
        <AnyReactComponentOG
              lat={props.elements.latitude}
              lng={props.elements.longitude}
              text="My Marker"/>
            {props.results.map(element=>(
                          <AnyReactComponent hovered={hovered} handleHovered={handleHovered} handleHoverIn={handleHoverIn} handleHoverOut={handleHoverOut} key={element.id}
                          lat={element.owner.deliveryAddressList[0].xCoordinate}
                          lng={element.owner.deliveryAddressList[0].yCoordinate}
                          element={element}
                          text="My Marker"
                        />
            ))}

        </>
      </GoogleMap>
  ) : <></>
}

export default React.memo(NeoMaps)
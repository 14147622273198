import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CloneDialog from '../maincomponents/CloneDialog';
import { Link } from "@reach/router";

const useStyles = makeStyles(theme => ({
    topMargin:{
        marginTop:"25px"
    },
    unDoneListing:{
        backgroundColor: "hsla(0, 0%, 15%, 0.10)",
        '&:hover':{
            backgroundColor: "hsla(61, 88%, 91%, 0.57)"
        }
    }
}))

const UnDoneListing = (props) =>{
    const classes = useStyles()
    const title = (props.listing.vehicle.brand && props.listing.vehicle.model && props.listing.vehicle.year)?props.listing.vehicle.brand +" "+ props.listing.vehicle.model +" "+ props.listing.vehicle.year:"Indefinido"
    console.log("UnDone",title)
    const handleDialog= () => {
        props.handleOpen(props.listing)
    }
    const openEdit=()=>{
      props.userPack.methods.stateHandling('listingHeredado',props.listing)
      props.selectTab(2)
    }
    return(
        <>
        
        <Grid item xs={4} md={2} sm={3} key={props.listing.id} onClick={openEdit} style={{marginRight:"30verifypx"}}>
            
            <Tooltip title="Click para continuar" aria-label="Editar" placement="bottom-start">
            <div className="wide" style={{minWidth:"144px"}}>
                <img className="relative" src="https://static.thenounproject.com/png/2999524-200.png" style={{width:"135px",height:"110px"}} className={classes.unDoneListing}/>
            <p>{title}</p>
            </div>
            </Tooltip>
            
        </Grid>
        </>
    );
}

function Home(props){
    const handleOpen =(listing)=>{
        props.handleOpen("finishListing",listing)
    }
    const classes = useStyles(props);
    //Do handling for deleting listing
    const handleConsejos= () => {
        props.selectTab(6)
    }
    const logout=()=>{
        props.logout()
    }
    const verMiPerfil =()=>{
        props.userPack.refs.routeMyProfileRef.current.click()
    }
    const editar =()=>{
        props.userPack.refs.routeUserIdRef.current.click()
    }
    const margin = props.smallScreen?"10px":"0px"
    return (
        <>
        <div className="mainBody" style={{position:"relative",top:"0px"}}>

                        <Grid item xs={12} >
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h4" className={classes.topMargin}>
                                        {props.user.first_name?"Hola, "+props.user.first_name:"Bienvenido"}!
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom className={classes.topMargin}>
                                        Publica tu carro y empieza hacer dinero!
                                    </Typography>
                                <Grid item xs={12}>
                                    <Grid container spacing={2} style={{marginTop:"10px"}}>
                                        {props.user.listings.filter(c=>c.mainPicture==null).map((listing) => (  
                                            <UnDoneListing className={classes.unDoneListing}
                                            listing={listing}
                                            key={listing.id}
                                            userPack={props.userPack}
                                            selectTab={props.selectTab}
                                            handleOpen={handleOpen}
                                            closeClone={props.closeClone}/>
                                        ))}
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{marginBottom:"15px"}}>
                                    <Grid container>
                                        <Grid item xs={"auto"}>
                                            <Button onClick={handleConsejos} variant="contained" color="secondary" className={classes.topMargin}>
                                                Consejos para aumentar tus ganacias
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{marginBottom:"15px"}}>
                                    <Grid container>
                                        <Grid item xs={"auto"}>
                                            <Button onClick={editar} variant="outlined" color="secondary" className={classes.topMargin}>
                                                Editar mi cuenta
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{marginBottom:"15px"}}>
                                    <Grid container>
                                        <Grid item xs={"auto"}>
                                            <Button onClick={verMiPerfil} variant="outlined" color="secondary" className={classes.topMargin}>
                                                Ver mi perfil
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{marginBottom:"15px"}}>
                                    <Grid container>
                                        <Grid item xs={"auto"}>
                                            <Button onClick={logout} variant="outlined" color="secondary" className={classes.topMargin}>
                                                Logout
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
      
                            </Grid>
                        </Grid>


            </Grid>
            </div>
    </>
    )
}

export default Home;
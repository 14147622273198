import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import Signature from './maincomponents/Signature';
import Avatar from '@material-ui/core/Avatar';
import Icon from './listingscomponents/Icon';
import CommentSection from './listingscomponents/CommentSection';
import DateDownShift from './userdashboardcomponents/DateDownShift';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

// import Carousel from './maincomponents/carousel/Carousel';
import Chat from './maincomponents/Chat';
import { IconButton } from '@material-ui/core';
import {Link} from '@reach/router';
import moment from 'moment';
import SelectMap from './listingscomponents/SelectMap';
import SelectAddress from './singlelistingcomponents/SelectAddress';
import MainNavBar from './elements/MainNavBar';
import SingleListingSkeleton from './maincomponents/SingleListingSkeleton';

function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

/*
state = { listing:listingBase, fetchedListing:true }
state = { listing:{}, fetchedListing:false }
*/
const getUrl = window.location;
let neoUrl
let baseUrl = "https://ur8z3iogad.execute-api.us-east-2.amazonaws.com/dev/";
if(getUrl.host.includes("249:3000")){
  neoUrl=getUrl.host.substring(0,getUrl.host.length-5)
  baseUrl = getUrl.protocol+ "//" + neoUrl +":8000/";
}else{
  neoUrl = getUrl.host
}


const axios = require('axios');
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = false;

const AddressLine = (props)=>{
  
  const handleSelect=()=>{
    props.handleLocation(props.address)
  }
  const handleMap=()=>{
    props.handleLocationVirtual(props.address)
    props.handleMap()
  }
  const selected = props.address.line1===props.booking.location.line1
  return(
    <>
      <Grid container>
      
        <Grid item xs={12} sm={6} md={12} style={{position:"relative",top:selected&&props.width>600&&props.width<960?"9px":"0px"}}>
          {props.address.line1+", "+props.address.city+", "+props.address.state}
        </Grid>
        <Grid item xs={12} sm={6} md={12}>
          <Button variant="outlined" onClick={handleMap} color="secondary">Ver en el mapa</Button>
          {selected?
          null
            :
            <Button variant="outlined" onClick={handleSelect} style={{marginLeft:"5px "}} color="primary">Seleccionar</Button>
            }
          
        </Grid>
      </Grid>
      
    </>
  )
}
class SingleListing extends Component {
    state = {
      fetchedListing:false, 
      currentImage:0,
      pictures:[], 
      booking:{startDate:"",endDate:"",location:""},
      selectMap:false,
      dialogLocation:{}
    }
    
    constructor(props){
      super(props);
      if(getUrl.href.includes("?code") && !props.userPack.fetchedUser){
        console.log("positivo dotol")
        props.userPack.dialog.handleOpen("register")
        //this.props.userPack.loginElements.handleRegister()
      }
      const id = parseInt(props.listingId)
      this.fetchListing(id)
    }
    handleMap=()=>{
      const newState = !this.state.selectMap
      this.setState({selectMap:newState})
    }
    handleBook=()=>{
      //this.props.userPack.book.updateMyBooking(this.state.listing);
      let newBooking = this.props.userPack.book.myBooking
      newBooking.deliveryAddress=this.state.booking.location
      newBooking.listing=this.state.listing.id
      
      const duracion = this.props.userPack.book.myBooking.endDate.diff(this.props.userPack.book.myBooking.startDate, 'days')+1
      newBooking.days=duracion
      //newBooking.paymentMethod="Cash"
      newBooking.totalCost=(duracion*this.state.listing.dailyPrice).toFixed(2)
      //newBooking.totalCost = "175.00"
      //delete newBooking.deliveryAddress.customer
      
      if(this.props.userPack.logged && this.props.userPack.user.verified){
        console.log("printing userPack user",this.props.userPack.user)
        newBooking.customer = this.props.userPack.user.id
        this.props.userPack.dialog.handleOpen("book",this.state.listing)
      }
      else{
        this.props.userPack.book.setListingForRent(this.state.listing);
        this.props.userPack.ulteriorMethods.setUlteriorMotive("book");
        this.props.userPack.dialog.handleOpen("needPowers")
        console.log("requesting powers")
      }
      this.props.userPack.book.updateMyBooking(newBooking)
    }
    fetchListing = (id) =>{
      axios.get(baseUrl +"api/listings/"+id+"/").then(res => {
        const data = res.data;
        this.setState({listing:data})
        let files = [data.mainPicture.url]
        data.listingPictures.forEach(picture => {
          files.push(picture.url)
        });
        let myBooking = {...this.state.booking}
        myBooking.location= data.owner.deliveryAddressList[0]
        this.setState({pictures:files,listing:data,fetchedListing:true,booking:myBooking})
      }, error=>{
        console.log("No existe",error)
        this.setState({notFound:true})
    })}
    handleDateFrom = (date) =>{
      let booking = {...this.state.booking}
      booking.startDate = date
      this.setState({booking:booking})
      }
    handleDateTo = (date) =>{
      let booking = {...this.state.booking}
      booking.endDate = date
      this.setState({booking:booking})
      }
    handleLocation=(e)=>{
      let booking = {...this.state.booking}
      booking.location = e
      this.setState({booking:booking})
    }
    handleLocationVirtual=(e)=>{
      this.setState({dialogLocation:e})
    }
    nextImage = ()=>{
      this.setState({currentImage:(this.state.currentImage + 1)})
    }
    previousImage = ()=>{
      this.setState({currentImage:(this.state.currentImage - 1)})
    }
    render() { 
        const isMobile = this.props.userPack.dimensions.width<960
        const width = this.props.userPack.dimensions.width
        const currentWidth = this.props.userPack.dimensions.width
        const that=this
        const relativeRight = isMobile?"8px":"0px";
        const listing = this.state.listing
        const booking = this.state.booking
        const Precio = ()=>{
          const margin = isMobile?"40px":"57px";
          const selectedSymbol = this.props.userPack.elements.currency.symbols.filter(c=>c.value==this.props.userPack.elements.currency.selected)[0].label;
          const rate = parseFloat(this.props.userPack.elements.currency.rates[this.props.userPack.elements.currency.selected]).toFixed(2)
          //console.log("tarifa",rate,listing.dailyPrice)
          return(
            <>
            {!isMobile?
              <div className="flex alignCenter relative" style={{paddingLeft:"10px",paddingRight:"10px",paddingTop:"4px",position:"relative",bottom:margin,backgroundColor:"white",borderTopLeftRadius:"20px",borderTopRightRadius:"20px"}}>
           
                      <Typography variant="h4" style={{fontWeight:"bold"}}>
                        {selectedSymbol}{formatNumber(Math.ceil(listing.dailyPrice*rate))}
                      </Typography>
                      <Typography className="l3" style={{fontWeight:"bold",position:"relative"}}>
                        por día
                      </Typography>
                   
              </div>
            :
            <div className="wide" style={{paddingLeft:"10px",paddingRight:"10px",paddingTop:"4px",position:"relative",bottom:margin,backgroundColor:"white",borderTopLeftRadius:"20px",borderTopRightRadius:"20px",maxWidth:"300px"}}>

                    <Typography variant={width<450?"h5":"h5"} style={{fontWeight:"bold"}}>
                    {selectedSymbol}{formatNumber(Math.ceil(listing.dailyPrice*rate))}
                    </Typography>
                    <div className="alignCenter">
                       <div className="wide" style={{position:"relative",bottom:"8px"}}>
                       <Typography style={{fontWeight:"bold"}}>
                      por día
                      </Typography>
                      </div>

                    </div>
        </div>
            }

            </>
          )
        }
        const MainFeatures = ()=>{
          return(
            <>
            <Icon label="performance" value={Math.ceil(listing.performance)+" "+ listing.unit}/>
            <Icon label="gas" value="Gasolina"/>
            <Icon label="seats" value={listing.vehicle.seats +" asientos"}/>
            <Icon label="doors" value={listing.vehicle.doors +" puertas"}/>
            </>
          )

        }
        const CarDetails=()=>{
          const amountViajes = listing.owner.bookingHistory.length===1?listing.owner.bookingHistory.length+" viaje":listing.owner.bookingHistory.length+" viajes"
          return(
            <>
              <Grid container spacing={2} style={{marginBottom:"5px"}}>
                {!isMobile?
                <Grid item md={4}>
                  <Grid container justify="flex-end">
                      <Grid item md="auto">
                      <Typography variant="subtitle1" style={{fontWeight:"bold"}}>
                        Detalles del carro
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                :null}
                {!isMobile?
                <Grid item xs={12} md={8} >
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs="auto">
                          <Typography variant="h4" style={{fontWeight:"bold", color:"black"}}>
                          {listing.vehicle.brand}
                          </Typography>
                      </Grid>
                      <Grid item xs="auto">
                          <Typography variant="h6" style={{fontWeight:"bold", color:"black", marginTop:"7px",marginLeft:"8px"}}>
                          {listing.vehicle.year}
                          </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{fontWeight:"bold"}}>
                    {listing.vehicle.model}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs="auto">
                      <Box component="fieldset" borderColor="transparent" style={{padding:"0px"}}>
                        <Rating value={listing.overallRating} readOnly/>
                      </Box>
                    </Grid>
                    <Grid item xs="auto">
                      <Typography style={{fontWeight:"bold", color:"black", marginTop:"3px",marginLeft:"8px"}}>
                        {amountViajes}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <MainFeatures/>
                  </Grid>
                </Grid>
                </Grid>
                :
                <Grid item xs={12} md={8}>
                  <Grid item xs={12}  >
                    <Grid container style={{marginTop:"15px"}} >
                      <Grid item xs="auto" style={{marginTop:width<450?"10px":"5px"}}>
                          <Typography variant="h6" style={{fontWeight:"bold", color:"black"}}>
                          {listing.vehicle.brand+" "+listing.vehicle.model}
                          </Typography>
                      </Grid>
                      <Grid item xs="auto">
                          <Typography style={{fontWeight:"bold", color:"black", marginTop:"7px",marginLeft:"8px",position:"relative",top:width<450?"15px":"10px"}}>
                          {listing.vehicle.year}
                          </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs="auto">
                      <Box component="fieldset" borderColor="transparent">
                        <Rating value={listing.overallRating} readOnly/>
                      </Box>
                    </Grid>
                    <Grid item xs="auto">
                      <Typography style={{fontWeight:"bold", color:"black",marginLeft:"8px"}}>
                      {amountViajes}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                </Grid>
                }
              </Grid>
      </>
          );

        }
        const HostedBy=()=>{ 
          const dateFormat = require('dateformat');
          const joined = new Date(listing.owner.date_joined);
          const date_joined = dateFormat(joined, "mmmm dS, yyyy");
          const amountViajes = listing.owner.bookingHistory.length===1?listing.owner.bookingHistory.length+" viaje":listing.owner.bookingHistory.length+" viajes"
          const viajes = amountViajes+" (Miembro desde "+date_joined+")"
          let maxWidth
          if(this.props.userPack.dimensions.width<520){
            maxWidth="180px"
          }else if(this.props.userPack.dimensions.width<960){
            maxWidth="600px"

          }else{
            maxWidth="240px"
          }
          return(
            <>
              <Grid container spacing={2} style={{marginBottom:"12px"}}>
                {!isMobile?
                <Grid item md={4}>
                  <Grid container justify="flex-end">
                      <Grid item md="auto">
                      <Typography variant="subtitle1" style={{fontWeight:"bold"}}>
                        Alojado por
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                :null}
                <Grid item xs={12} md={8}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs="auto">
                        <Avatar alt="Remy Sharp" src={listing.owner.profilePicture?listing.owner.profilePicture.url:"/missingprofile.png"} style={{width:"80px",height:"80px"}}/>
                        </Grid>
                        <Grid item xs="auto" style={{maxWidth:maxWidth,marginLeft:"8px"}}>
                          <Grid container>
                            <Grid item xs={12}>
                              <Link to={'/user/'+listing.owner.id}>
                                <Typography variant="h6" style={{fontWeight:"bold", color:"black" }}>
                                {listing.owner.first_name+" "+listing.owner.last_name}
                                </Typography>
                              </Link>
                            </Grid>
                            <Grid item xs="auto" >
                              <Typography>
                               {viajes} 
                              </Typography>
                            </Grid>
                          </Grid>

                        </Grid>

                        </Grid>
                      </Grid>
                </Grid>
              </Grid>
      </>
          )

        }
        const Description=()=>{
          return(
            <>
            {listing.additionalDetails && listing.additionalDetails!==""?
              <Grid container spacing={2} style={{marginBottom:"8px",marginTop:"8px"}}>
                {!isMobile?
                <Grid item md={4}>
                  <Grid container justify="flex-end">
                      <Grid item md="auto">
                      <Typography style={{fontWeight:"bold"}}>
                        Descripción
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                :null}
                <Grid item xs={12} md={8}>
                  <Typography variant="body1">
                    {listing.additionalDetails}
                  </Typography>
                  </Grid>

              </Grid>
              :null}
      </>
          )}
        const Features=()=>{
          return(
            <>
            {listing.features.length===0?null:
              <Grid container spacing={2} style={{marginBottom:"8px",marginTop:"8px",width:"100%"}}>
                {!isMobile?
                <Grid item md={4}>
                  <Grid container justify="flex-end">
                      <Grid item md="auto">
                      <Typography variant="subtitle1" style={{fontWeight:"bold"}}>
                        Características
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                :null}
                <Grid item xs={12} md={8}>
                  <Grid container>
                    {listing.features.map((feature,index)=>(
                      <Icon key={index} label={feature.name}/>
                    ))}
                  
                    
                  </Grid>
                </Grid>
              </Grid>
              }
      </>
          );
        }
        const Reviews=()=>{
          return(
<>
              <Grid container spacing={2} style={{marginBottom:"8px",marginTop:"8px"}}>
                {!isMobile?
                <Grid item md={4}>
                  <Grid container justify="flex-end">
                      <Grid item md="auto">
                      <Typography variant="subtitle1" style={{fontWeight:"bold"}}>
                        Críticas
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                :null}
                <Grid item xs={12} md={8}>
                  <Grid container>
                    {!listing.bookingHistory===[]?
                    <>
                    <Grid item xs="auto">
                      <Box component="fieldset" borderColor="transparent">
                        <Rating value={4} readOnly/>
                      </Box>
                    </Grid>
                    <Grid item xs="auto">
                      <Typography style={{fontWeight:"bold", color:"black", marginTop:"3px",marginLeft:"8px"}}>
                        4.1 de 5
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{marginBottom:"15px"}}>
                      <Typography style={{color:"black",marginLeft:"4px"}}>
                          15 clientes han evaluado
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                    <CommentSection isMobile={isMobile} width={that.props.userPack.dimensions.width}/>
                    </Grid>
                    </>
                    :<Grid item xs={12} style={{marginBottom:"15px"}}>
                    <Typography style={{color:"black",marginLeft:"4px"}}>
                        Esta publicación aún no ha sido evaluada. Haz tu reservación y sé el primero!
                    </Typography>
                  </Grid>}
                  </Grid>
                </Grid>
              </Grid>
      </>
          );
        }
        const ImageCard=()=>{
          let myHeight = that.props.userPack.dimensions.height
          const cardHeight = isMobile?that.props.userPack.dimensions.width/1.5+"px":myHeight-104+"px"
          return(
            <>
          <Grid item xs={12} style={{backgroundImage:"url("+that.state.pictures[that.state.currentImage]+")",width:"100%",
          backgroundSize:"cover",backgroundRepeat:"no-repeat", backgroundPosition:"center",
          height:cardHeight, position:"static"}}>

            {/*
            <Carousel imageUrls={that.state.pictures} />
            */ }
            <Grid container id="verticalCarousel">
              <Grid item xs={6}>
                <Grid container justify="flex-start">
                  <Grid item xs="auto">
                    {that.state.currentImage==0?null:<div onClick={that.previousImage} className="backButton" style={{backgroundSize:"cover", backgroundImage:"url('../img/back.png')"}}></div>}
                    </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container justify="flex-end">
                  <Grid item xs="auto">
                    {that.state.currentImage==that.state.pictures.length-1?null:<div onClick={that.nextImage} className="nextButton" style={{backgroundSize:"cover", backgroundImage:"url('../img/next.png')"}}></div>}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          </>
          )
        }
        const SecondColumn=(props)=>{
          const myBooking = that.props.userPack.book.myBooking
          const updateBooking = that.props.userPack.book.updateMyBooking
          const handleStart=(dateRaw)=>{
            const date = moment(dateRaw)
            myBooking.startDate=date
            window.localStorage.setItem("startDate",date)
            updateBooking(myBooking)
          }
          const handleEnd=(dateRaw)=>{
            const date = moment(dateRaw)
            myBooking.endDate=moment(date)
            window.localStorage.setItem("endDate",date)
            updateBooking(myBooking)
          }
          return(
            <>
              <Grid container justify="center">
              <Grid item xs="auto" style={{position:"relative",paddingRight:relativeRight}}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography style={{color:"black",fontWeight:"bold"}}>
                    Inicio del recorrido
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={6} md={6}>
                      <DateDownShift selectedDate={myBooking.startDate} handleDate={handleStart}/>
                    </Grid>
                    <Grid item xs={6} md={6} >
                    <TextField
                      id="time"
                      type="time"
                      defaultValue="07:30"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      style={{width:"100%"}}
                    />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{color:"black",fontWeight:"bold"}}>
                    Final del recorrido
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={6} md={6}>
                      <DateDownShift selectedDate={myBooking.endDate} handleDate={handleEnd}/>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <TextField
                        id="time"
                        type="time"
                        defaultValue="07:30"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        style={{width:"100%"}}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                <Typography style={{color:"black",fontWeight:"bold"}}>
                    Lugar de encuentro para entregas
                  </Typography>
                </Grid>
                <SelectAddress handleMap={this.handleMap} handleLocationVirtual={this.handleLocationVirtual}
                handleLocation={this.handleLocation} booking={booking}
                width={currentWidth} listing={listing}
                />

                <Grid container>
                <Grid item xs={12} style={{marginTop:"14px",marginBottom:"14px"}}>
                  <Grid container justify="center">
                      <Grid item xs={12}>
                        <Button onClick={this.handleBook} variant="contained" color="secondary" style={{width:"100%"}}>
                          Reservar!
                        </Button>
                      </Grid>
                  </Grid>

                </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={8}>
                      <Grid container>
                        <Grid item xs={12}>

                        </Grid>
                        <Grid item xs={12}>

                        </Grid>
                      </Grid>
                  </Grid>
                  <Grid item xs={4}>
                      <Grid container justify="flex-end">
                        <Grid item xs="auto">
                          
                        </Grid>
                      </Grid>
                  </Grid>

                </Grid>
              </Grid>
              <Grid item xs={12}>

              </Grid>
              <Grid item xs={12}>
                <Grid container justify="center">
                  <Grid item xs="auto">

                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs="auto">

                  </Grid>
                  <Grid item xs="auto" style={{maxWidth:"200px"}}>
                    <Grid container>
                      <Grid item xs={12}>

                      </Grid>
                      <Grid item xs={12}>
                        
                        </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>

              </Grid>
              <Grid item xs={12}>
                
                </Grid>
              
                </Grid>
                </Grid>
              </Grid>
            </>
          );
        }
        
        const padding = isMobile?"10px":"80px"
        const paddingRight = isMobile?"0px":"80px"
        return ( 
          <>
          {this.state.selectMap?
          <SelectMap open={this.state.selectMap} handleClose={this.handleMap} updateMyBooking={this.props.userPack.updateMyBooking}
          deliveryAddressList={listing.owner.deliveryAddressList} userPack={this.props.userPack} 
          dialogLocation={this.state.dialogLocation} choose={this.handleLocation}
          />
            :null
            }
          
            <MainNavBar userPack={this.props.userPack} navColor="black"/>
          <div id="carousel" style={{paddingTop:"64px"}}>
          <Grid container>
          {this.state.fetchedListing?
          <>
            <ImageCard/>
            <Grid item xs={12}>
              <Grid container spacing={2} style={{paddingTop:"12px",paddingBottom:"12px",paddingLeft:padding,paddingRight:paddingRight}}>
                {!isMobile?
                <>
                <Grid item xs={8}>
                <CarDetails/>
                <HostedBy/>
                <Description/>
                <Features/>
                <Reviews/>
                </Grid>
                <Grid item xs={4}>
                <Precio/>
                <SecondColumn userPack={this.props.userPack}/>                
                </Grid>
                </>
                  :
                  <>
                  <Grid item xs={12}>
                    <Grid container justify="flex-end">
                      <Grid item xs="auto">
                        <Precio/>
                      </Grid>
                      
                    </Grid>
                  <div style={{position:"relative",bottom:"75px"}}>
                  <CarDetails/>
                  <div style={{marginBottom:"12px"}}>
                  <SecondColumn/>
                  </div>
                  <Grid item xs={12}>
                    <Grid container style={{marginBottom:"6px"}}>
                      <MainFeatures/>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{marginBottom:"10px"}}>
                      <Typography variant="subtitle1" style={{fontWeight:"bold"}}>
                        Alojado por
                      </Typography>
                  </Grid>
                  <HostedBy/>
                  <Grid item xs={12}>
                    {listing.additionalDetails && listing.additionalDetails!==""?
                      <Typography variant="subtitle1" style={{fontWeight:"bold"}}>
                        Descripción
                      </Typography>
                      :null}
                  </Grid>
                  <Description/>
                  <Grid item xs={12}>
                  {listing.features.length===0?null:
                      <Typography variant="subtitle1" style={{fontWeight:"bold"}}>
                        Características
                      </Typography>
                      }
                  </Grid>
                  <Features/>
                  <Grid item xs={12}>
                      <Typography variant="subtitle1" style={{fontWeight:"bold"}}>
                        Críticas
                      </Typography>
                  </Grid>
                  <Reviews/>
                  </div>
                  </Grid>
                  
                  
                  </>
                  }


              </Grid>
            </Grid>
          </>
              :
           <>
              
              {this.state.notFound?
              <> <Grid item xs={"auto"} style={{marginTop:"140px",marginBottom:"140px",position:"relative",transform:"translateX(-50%)",left:"50%"}}>
                <h1>404 Not Found</h1></Grid>
                </>
                :
                <div className="wide">
                  <SingleListingSkeleton loading={true}/>
                  </div>
                
                
                }
            </>
            }
            </Grid>
            </div>
            <Signature userPack={this.props.userPack}/>
          </>
         );
    }
}
 
export default SingleListing;
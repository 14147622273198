import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DeleteConfirmation from './DeleteAddressConfirmation';

const useStyles = makeStyles(theme => ({
  topMargin:{
      marginTop:"25px"
  },
  unDoneListing:{
      backgroundColor: "hsla(0, 0%, 15%, 0.10)",
      '&:hover':{
          backgroundColor: "hsla(61, 88%, 91%, 0.57)"
      }
  }
}))
const AddressLine = (props)=>{
    const deleteAddress=()=>{
        props.deleteAddress(props.address)
    }
    return(
        <>
        <div id="addressLine" style={{marginTop:"15px"}}>
        <Grid item xs={12}>
            <Grid container spacing={3}>
                <Grid item xs={10}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h6" style={{minWidth:"300px"}}>
                                {props.address.line1}
                            </Typography>                 
                        </Grid>
                        <Grid item xs={12}>
                            {console.log(props.address, "Address")}
                            <Typography variant="h6" >
                                {props.address.city +", "+ props.address.state +", "+ props.address.country+", "+props.address.zipcode}
                            </Typography>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={2}>
                    <Grid container justify="flex-end">
                        <Grid item xs="auto" id="floatingIcon" style={{position:"relative",top:"3px",opacity:"0"}}>
                            <IconButton onClick={deleteAddress}>
                                <DeleteForeverIcon/>
                            </IconButton>
                    </Grid>
                    </Grid>
                </Grid>
                    

            </Grid>


        <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
                {"Latitud: "+props.address.xCoordinate+" | Longitud: "+props.address.yCoordinate}
            </Typography>
   
        </Grid>
        </Grid>

        </div>
        </>
    )
}
export default function Entrega(props){
    const [myAddress,setMyAddress]=React.useState({})
    const [confirmationDialog,setConfirmationDialog] = React.useState(false);
    const handleAddAddress=()=>{
        props.userPack.dialog.handleOpen("newAddress")
    }
    const deleteAddress=(address)=>{
        switchDelete(address)
    }
    const deleteAddressOG=()=>{
        let address = myAddress
        let newUser = props.userPack.user
        console.log("before",newUser.deliveryAddressList)
        let newAddressList = newUser.deliveryAddressList.filter(c=>c.id!==address.id)
        newUser.deliveryAddressList = newAddressList
        console.log("after",newUser)
        props.userPack.userAPI(newUser)
    }
    const heightFunc = () => {
      if(props.isMobile){
        return (props.height-200)+"px"
      }
      return (props.height-100)+"px"
    }


    const switchDelete = (address)=>{
        setMyAddress(address)
        setConfirmationDialog(prev=>!prev)
    }
    const margin = props.smallScreen?"10px":"0px"
    const mainMargin = props.smallScreen?"0px":"10vw"
    return(
      <>
      {confirmationDialog?
         <DeleteConfirmation open={confirmationDialog} handleClose={switchDelete} handleDelete={deleteAddressOG}/>
        :null}
        <div className="mainBody" style={{position:"relative",top:"0px"}}>
      <Grid container alignItems="center" justify="center" style={{marginBottom:"20px"}}>
            <Grid item xs={12} sm={12}>
                    <Grid container spacing={0} alignItems="flex-end" style={{marginBottom:"20px"}}>
                        <Grid item xs={12}  >
                            <Grid container style={{marginTop:"20px"}}>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={9}>
                                            <Typography variant="h4">
                                                Mis lugares para entregas
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3} >
                                            <Fab onClick={handleAddAddress} size="medium" color="secondary" aria-label="add">
                                                <AddIcon />              
                                            </Fab>
                                        </Grid>
                                    </Grid>

   
                                </Grid>
                                {props.userPack.user.deliveryAddressList.map((address,index)=>(
                                    <AddressLine address={address} key={index} deleteAddress={deleteAddress}/>
                                ))}
  
                        </Grid>

                        </Grid>
                    </Grid>

            </Grid>
        </Grid>
        </div>
      </>
    );
}
import React from 'react';
import MySelect from '../../MySelect';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';

 export default function(props){
    
      const handleChange = name => event => {

        props.userPack.updateMyUser([name],event.target.value)
      };

    const margin=props.userPack.dimensions.width<700?"0px":"70px"
    return(
      <>
      <div style={{padding:"20px",marginLeft:margin,marginRight:margin}}>
      <Grid container style={{marginTop:"5px"}}>
          <Grid item xs={12}>
          <TextField
          style={{width:"100%",borderColor:"white"}}
              required
              autoFocus
              id="standard-required"
              label="Nombre"
              margin="normal"
              value={props.userPack.user.first_name}
              onChange={handleChange('first_name')}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
            }}
              />
               </Grid>
          
          </Grid>
          <Grid item xs={12}>
          <TextField
          style={{width:"100%",color:"white"}}
              required
              id="standard-required"
              label="Apellido"
              margin="normal"
              value={props.userPack.user.last_name}
              onChange={handleChange('last_name')}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
            }}
              />
          </Grid>

</div>

      </>
    );
}


import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import GoogleLogin from 'react-google-login';
import FacebookIcon from '@material-ui/icons/Facebook';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GTranslateIcon from '@material-ui/icons/GTranslate';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Divider from '@material-ui/core/Divider';
import * as queryString from 'query-string';

const getUrl = window.location;
let neoUrl
let baseUrl = "https://ur8z3iogad.execute-api.us-east-2.amazonaws.com/dev/";
if(getUrl.host.includes("249:3000")){
  neoUrl=getUrl.host.substring(0,getUrl.host.length-5)
  baseUrl = getUrl.protocol+ "//" + neoUrl +":8000/";
}else{
  neoUrl = getUrl.host
}


const axios = require('axios');
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = false;


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
      Carly.do
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
  },
}))

function validateEmail(email) {
  var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return re.test(String(email).toLowerCase());
}

class SignIn extends Component {

  state = { fetching:false, error:false, 
    socialLogin:false,
   
  }

  setFetching=(fetching)=>{
    this.setState({fetching})
  }
  setError=(error)=>{
    this.setState({error})
  }
  handleClose=(ulterior="noUlterior")=>{
    if(ulterior.target){
      this.props.handleClose("noUlterior")
    }else{
      this.props.handleClose(ulterior)
    }
  }
  stringifiedParams = ()=>{
    const that = this
    const string = queryString.stringify({
    client_id: '2501770909900950',
    redirect_uri:that.props.userPack.loginElements.redirectUri,
    //'name','first_name','last_name','email','picture'
    scope: ['email'].join(','), // comma seperated string
    response_type: 'code',
    auth_type: 'rerequest',
    display: 'popup',
  })
  return string
}
  
  
  login = () =>{
    this.setFetching(true);
    this.setError({status:false});
    const that = this
    axios.post(baseUrl+"token-auth/",{username:this.props.userPack.loginElements.username,password:this.props.userPack.loginElements.password})
    .then(res=>{
        //const newToken=res.data.token
        //window.localStorage.setItem('token', newToken);
        //that.getUser(newToken)
        that.setFetching(false);
        that.props.systemLogin(res.data.user,res.data.token)
        that.handleClose()
        return
    },
    error=>{
      that.setError({status:true,content:"Usuario ó contraseña inválida"});
      that.setFetching(false);
    })
  }
  handleForgotPassword=(e)=>{
    e.preventDefault()
    const that = this
    axios.post(baseUrl+"changePassword/",{email:that.props.userPack.loginElements.username})
    .then(res=>{
        //const newToken=res.data.token
        //window.localStorage.setItem('token', newToken);
        //that.getUser(newToken)
        that.setError({status:true,content:"Te hemos enviado un correo"});
        that.setFetching(false);
        return
    },
    error=>{
      that.setError({status:true,content:"No hemos podido enviar el correo"});
      that.setFetching(false);
    })
  }
  publish = ()=>{
    const ulterior = window.localStorage.getItem('ulteriorMotive')
    if(ulterior==='list'){
      if(this.props.userPack.logged && this.props.userPack.user.verified){
        this.props.userPack.ulteriorMethods.removeUlteriorMotive();
        this.props.userPack.dialog.handleOpen("newListing",this.props.userPack.myListing,this.props.userPack.listings)
      }
      
      this.props.userPack.ulteriorMethods.setUlteriorMotive("list");
      this.props.userPack.dialog.handleOpen("needPowers")
  }
  }
  handleRegister=(myUsername=null,myPassword=null,myData=null)=>{
    const that = this
    this.setFetching(true);
    async function register (myUsername={target:false},myPassword=null,myData=null){
      if(myUsername && myUsername.target)
      myUsername = that.props.userPack.loginElements.username
      myPassword = that.props.userPack.loginElements.password
      myData = that.props.userPack.loginElements.data
      const isEmail = await validateEmail(myUsername)
      if(getUrl.href.includes("?code")){
        if(!isEmail){
          
          return
        }
        if(myPassword.length<8){
          that.setFetching(true);
          return
        }
      }else{
        if(!isEmail){
          that.setError({status:true,content:"Por favor ingrese un correo válido"});
          that.setFetching(false);
          return
        }
        if(myPassword.length<8){
          that.setError({status:true,content:"Su contraseña debe tener 8 o más caracteres"});
          that.setFetching(false);
          return
        }
      }

      that.setFetching(true);
      that.setError({status:false});
      let sendingData = {...myData}
      sendingData.username = myUsername
      sendingData.password = myPassword
      console.log("esfierzps")
      await axios.post(baseUrl+"api/existing/",sendingData)
      .then(res=>{
        if(res.data.message==="existe"){
          axios.post(baseUrl+"custom-auth/",{username:myUsername,password:myPassword})
            .then(res=>{
              console.log(res.data, "logging you in...")
              
              that.props.systemLogin(res.data.user,res.data.token)
              that.handleClose(null)
              that.setFetching(false);
              that.publish()
              try{that.props.userPack.refs.routeProfileRef.current.click()}catch (err){console.log(err)}
            }
            
        ).catch(err=>{
          if(myData.email){
            console.log(res.data, "logging you in now...")
            axios.post(baseUrl+"custom-auth/",{username:myUsername,password:myPassword})
            .then(res=>{
                console.log(res.data,"res.data")
                //const newToken=res.data.token
                //window.localStorage.setItem('token', newToken);
                //that.getUser(newToken)
                
                that.props.systemLogin(res.data.user,res.data.token)
                that.props.handleClose(null)
                that.setFetching(false);
                that.publish()
                try{that.props.userPack.refs.routeProfileRef.current.click()}catch (err){console.log(err)}
                return
            },
            error=>{
              console.log("not worked",error)
              that.setError({status:true,content:"Usuario ó contraseña inválida"});
              that.setFetching(false);
              return
            })
            that.setFetching(false);
          }else{
            that.setError({status:true,content:"Este correo ya existe. Intente iniciar sesión. Si olvidó su contraseña, haga click abajo."});
            that.setFetching(false);
          return false
        }
        })
        }
        if(res.data.response==="success"){
          that.login()
          return
        }
        if(that.props.userPack.loginElements.socialLogin){
          axios.post(baseUrl+"custom-auth/",{username:myUsername,password:myPassword})
          .then(res=>{
              console.log(res.data,"res.data")
              //const newToken=res.data.token
              //window.localStorage.setItem('token', newToken);
              //that.getUser(newToken)
             
              that.props.systemLogin(res.data.user,res.data.token)
              that.props.handleClose(null)
              that.setFetching(false);
              that.publish()
              return
          },
          error=>{
            console.log("not worked",error)
            that.setError({status:true,content:"Usuario ó contraseña inválida"});
            that.setFetching(false);
            return
          })
          that.setFetching(false);

        }

      })
  
    }
    register(myUsername,myPassword,myData)
    return
  }

  componentDidMount() {
    this.props.userPack.methods.stateHandling("setRedirectUri",window.location.host)
    if(getUrl.href.includes("?code") && !this.props.userPack.fetchedUser){
      this.handleRegister()
    }
  }

  handleEnter = (e)=>{
    if(e.key=="Enter"){
      this.handleRegister()
    }
  }
  handleEnterLogin = (e)=>{
    if(e.key=="Enter"){
      this.login()
    }
  }
  handlePassword = (e)=>{
    this.props.userPack.loginElements.setPassword(e.target.value)
  }
  handleUsername = (e)=>{
    
    this.props.userPack.loginElements.setUsername(e.target.value.toLowerCase())
  }
  responseGoogle = (e) => {
    const data = e.profileObj
    this.props.userPack.loginElements.setUsername(data.email)
    this.props.userPack.loginElements.setPassword('Default3xsd1Pass')
    this.props.userPack.loginElements.setData(data)
    this.setState({socialLogin:true})
    //register(data.email,'Default3xsd1Pass',data)
    this.handleRegister(data.email,'Default3xsd1Pass',data)
  }
  responseFacebook = (e) => {
    //e.preventDefault()
    const data = e
    this.setState({socialLogin:true})
    this.props.userPack.loginElements.setUsername(data.email)
    this.props.userPack.loginElements.setPassword('Default3xsd1Pass')
    this.props.userPack.loginElements.setData(data)
    // if(this.props.userPack.dimensions.width>600){
    //   this.handleRegister(data.email,'Default3xsd1Pass',data)
    // }
    //register(data.email,'Default3xsd1Pass',data)?
    this.handleRegister(data.email,'Default3xsd1Pass',data)
  };

  render() { 
    const facebookLoginUrl = `https://www.facebook.com/v5.0/dialog/oauth?`+this.stringifiedParams();
    return ( 
      <>
      <Container component="main" maxWidth="xs" style={{marginTop:"20px"}}>
      <Grid container justify="flex-end">
          <Grid item xs="auto" style={{position:"relative", top:"-8px",left:"33px"}}>
          <IconButton
                    edge="end"
                    aria-label="account of current user"
                    color="inherit"
                    onClick={this.handleClose}
                  >
           <CloseIcon fontSize="large"/>
           </IconButton>
          </Grid>
        </Grid>
        <div style={{position:"relative",bottom:"65px"}}>
              <div>
        {this.state.error.status?
        <>
        <Grid container>
          <Grid xs="auto" item>
          <p style={{color:"red",marginBottom:"20px",alignContent:"center"}}>{this.state.error.content}</p>
          </Grid>
        </Grid>

        </>
        :null}      
        {this.state.fetching?
        <>
        <Grid container justify="center" >
          <Grid item xs="auto">
          <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
         
          {/* <div className="lds-hourglass"></div> */}
          </Grid>
        </Grid>
        
        </>:null}
        <Grid container justify="center" style={{marginTop:"18px",marginBottom:"8px"}}>
            <Grid item xs="auto">
            <Avatar style={{backgroundColor:"#f50057"}} color="secondary">
            <LockOutlinedIcon />
          </Avatar>
            </Grid>
          </Grid>

          <Grid container justify="center">
            <Grid item xs="auto">
            <Typography component="h1" variant="h5" className="loginTitle">
          {this.props.state?"Regístrate!":"Iniciar Sesión"}
          </Typography>
            </Grid>
          </Grid>

          <Grid container>
              <Grid item xs={12}>
                <Grid container justify="center">
  
                  <Grid item xs="auto"  style={{marginBottom:"10px"}}>
                  <FacebookLogin
                    appId="2501770909900950"
                    redirectUri={getUrl.href}
                    callback={this.responseFacebook}
                    fields="name,first_name,last_name,email,picture"
                    render={renderProps => (
                      <div className="fb-login-button" data-width="" data-size="large" data-button-type="login_with" data-layout="default" data-auto-logout-link="false" data-use-continue-as="false">
                      <a href="#" className="fb connect textAlign" style={{width:"300px", backgroundRepeat: "no-repeat",backgroundPosition: "14px 10px",backgroundImage:"url('/svg/fbicon.png')"}}  onClick={renderProps.onClick}>{this.props.state?"Crear cuenta con Facebook":"Iniciar Sesión con Facebook"}</a>
                      </div>
                    )}
                  />

                    {/* <div className="fb-login-button" data-width="" data-size="large" data-button-type="login_with" data-layout="default" data-auto-logout-link="false" data-use-continue-as="false"></div>
                      <a href={facebookLoginUrl} className="fb connect" style={{width:"300px", backgroundRepeat: "no-repeat",backgroundPosition: "14px 10px",backgroundImage:"url('/svg/fbicon.png')"}}>{this.props.state?"Crear cuenta con Facebook":"Iniciar Sesión con Facebook"}</a> */}

                  </Grid>
                  <Grid item xs="auto" style={{marginBottom:"18px"}}>
                    <GoogleLogin
                    scope="email https://www.googleapis.com/auth/analytics https://www.googleapis.com/auth/analytics.edit https://www.googleapis.com/auth/analytics.readonly"
                      clientId="55234840759-j7j39q8libqbooflhcknopgevl7va2o1.apps.googleusercontent.com"
                      render={renderProps => (
                        <a href="#" className="google connect textAlign" style={{width:"300px", backgroundRepeat: "no-repeat",backgroundPosition: "12px 10px",backgroundImage:"url('/svg/googleicon.png')"}} onClick={renderProps.onClick} disabled={renderProps.disabled}>{this.props.state?"Crear cuenta con Google":"Iniciar Sesión con Google"}</a> 
                      )}
                      buttonText="Login"
                      onSuccess={this.responseGoogle}
                      cookiePolicy={'single_host_origin'}
                    />
                    </Grid>
                </Grid>
  
              </Grid>
  
  
            </Grid>
          <Grid item xs={12} style={{marginBottom:"10px"}}>
            <Grid container justify="center">
              <Grid xs="auto" item>
              <p>ó</p>
              </Grid>
            </Grid>
            
          </Grid>
            <TextField
            style={{zIndex:"9000"}}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Correo"
              name="email"
              autoComplete="email"
              onChange={this.handleUsername}
              value={this.props.userPack.username}
            />
            {this.props.state?
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={this.handlePassword}
              value={this.props.userPack.password}
              onKeyPress={this.handleEnter}
            />:
            <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={this.handlePassword}
            value={this.props.userPack.password}
            onKeyPress={this.handleEnterLogin}
          />
            }
            {this.props.state?
                          <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="secondary"
                          onClick={this.handleRegister}
                          style={{marginBottom:"10px"}}
                        >
                          Registrarme
                        </Button>
              :<Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              onClick={this.login}
              style={{marginBottom:"10px"}}
            >
              Iniciar Sesión
            </Button>
            }

            <Grid container>
            {this.props.state?
                            <Grid item xs={12}>
                            <a href="#" onClick={this.props.switch} style={{color:"#3f51b5",fontSize:"16px"}}>
                             Haz click aqui si ya tienes cuenta
                            </a>
                          </Grid>
                          :
                          <>
                          <Grid item xs={12}>
                          <a href="#" onClick={this.handleForgotPassword} style={{color:"#3f51b5",fontSize:"16px"}}>
                              Olvidaste tu contraseña?
                            </a>
                          </Grid>
                           <Grid item xs={12}>
                          <a href="#" onClick={this.props.switch} style={{color:"#3f51b5",fontSize:"16px"}}>
                              Todavia no tienes cuenta? Registrate!
                            </a>
                          </Grid>
                          </>
                          }

            </Grid>
        </div>
        </div>
      </Container>
      </>
     );
  }
}
 
export default SignIn;
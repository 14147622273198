import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Perfil from './Perfil';
import NavBar from './maincomponents/NavBar';
import Signature from './maincomponents/Signature';
import MainNavBar from './elements/MainNavBar';

const getUrl = window.location;
let neoUrl
let baseUrl = "https://ur8z3iogad.execute-api.us-east-2.amazonaws.com/dev/";
if(getUrl.host.includes("249:3000")){
  neoUrl=getUrl.host.substring(0,getUrl.host.length-5)
  baseUrl = getUrl.protocol+ "//" + neoUrl +":8000/";
}else{
  neoUrl = getUrl.host
}

const axios = require('axios');
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = false;


class User extends Component {
    state = {
      user:null,
      loading:true,
      notFound:false
    }
    
    constructor(props){
      super(props);
      if(getUrl.href.includes("?code") && !props.userPack.fetchedUser){
        console.log("positivo dotol")
        props.userPack.dialog.handleOpen("register")
        //this.props.userPack.loginElements.handleRegister()
      }
      const id = parseInt(props.userId)
      this.fetcUser(id)
    }

    fetcUser = (id) =>{
      axios.post(baseUrl +"userPage/",{id}).then(res => {
        const user = res.data;
        console.log(" existe",user)
        this.setState({user,loading:false})
      }, error=>{
        console.log("No existe",error)
        this.setState({notFound:true})
    })}
   
    render() { 
      return(<>
        {this.state.loading?<>
                {this.state.notFound?
                  <><div style={{height:this.props.userPack.dimensions.height-150+"px"}}>
                   <MainNavBar navColor="black" userPack={this.props.userPack}/>
                    <h1 style={{top:"35%",right:"50%",position:"absolute",transform:"translate(50%,50%)"}}>404 Not Found</h1>
                    </div>
                    <Signature userPack={this.props.userPack}/>
                  </>
                    :
                    <CircularProgress color="secondary" style={{top:"45%",right:"50%",position:"absolute"}} />
                    }</>
            :
            <>
            <Perfil viewUser={this.state.user} userPack={this.props.userPack}/>
            </>}

      
      </>)
    }
}
 
export default User;